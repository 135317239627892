<!-- See https://www.figma.com/file/kUddj2qJpeidYiXv91BeJZ/Console?type=design&node-id=6056-68935&mode=design&t=ZbXIoGiKm2tRSkON-0 -->
<div class="dark">
  <div *ngIf="hasValidParameters" class="page">
    <div class="card">
      <div class="card_content">
        <div class="logo_title_and_text">
          <div class="logo">
            <mat-icon svgIcon="short_logo_light" class="logo_icon"></mat-icon>
          </div>
          <div class="title_and_text">
            <div class="title">Check your email</div>
            <div class="text">
              We've sent a verification link to your email address.<br>
              Please click on it to access your console.
            </div>
          </div>
        </div>
        <div class="actions">
          <div>
            <a mat-stroked-button color="primary" href="https://gmail.com" target="_blank" class="large_button">
              <mat-icon svgIcon="gmail" class="button_icon"></mat-icon>
              Open Gmail
            </a>
          </div>
          <div>
            <a mat-stroked-button color="primary" href="https://outlook.live.com" target="_blank" class="large_button">
              <mat-icon svgIcon="outlook" class="button_icon"></mat-icon>
              Open Outlook
            </a>
          </div>

          <div class="resend_block">
            Couldn’t find the email?&nbsp;
            @if (isSendButtonDisabled) {
              <span class="resend_link_disabled">Resend link</span>
            } @else {
              <a (click)="resendEmailVerificationLink()">Resend link</a>
            }
          </div>

        </div>
      </div>
    </div>
    <div>
      <a routerLink="/" class>Back to Sign In</a>
    </div>
  </div>
</div>
