@let triggersData = triggersDataSubject | async ;
@let transformersData = transformersDataSubject | async ;
@let securityRulesData = securityRulesDataSubject | async ;
@if ((triggersData?.headerRow?.length || transformersData?.headerRow?.length || securityRulesData?.headerRow?.length)) {
  <div
    class="functions_container">
    <div class="functions_title_container">
      <div class="icon_and_title">
        <div class="icon_wrapper">
          <mat-icon class="icon" svgIcon="faders_icon"></mat-icon>
        </div>
        <div class="title">
          {{ title }}<span class="separator">//</span><span class="subtitle">Backend functions</span>
        </div>
      </div>
      <div class="readonly_and_chevron">
        <div class="read_only_badge">READ ONLY</div>
        <button (click)="expanded = !expanded"
                [ngClass]="{ expanded: expanded }"
                class="secondary_icon_button"
                mat-flat-button>
          <mat-icon class="chevron_icon" svgIcon="chevron_right_icon"></mat-icon>
        </button>
      </div>
    </div>

    @if (expanded) {
      <div class="functions">
        @let hasTriggerRows = triggersData?.headerRow?.length ;
        @let hasTransformersRows = transformersData?.headerRow?.length ;
        @if (hasTriggerRows || hasTransformersRows) {
          <div [ngClass]="{ grid_2_container: hasTriggerRows && hasTransformersRows}" class="grid_container">
            @if (triggersData) {
              <mini-bundle-data-table [data]="triggersData" class="function_metadata" />
            }
            @if (transformersData) {
              <mini-bundle-data-table [data]="transformersData" class="function_metadata" />
            }
          </div>
        }
        @if (securityRulesData) {
          <mini-bundle-data-table [data]="securityRulesData" class="function_metadata" />
        }
      </div>
    }
  </div>
}
