import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { kebabCase } from 'change-case';

@Component({
  selector: 'schema-card',
  templateUrl: './schema-card.component.html',
  styleUrls: ['./schema-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaCardComponent {
  @Input() expanded = false;
  @Input() titleText!: string;
  @Input() icon!: string;
  @Input() elevated = false;
  @Input() hideToggle = false;

  @Output() add = new EventEmitter<void>();

  toggle(): void {
    this.expanded = !this.expanded;
  }

  getAddButtonTestId(title: string): string {
    return `add-${kebabCase(title)}-button`;
  }
}
