export type TabMaps = {
  tabIdToIndexMap: Record<string, number>;
  indexToTabIdMap: Record<number, string>;
};

export function getTabMaps(tabIds: Array<string>): TabMaps {
  const tabIdToIndexMap: Record<string, number> = {};
  const indexToTabIdMap: Record<number, string> = {};
  tabIds.forEach((tabId, index) => {
    tabIdToIndexMap[tabId] = index;
    indexToTabIdMap[index] = tabId;
  });

  return {
    tabIdToIndexMap,
    indexToTabIdMap,
  };
}
