import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

const STEP = 5;

@Component({
  selector: 'seabed',
  templateUrl: './seabed.component.html',
  styleUrl: './seabed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideAndSnapBack', [
      state('', style({ transform: 'translateX(calc({{ lastTranslation }}%/ 3))' }), {
        params: { lastTranslation: -100 },
      }),
      transition('* => move', [
        animate(
          '0.75s ease-in-out',
          keyframes([
            style({ transform: 'translateX(calc({{ lastTranslation }}%/ 3))', offset: 0 }),
            style({ transform: 'translateX(calc({{ translation }}%/ 3))', offset: 0.9999 }),
            style({ transform: 'translateX(calc({{ translation }}%/ 3))', offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class SeabedComponent implements OnChanges {
  @Input() index: number = 0;
  private lastIndex: number = 0;

  translation: number = -100;
  lastTranslation: number = -100;

  animationState: 'move' | '' = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['index']) {
      this.translation = this.lastTranslation + (this.lastIndex - this.index) * (1 / STEP) * 100;
      this.lastIndex = this.index;
      this.animationState = 'move';
    }
  }

  done(): void {
    const interval = STEP * 3;
    let index = ((this.lastIndex % interval) + interval) % interval;
    if (index < STEP) {
      index += STEP;
    } else if (index >= STEP * 2) {
      index -= STEP;
    }
    this.lastTranslation = ((index % interval) / interval) * -300;
    this.animationState = '';
  }
}
