import { LogLevel } from 'logpipes';

export const DATADOG_PUBLIC_KEY_FOR_LOGS = 'pubc0c495846e8468c5d0220cf0149e28fd';
export const DATADOG_CONSOLE_SERVICE_NAME = 'console';
export const DATADOG_ENV_PRODUCTION = 'prod';
export const DATADOG_ENV_DEVELOPMENT = 'dev';

/** List of DD statuses used by @datadog/browser-logs package. */
export const DATA_DOG_LOG_MESSAGE_STATUSES = ['debug', 'info', 'warn', 'error'] as const;
export type DataDogLogMessageStatus = (typeof DATA_DOG_LOG_MESSAGE_STATUSES)[number];

export function convertConsoleLogLevelToDataDogLogStatus(logLevel: LogLevel): DataDogLogMessageStatus {
  switch (logLevel) {
    case 'debug':
    case 'trace':
      return 'debug';
    case 'info':
    case 'log':
      return 'info';
    case 'warn':
      return 'warn';
    case 'error':
      return 'error';
  }
}
