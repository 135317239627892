<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="appObs | async as app">
    <ng-container *ngIf="getEnvironmentId(app.appId) as environmentId">
      <doc *ngIf="apiKeySubject | async as apiKey"
           [markdownFileUrl]="'assets/docs/application-overview/deploy-backend.md'"
           [replaceableParams]="{apiKey, environmentId, stageParameter }">
      </doc>
    </ng-container>
  </ng-container>
</ng-container>
