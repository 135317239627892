<div class="page_header" [ngClass]="{
 bottom_border: withBottomBorder,
 full_width: fullWidth,
  }">
  <div class="left">
    <div class="title_wrapper">
      <span class="title_prefix">{{ title }}</span>
      @if (subtitle) {
        <span class="separator">//</span>
        <span class="title">{{ subtitle }}</span>
      }
    </div>
    <div class="description" *ngIf="description">{{ description }}</div>
    <div class="image_and_secondary" *ngIf="image || secondaryTitle">
      @if (image) {
        <img class="image" [src]="image" />
      }
      @if (secondaryTitle) {
        <div class="secondary_text_wrapper">
          <div class="secondary_title" [innerHTML]="secondaryTitle"></div>
          <div class="secondary_description" [innerHTML]="secondaryDescription"></div>
        </div>
      }
    </div>
  </div>
  <div class="right">
    <ng-content></ng-content>
  </div>
</div>
