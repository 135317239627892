import { isPlaywrightTestMode } from '@squidcloud/internal-common/utils/e2e-test-utils';
import { createJsonStringifyPipe, installConsoleOverrides } from 'logpipes';
import { disableTimestampsInLog, enableDebugLogs } from '@squidcloud/internal-common/utils/global.utils';

export function logE2eTestMessage(message: string | (() => string)): void {
  if (!isPlaywrightTestMode()) {
    return;
  }
  if (typeof message === 'function') {
    message = message();
  }
  console.log('[e2e]', message);
}

export function initPlaywrightModeSupport(): void {
  if (isPlaywrightTestMode()) {
    enableDebugLogs();
    disableTimestampsInLog(); // Playwright already logs timestamps for all messages.
    // To see object details (like Map/Set) logged into console in Playwright we need a formatter.
    // Otherwise, we will see something like this:  'ClientRequestId to local documents: Map(4)'.
    installConsoleOverrides(
      createJsonStringifyPipe({
        levelPropertyName: null,
        timestampPropertyName: null,
        messageIdPropertyName: null,
      }),
    );
  }
}
