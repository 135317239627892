export const ONBOARDING_FLOW_ID_PARAMETER = 'flow';
export const ONBOARDING_FLOW_IDS = ['archer-lite', 'widget', 'todo', 'starter'] as const;
export type OnboardingFlowId = (typeof ONBOARDING_FLOW_IDS)[number];

export const ONBOARDING_ENTRY_STEP_PARAMETER = 'step';
export const ONBOARDING_ENTRY_STEPS = ['name', 'role'] as const;
export type OnboardingEntryStep = (typeof ONBOARDING_ENTRY_STEPS)[number];

export const ONBOARDING_TYPE_PARAMETER = 'type';
export const ONBOARDING_TYPES = ['standard', 'return'] as const;
export type OnboardingType = (typeof ONBOARDING_TYPES)[number];

export const ONBOARDING_PAGE_PATH = 'onboarding';

export type OnboardingStepConfig = {
  progress: number;
  number?: [number, number];
  hideButton?: boolean;
  steps?: Record<string, OnboardingStepConfig>;
};

export type OnboardingButtonType = 'skip' | 'back';

export type OnboardingConfig = {
  type: OnboardingType;
  showProgress?: boolean;
  showSteps?: boolean;
  showDone?: boolean;
  button?: OnboardingButtonType;
  steps: Record<string, OnboardingStepConfig>;
};

export const StandardConfig: OnboardingConfig = {
  type: 'standard',
  showProgress: true,
  showSteps: true,
  showDone: true,
  button: 'skip',
  steps: {
    welcome: {
      progress: 0,
      hideButton: true,
    },
    entry: {
      number: [1, 2],
      progress: 25,
      // steps: {
      //   role: {
      //     number: [2, 3],
      //     progress: 40,
      //     hideButton: true,
      //   },
      // },
      hideButton: true,
    },
    cards: {
      progress: 50,
      number: [2, 2],
    },
    application: {
      progress: 60,
    },
    'getting-started': {
      progress: 75,
    },
    done: {
      progress: 100,
    },
  },
};

export const ReturnConfig: OnboardingConfig = {
  type: 'return',
  showProgress: true,
  button: 'back',
  steps: {
    cards: {
      progress: 0,
      number: [3, 3],
    },
    application: {
      progress: 33,
    },
    'getting-started': {
      progress: 67,
    },
  },
};

export const OnboardingConfigs: Record<OnboardingType, OnboardingConfig> = {
  standard: StandardConfig,
  return: ReturnConfig,
};
