@if (accountService.user$ | async; as user) {
  <div class="identity_provider_options">
    @for (option of identityProviderOptions; track option.provider) {
      @let isLinked = checkAccountIsLinked(option, user) ;
      <div class="identity_provider_option">
        <button mat-stroked-button
                (click)="handleLinkButtonClick(user, option)"
                [matTooltip]="getButtonTooltipText(option, isLinked, user)"
                matTooltipClass="pre_line"
                matTooltipPosition="right"
                [ngClass]="{'linked': isLinked}"
        >
          {{ option.label }}
        </button>
      </div>
    }
  </div>
}
