import {
  BackendFunctionExecutionCounts,
  BundleDataFunctionRow,
  getServiceNameFromFunctionName,
} from '@squidcloud/console-common/types/metrics.types';
import { formatAsTimePeriod } from '@squidcloud/console-web/app/global/utils/formatting-utils';
import { BundleDataTableColumn } from '@squidcloud/console-web/app/global/components/bundle-data-table/bundle-data.types';

export function compareByFunctionId(r1: BundleDataFunctionRow, r2: BundleDataFunctionRow): number {
  const functionName1 = r1.functionData.extras['id'] as string;
  const functionName2 = r2.functionData.extras['id'] as string;
  return functionName1.localeCompare(functionName2);
}

export function newSuccessColumn(counts: BackendFunctionExecutionCounts): BundleDataTableColumn {
  return {
    value: `${counts.success}`,
    sortValue: counts.success,
  };
}

export function newErrorsColumn(counts: BackendFunctionExecutionCounts): BundleDataTableColumn {
  return {
    value: `${counts.errors}`,
    sortValue: counts.errors,
    class: counts.errors > 0 ? 'error' : 'muted',
  };
}

export function newMedianColumn(counts: BackendFunctionExecutionCounts): BundleDataTableColumn {
  return {
    value: formatAsTimePeriod(counts.successMedian),
    sortValue: counts.successMedian,
  };
}

export function newP95Column(counts: BackendFunctionExecutionCounts): BundleDataTableColumn {
  return {
    value: formatAsTimePeriod(counts.successP95),
    sortValue: counts.successP95,
  };
}

export function newServiceNameColumn(functionName: string): BundleDataTableColumn {
  return {
    value: getServiceNameFromFunctionName(functionName),
  };
}

export function newTextColumn(text: string): BundleDataTableColumn {
  return { value: text };
}

export function newTextColumnWithTooltip(text: string): BundleDataTableColumn {
  return { value: text, tooltip: text };
}

export function newLinkColumn(url: string, text: string): BundleDataTableColumn {
  return {
    value: text,
    link: url,
    tooltip: url,
    target: '_blank',
  };
}
