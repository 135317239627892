import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LogLevel } from '../logs.types';

@Component({
  selector: 'log-level',
  templateUrl: './log-level.component.html',
  styleUrls: ['./log-level.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogLevelComponent {
  @Input()
  level!: LogLevel;
}
