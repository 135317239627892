import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularComponent } from '@squidcloud/console-web/app/utils/angular-utils';

export interface DocumentationWithComponentDialogData {
  title?: string;
  docComponent: AngularComponent;
}

@Component({
  selector: 'app-documentation-with-component-dialog',
  templateUrl: './documentation-with-component-dialog.component.html',
  styleUrls: ['./documentation-with-component-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentationWithComponentDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) protected readonly data: DocumentationWithComponentDialogData) {}
}
