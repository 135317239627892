import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getIntegration } from '../../utils/content';
import { IntegrationType } from '@squidcloud/client';

@Component({
  selector: 'integration-header',
  styleUrls: ['./integration-header.component.scss'],
  templateUrl: './integration-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationHeaderComponent {
  @Input() title!: string;
  @Input() integrationType!: string;
  @Input() step: [number, number] = [-1, -1];
  @Input() withTabs = true;
  @Input() description?: string;
  @Input() secondaryTitle?: string;
  @Input() integrationPageText?: string;
  @Input() forceSubtitle?: string;

  get subtitleMessage(): string {
    return this.forceSubtitle || this.stepMessage || 'Integration';
  }

  getSecondaryTitle(): string {
    return this.secondaryTitle || getIntegration(this.integrationType as IntegrationType).name;
  }

  get secondaryDescription(): string {
    return (
      this.integrationPageText ||
      this.description ||
      getIntegration(this.integrationType as IntegrationType).description
    );
  }

  get stepMessage(): string {
    if (this.step[0] < 1) return '';
    return `Step ${this.pad(this.step[0])} of ${this.pad(this.step[1])}`;
  }

  private pad(number: number): string {
    return String(number).padStart(2, '0');
  }
}
