import { BaseIntegrationConfig } from './schemas';

/** @internal. */
export interface DatadogIntegrationConfig extends BaseIntegrationConfig {
  configuration: DatadogIntegrationConfiguration;
}

/** @internal. */
export interface DatadogIntegrationConfiguration {
  apiKey: string;
  appKey: string;
  datadogRegion: DatadogRegion;
}

/** @internal. */
export type DatadogRegion = 'us1' | 'us3' | 'us5' | 'eu1' | 'us1-fed' | 'ap1';

/** @internal. */
export interface NewRelicIntegrationConfig extends BaseIntegrationConfig {
  configuration: NewRelicIntegrationConfiguration;
}

/** @internal. */
export const datadogRegionMap: Record<DatadogRegion, string> = {
  us1: 'US1 - East',
  us3: 'US3 - West',
  us5: 'US5 - Central',
  eu1: 'EU1 - Europe',
  ap1: 'AP1 - Asia',
  'us1-fed': 'US1-FED (FedRamp)',
};

/** @internal. */
export const datadogLogsUrlMap: Record<DatadogRegion, string> = {
  us1: 'https://http-intake.logs.datadoghq.com',
  us3: 'https://http-intake.logs.us3.datadoghq.com',
  us5: 'https://http-intake.logs.us5.datadoghq.com',
  eu1: 'https://http-intake.logs.datadoghq.eu',
  ap1: 'https://http-intake.logs.ap1.datadoghq.com',
  'us1-fed': 'https://http-intake.logs.ddog-gov.com',
};

/** @internal. */
export type NewRelicRegion = 'worldwide' | 'eu';

/** @internal. */
export interface NewRelicIntegrationConfiguration {
  apiKey: string;
  newRelicRegion: NewRelicRegion;
}

/** @internal. */
export const newRelicRegionMap: Record<NewRelicRegion, string> = {
  worldwide: 'Worldwide',
  eu: 'Europe',
};
