<div class="field_name_and_field">
  <div class="name_and_description">
    <div class="name">
      {{name}}
    </div>
    <span class="description" *ngIf="description">{{description}}</span>
  </div>

  <div class="form_field boolean_field_container">
    <mat-slide-toggle
      (change)="toggle($event)" [(ngModel)]="expanded"
      class="section_toggle_button"
      color="primary"></mat-slide-toggle>
  </div>
</div>

<injection-table #injectionTable
                 [ngClass]="{hidden}"
                 [schema]="control.value"
                 [isModified]="control.dirty"
                 (schemaChanged)="handleSchemaChange($event)" />
