import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { truthy } from 'assertic';

@Component({
  selector: 'portal',
  templateUrl: './portal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalComponent implements AfterViewInit {
  @Input() teleport = true;
  @Input() emitter!: EventEmitter<TemplateRef<unknown>>;

  @ViewChild('template') templateRef: TemplateRef<unknown> | undefined;

  ngAfterViewInit(): void {
    this.emitter && this.emitter.emit(truthy(this.templateRef));
  }
}
