import { from, Observable, race } from 'rxjs';
import { map } from 'rxjs/operators';
import { RpcManager } from './rpc.manager';
import { ClientIdService } from './client-id.service';
import { deserializeObj, serializeObj } from '../../internal-common/src/utils/serialization';
import { ExecuteBackendFunctionRequest } from '../../internal-common/src/types/backend-function.types';
import { BackendFunctionResultData } from '../../internal-common/src/types/socket.types';

/** @internal */
export class BackendFunctionManager {
  constructor(
    private readonly clientIdService: ClientIdService,
    private readonly rpcManager: RpcManager,
  ) {}

  executeFunctionAndSubscribe<T>(functionName: string, ...params: unknown[]): Observable<T> {
    const fileArr: unknown[] = [];
    const paramArr: unknown[] = [];
    params.forEach(param => {
      if (typeof File !== 'undefined' && param instanceof File) {
        fileArr.push(param);
      } else {
        paramArr.push(param);
      }
    });

    const request: ExecuteBackendFunctionRequest = {
      functionName,
      paramsArrayStr: serializeObj(paramArr),
    };

    // Append '?functionName' suffix to every POST request for visibility in the browser's 'Network' tab.
    const postUrl = `backend-function/execute?${encodeURIComponent(functionName)}`;
    return race(
      from(
        this.rpcManager.post<BackendFunctionResultData>(
          postUrl,
          request,
          fileArr.length > 0 ? (fileArr as File[]) : [],
        ),
      ).pipe(
        map(response => {
          if (!response.success) {
            throw new Error(response.payload);
          }
          return deserializeObj(response.payload);
        }),
      ),
      this.clientIdService.observeClientTooOld().pipe(
        map(() => {
          throw new Error('CLIENT_NOT_CONNECTED');
        }),
      ),
    );
  }
}
