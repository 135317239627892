import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmail } from 'assertic';
import { Type } from '@angular/core';

export function trackById<T extends { id: string | number }>(_: number, item: T): string {
  return `${item.id}`;
}

export function getSortedKeys(record: Record<string, unknown> | undefined): string[] {
  return record ? Object.keys(record).sort() : [];
}

export function getEntries<T>(record: T | undefined, sortByKey = true): Array<[string, T[keyof T]]> {
  let result = (record ? Object.entries(record) : []) as Array<[string, T[keyof T]]>;
  if (sortByKey) {
    result = result.sort((e1, e2) => e1[0].localeCompare(e2[0]));
  }
  return result;
}

export class SquidAngularValidators {
  /** Backend compatible email validator. */
  static email(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!isEmail(value)) {
      return { invalidEmail: true };
    }
    return null;
  }
}

export type AngularComponent<T = unknown> = Type<T>;
