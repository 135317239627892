@let isAdmin = ('ADMIN' | minRole) ;
<div class="schema_card" [ngClass]={elevated}>
  <div class="title_row" [ngClass]="{expanded}">
    <div class="icon_and_title">
      @if (icon) {
        <div class="icon_wrapper">
          <mat-icon class="icon" [svgIcon]="icon" />
        </div>
      }
      <div class="title truncate">{{ titleText }}</div>
    </div>
    <div class="buttons">
      <ng-content select=[header]></ng-content>
      @if (!hideToggle) {
        <button (click)="toggle()"
                class="plus_button secondary_icon_button"
                mat-flat-button
                title="Add field">
          <mat-icon svgIcon="chevron_right_icon" [ngClass]="{ expanded: expanded }" class="chevron_icon menu_icon" />
        </button>
      }

      @if (add.observed) {
        <div class="inline_block" [matTooltip]="isAdmin ? '' : 'You need to be an admin to add items'">
          <button
            [disabled]="!isAdmin"
            (click)="add.emit()"
            class="edit_button secondary_icon_button"
            mat-flat-button
            title="Add {{titleText}}"
            [attr.data-testid]="getAddButtonTestId(titleText)"
          >
            <mat-icon class="plus_icon" svgIcon="plus_icon" />
          </button>
        </div>
      }
    </div>
  </div>
  @if (expanded) {
    <ng-content />
  }
</div>
