@let isAdmin = ('ADMIN' | minRole) ;
<div class="schema_table_header">
  <div class="title_row">
    @if (icon) {
      <div class="icon_wrapper title_icon">
        <mat-icon class="icon" [svgIcon]="icon" />
      </div>
    }
    @if (isSearching) {
      <input matInput type="text" class="search_input" placeholder="Search {{ title }}"
             (input)="searchAction($event)" />
    } @else {
      <div class="title truncate {{ icon && 'title_with_icon' }}">{{ title }}</div>
    }

    <div class="badge_and_buttons">
      <ng-content select="[badge]"></ng-content>
      @if (isModified) {
        <div class="badge">Unsaved</div>
      }
      @if (generateTypeScriptInterface.observed && !hideGenerateTypeScriptInterface) {
        <button mat-flat-button
                (click)="generateTypeScriptInterface.emit()"
                class="edit_button secondary_icon_button"
                title="Generate TypeScript interface for '{{title}}'">
          <mat-icon class="typescript_icon" svgIcon="typescript" />
        </button>
      }
      @if (generateAiDescription.observed && !hideGenerateAiDescription) {
        <button mat-flat-button
                (click)="generateAiDescription.emit()"
                class="edit_button secondary_icon_button"
                title="Generate AI descriptions for '{{title}}'">
          <mat-icon svgIcon="ai_icon" />
        </button>
      }
      @if (edit.observed && !hideEdit && method) {
        <div class="column outline_badge" [ngClass]="method | lowercase">
          {{ method | uppercase }}
        </div>
      }
      @if (edit.observed && !hideEdit) {
        <button mat-flat-button
                (click)="edit.emit()"
                class="edit_button secondary_icon_button"
                title="Edit '{{title}}'">
          <mat-icon class="edit_icon" svgIcon="edit_icon" />
        </button>
      }
      @if (search.observed) {
        <button mat-flat-button
                (click)="onSearchClick()"
                [disabled]="!isAdmin"
                class="plus_button secondary_icon_button"
                title="Add item">
          <mat-icon class="edit_icon {{ this.isSearching && 'close_icon' }}"
                    svgIcon="{{ this.isSearching ? 'close_icon' : 'search_icon' }}" />
        </button>
      }
      @if (add.observed && !hideAdd) {
        <div [matTooltip]="isAdmin ? '' : 'You need to be an admin to add items'">
          <button mat-flat-button
                  (click)="add.emit()"
                  [disabled]="!isAdmin"
                  class="edit_button secondary_icon_button"
                  title="Add item">
            <mat-icon class="edit_icon" svgIcon="plus_icon" />
          </button>
        </div>
      }
    </div>
  </div>
</div>

