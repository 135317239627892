import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsGuard implements CanActivate {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
  ) {}

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const application = await firstValueFrom(this.applicationService.observeCurrentApplication());
    if (!application) return this.router.createUrlTree(['/']);

    const integrationUrl = state.url.split('/').filter(Boolean);
    return this.router.createUrlTree(['application', application.appId, ...integrationUrl]);
  }
}
