import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  OnboardingButtonType,
  OnboardingConfig,
  OnboardingConfigs,
  OnboardingStepConfig,
  OnboardingType,
} from '@squidcloud/console-web/app/onboarding/onboarding.types';

@Injectable()
export class OnboardingService {
  private progressSubject = new BehaviorSubject<number>(0);
  private stepNumberSubject = new BehaviorSubject<[number, number] | undefined>(undefined);

  onboardingOrganizationId: string | undefined;
  private config: OnboardingConfig = OnboardingConfigs['standard'];
  private step: OnboardingStepConfig | undefined;

  get type(): OnboardingType {
    return this.config.type;
  }

  setType(type: OnboardingType, path: string): void {
    this.config = OnboardingConfigs[type];
    this.setPath(path);
  }

  get showSteps(): boolean {
    return !!this.config.showSteps;
  }

  get showProgress(): boolean {
    return !!this.config.showProgress;
  }

  get showDone(): boolean {
    return !!this.config.showDone;
  }

  get button(): OnboardingButtonType | undefined {
    return this.step?.hideButton ? undefined : this.config.button;
  }

  setPath(path: string): void {
    const parts = path.split('?')[0].split('/').slice(2);
    let step: OnboardingStepConfig | undefined = undefined;
    let steps = this.config.steps;

    for (const part of parts) {
      step = steps[part];
      if (step?.steps) {
        steps = step.steps;
      } else {
        break;
      }
    }

    this.setStep(step);
  }

  private setStep(step: OnboardingStepConfig | undefined): void {
    this.step = step;
    this.setProgress(step?.progress || 0);
    this.stepStepNumber(step?.number);
  }

  private setProgress(progress: number): void {
    this.progressSubject.next(progress);
  }

  observeProgress(): Observable<number> {
    return this.progressSubject.asObservable();
  }

  private stepStepNumber(number: [number, number] | undefined): void {
    this.stepNumberSubject.next(number);
  }

  observeStepNumber(): Observable<[number, number] | undefined> {
    return this.stepNumberSubject.asObservable();
  }
}
