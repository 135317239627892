import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

export type SquidTheme = 'light' | 'dark';

export const THEME_ICONS = [
  'ai_icon',
  'amex',
  'api_endpoint_icon',
  'api_icon',
  'api_request_icon',
  'api_response_icon',
  'arrow_clockwise_icon',
  'arrow_down',
  'aws-dev_app_overview_icon',
  'aws_app_overview_icon',
  'aws_icon_dark',
  'aws_icon_light',
  'azure_icon_dark',
  'azure_icon_light',
  'backend_functions_icon',
  'backend_icon',
  'calendar_icon',
  'cartes_bancaires',
  'chats_icon',
  'check_circle_filled_icon',
  'check_icon',
  'chevron_right_icon',
  'close_icon',
  'collapse_icon',
  'copy_icon',
  'credit_card_icon',
  'danger_icon',
  'dark_theme_icon',
  'deep_sea_icon',
  'diners',
  'disconnect_icon',
  'discover',
  'documentation_icon',
  'duplicate_icon',
  'edit_icon',
  'elo',
  'eye_icon',
  'faders_icon',
  'feedback_icon',
  'file_doc_icon',
  'file_dotted_icon',
  'file_plus_icon',
  'gcp_app_overview_icon',
  'gcp_icon_dark',
  'gcp_icon_light',
  'globe_icon',
  'gmail',
  'info',
  'injection_icon',
  'integrations_icon',
  'jcb',
  'light_theme_icon',
  'link_icon',
  'little_wave_icon',
  'lock_icon',
  'log_level_icon',
  'logout_icon',
  'logs_icon',
  'logo_long',
  'maestro',
  'mastercard',
  'megaphone_icon',
  'menu_dots_icon',
  'minimize_icon',
  'moon_icon',
  'onboarding_angular_icon',
  'onboarding_logo_icon',
  'onboarding_react_icon',
  'onboarding_typescript_icon',
  'onboarding_vue_icon',
  'organization_icon',
  'outlook',
  'overview_icon',
  'peeking_rudder_dark',
  'peeking_rudder_light',
  'plus_icon',
  'profile_icon',
  'question_icon',
  'reveal_icon',
  'right_arrow_icon',
  'robot_icon',
  'rocket_icon',
  'sailor_searching_icon',
  'schedulers_icon',
  'search_icon',
  'secrets_icon',
  'security_rules_icon',
  'select_arrow_down',
  'settings_icon',
  'ship',
  'short_logo_dark',
  'short_logo_light',
  'spinner_icon',
  'star_icon',
  'table_icon',
  'tag_icon',
  'tooltip_icon',
  'trash_icon',
  'triggers_icon',
  'usage',
  'unionpay',
  'unreveal_icon',
  'visa',
  'warning_icon',
  'warning_circle_icon',
  'webhooks_icon',
  'whale_dive',
  'wheel',
  'browser_collections_icon',
  'browser_documents_icon',
  'browser_data_icon',
  'browser_row_icon',
  'browser_row_selected_icon',
  'browser_expand_icon',
  'browser_filter_icon',
  'browser_sort_icon',
  'browser_ascending_icon',
  'browser_descending_icon',
  'typescript',
  'analytics_icon',
  'folder',
  'folder_open',
  'discord',
  'envelope_simple',
  'selection_foreground',
  'caret_down',
  'logo',
] as const;

export type IconType = (typeof THEME_ICONS)[number];

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private readonly themeSubject = new ReplaySubject<SquidTheme>(1);

  illustrationList = [
    'coming_soon',
    'no_applications',
    'nothing_to_see',
    'quickstart_rudder_1',
    'quickstart_rudder_2',
    'quickstart_rudder_3',
    'quickstart_rudder_4',
    'quickstart_rudder_5',
    'quickstart_rudder_6',
    'quickstart_rudder_7',
    'quickstart_rudder_8',
    'quickstart_rudder_9',
    'quickstart_rudder_10',
    'no_stored_secrets',
    'rudder_look_over_there',
    'info_rudder_peeking',
    'info_rudder_mech',
    'info_rudder_mech_peeking',
    'rudder_mechanical',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.initializeIcons();
    this.initializeIllustrations();
    this.setInitialTheme();
  }

  setTheme(theme: SquidTheme, saveToLocalStorage = false): void {
    this.document.body.classList.remove('light', 'dark');
    this.document.body.classList.add(theme);
    if (saveToLocalStorage) {
      this.localStorageService.setItem('theme', theme);
    }
    this.themeSubject.next(theme);
  }

  get theme$(): Observable<SquidTheme> {
    return this.themeSubject.asObservable();
  }

  private initializeIcons(): void {
    for (const iconName of THEME_ICONS) {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${iconName}.svg`),
      );
    }
  }

  private initializeIllustrations(): void {
    for (const iconName of this.illustrationList) {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/illustrations/${iconName}.svg`),
      );
    }
  }

  private setInitialTheme(): void {
    const theme = this.localStorageService.getItem<SquidTheme | undefined>('theme');
    if (theme) {
      this.setTheme(theme);
    } else {
      const window = this.document.defaultView as Window;
      this.setTheme(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    }
  }
}
