/**
 * The appId is the unique identifier of an application.
 * It is the combination of the application id (as shown in the console), environment id (dev, prod) and the
 * developer id (if exists). For example - "fdgfd90ds-dev-1234567890abcdef"
 */
import { assertString, assertTruthy } from 'assertic';
import { AppId, EnvironmentId, SquidDeveloperId } from '../public-types/communication.public-types';

export type ChatId = string;

/** @internal */
export interface ParsedAppId {
  appId: AppId;
  environmentId: EnvironmentId;
  squidDeveloperId?: SquidDeveloperId;
}

/** @internal */
export function parseAppId(appId: AppId): ParsedAppId {
  assertString(appId, 'Invalid application ID');
  const [appIdWithoutEnv, environmentId, squidDeveloperId, other] = appId.split('-');
  assertTruthy(!other, `Invalid application ID: ${appId}`);
  return {
    appId: appIdWithoutEnv,
    environmentId: (environmentId ?? 'prod') as EnvironmentId,
    squidDeveloperId,
  };
}

/** @internal */
export function appIdWithEnvironmentId(appId: AppId, environmentId: EnvironmentId | undefined): AppId {
  const parsedAppId = parseAppId(appId);
  return `${parsedAppId.appId}${!environmentId || environmentId === 'prod' ? '' : `-${environmentId}`}`;
}

/** @internal */
export function appIdWithEnvironmentIdAndDevId(
  appId: AppId,
  environmentId: EnvironmentId | undefined,
  developerId: SquidDeveloperId | undefined,
): AppId {
  return `${appIdWithEnvironmentId(appId, environmentId)}${developerId ? `-${developerId}` : ''}`;
}

/** @internal */
export function validateEnvironment(appId: AppId): ParsedAppId {
  const parsedAppId = parseAppId(appId);
  assertTruthy(
    parsedAppId.environmentId !== 'prod' || !parsedAppId.squidDeveloperId,
    'Cannot use developer ID in production',
  );
  return parsedAppId;
}

/** @internal */
export function verifyWithSquidDevId(appId: AppId): void {
  const parsedAppId = parseAppId(appId);
  assertTruthy(parsedAppId.squidDeveloperId, 'Must provide squid developer ID');
}

/** @internal */
export function omitSquidDevId(appId: AppId): AppId {
  const parsedAppId = parseAppId(appId);
  return appIdWithEnvironmentId(parsedAppId.appId, parsedAppId.environmentId);
}
