import { RpcManager } from '../rpc.manager';
import { QuerySender } from '../query/query-sender';
import { DebugLogger } from '../../../internal-common/src/utils/global.utils';
import { LockManager } from '../../../internal-common/src/utils/lock.manager';
import { IntegrationId, Mutation } from '../public-types';
import { MutateRequest, MutateResponse, reduceMutations } from '../../../internal-common/src/types/mutation.types';
import { getSquidDocId } from '../../../internal-common/src/types/document.types';

/** @internal */
export class MutationSender {
  constructor(
    private readonly rpcManager: RpcManager,
    private readonly lockManager: LockManager,
    private readonly querySender: QuerySender,
  ) {}

  async sendMutations(mutations: Array<Mutation>, integrationId: IntegrationId): Promise<MutateResponse> {
    const reducedMutations = reduceMutations(mutations);
    const mutexes = reducedMutations.map(mutation => `sendMutation_${getSquidDocId(mutation.squidDocIdObj)}`);
    await this.lockManager.lock(...mutexes);
    await this.querySender.waitForAllQueriesToFinish();
    try {
      const request: MutateRequest = {
        mutations: reducedMutations,
        integrationId,
      };
      return await this.rpcManager.post<MutateResponse>('mutation/mutate', request);
    } catch (e) {
      DebugLogger.debug('Error while sending mutations', {
        error: e,
        mutations: JSON.stringify(reducedMutations, null, 2),
      });
      throw e;
    } finally {
      this.lockManager.release(...mutexes);
    }
  }
}
