<div mat-dialog-title>Send us a telegram</div>

<mat-dialog-content class="feedback_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_normal_text">We want to hear what is working, what is not and what could be better!</div>
  <form (ngSubmit)="submitFeedback()" [formGroup]="form" class="form">
    <div class="form_fields">
      @let feedbackControl = form.controls['feedback'] ;
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: feedbackControl.valid && feedbackControl.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field">
        <mat-label>Enter your feedback</mat-label>
        <textarea autocomplete="off" class="feedback_textarea" formControlName="feedback" matInput
                  name="feedback"></textarea>
      </mat-form-field>
    </div>
    <div class="dialog_buttons">
      <button [disabled]="form.invalid" class="confirm_button primary_button xl_button" mat-flat-button type="submit">
        Send
      </button>
    </div>
  </form>
</mat-dialog-content>
