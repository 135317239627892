/** @internal */
export const SECONDS_PER_MINUTE = 60;
/** @internal */
export const SECONDS_PER_HOUR = 60 * SECONDS_PER_MINUTE;
/** @internal */
export const SECONDS_PER_DAY = 24 * SECONDS_PER_HOUR;
/** @internal */
export const SECONDS_PER_WEEK = 7 * SECONDS_PER_DAY;
/** @internal */
export const SECONDS_PER_MONTH = 30 * SECONDS_PER_DAY;

/** @internal */
export const MILLIS_PER_SECOND = 1000;
/** @internal */
export const MILLIS_PER_MINUTE = SECONDS_PER_MINUTE * MILLIS_PER_SECOND;
/** @internal */
export const MILLIS_PER_HOUR = SECONDS_PER_HOUR * MILLIS_PER_SECOND;
/** @internal */
export const MILLIS_PER_DAY = SECONDS_PER_DAY * MILLIS_PER_SECOND;
/** @internal */
export const MILLIS_PER_WEEK = SECONDS_PER_WEEK * MILLIS_PER_SECOND;
/** @internal */
export const MILLIS_PER_MONTH = SECONDS_PER_MONTH * MILLIS_PER_SECOND;
