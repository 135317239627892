import { generateShortId } from '@squidcloud/internal-common/public-utils/id-utils';
import { assertString, objectAssertion } from 'assertic';

export const GCP_MP_PUB_SUB_EVENT_TYPES = [
  /** Deprecated */
  'ACCOUNT_CREATION_REQUESTED',

  /** Indicates that the customer's account has been created. */
  'ACCOUNT_ACTIVE',

  /** Indicates that the customer's account was deleted from Google Cloud systems. */
  'ACCOUNT_DELETED',

  /**
   * Indicates that a customer selected one of your pricing plans.
   * Caution: Once you turn on automatic offer approval for a SaaS product, you no longer receive
   * ENTITLEMENT_CREATION_REQUESTED or ENTITLEMENT_PLAN_CHANGE_REQUESTED Pub/Sub messages for that SaaS product.
   */
  'ENTITLEMENT_CREATION_REQUESTED',

  /**
   * Indicates that an offer was accepted by a customer.
   * Includes the scheduled start time of the offer, if there is one.
   */
  'ENTITLEMENT_OFFER_ACCEPTED',

  /** Indicates that a customer's chosen plan is now active. */
  'ENTITLEMENT_ACTIVE',

  /**
   * Indicates that a customer chose a new plan.
   * Caution: Once you turn on automatic offer approval for a SaaS product, you no longer receive
   * ENTITLEMENT_CREATION_REQUESTED or ENTITLEMENT_PLAN_CHANGE_REQUESTED Pub/Sub messages for that SaaS product.
   */
  'ENTITLEMENT_PLAN_CHANGE_REQUESTED',

  /** Indicates that a customer's plan change is approved and the changes have taken effect. */
  'ENTITLEMENT_PLAN_CHANGED',

  /**
   * Indicates that a customer's plan change was canceled, either because it wasn't approved,
   * or because they switched back to their old plan.
   */
  'ENTITLEMENT_PLAN_CHANGE_CANCELLED',

  /** Indicates that a customer canceled their plan, and the cancellation is pending until the end of the billing cycle. */
  'ENTITLEMENT_PENDING_CANCELLATION',

  /**
   * Indicates that a customer's pending cancellation was reverted.
   * Note that cancellations cannot be reverted after they are final.
   */
  'ENTITLEMENT_CANCELLATION_REVERTED',

  /** Indicates that a customer's plan was canceled. */
  'ENTITLEMENT_CANCELLED',

  /** Indicates that a customer's plan is in the process of being canceled. */
  'ENTITLEMENT_CANCELLING',

  /**
   * Indicates that a customer's entitlement was renewed for another term.
   * You don't need to take any action to complete the renewal.
   */
  'ENTITLEMENT_RENEWED',

  /**
   * Indicates that a customer's private offer has ended.
   * If the customer's entitlement was canceled, a separate ENTITLEMENT_CANCELLED event is triggered.
   * If the customer's entitlement is still active, their plan reverts to non-discounted pricing.
   */
  'ENTITLEMENT_OFFER_ENDED',

  /** Indicates that information about a customer's plan was deleted from Cloud Marketplace. */
  'ENTITLEMENT_DELETED',
] as const;

export type GcpMpPubSubEventType = (typeof GCP_MP_PUB_SUB_EVENT_TYPES)[number];

/**
 * PubSub message received from GCP marketplace.
 * Only used fields are listed.
 * Actual DB record may contain more fields sent by GCP we do not use in the code.
 */
export interface GcpMpPubSubEvent {
  /** The date Squid received this event. Extension of Google PubSub message. */
  receiveDate: Date;

  /** A free form message about event processing by Squid. Extension of Google PubSub message */
  processingDetails?: string;

  /** Unique event id. Example: CREATE_ENTITLEMENT-72785eac-acf5-4972-9943-8939ef21cbcb. */
  eventId: string;

  /** Type of the event. Example: "ENTITLEMENT_OFFER_ACCEPTED". */
  eventType: GcpMpPubSubEventType;

  /** For Squid is always 'squidcloud-public'. */
  providerId: string;

  account?: GcpMpPubSubAccount;

  entitlement?: GcpMpPubSubEventEntitlement;
}

export interface GcpMpPubSubAccount {
  /** UUID.*/
  id: string;
  /** ISO date format. */
  updateTime: string;
}

/** Entitlement info. Actual structure contains more fields, and we list here only the interested ones. */
export interface GcpMpPubSubEventEntitlement {
  /** UUID.*/
  id: string;
  /** ISO date format. */
  updateTime: string;
  /** UUID. */
  orderId: string;
}

export function generateGcpMpAccountSetupEventId(): string {
  return `gcp-account-setup-${generateShortId()}`;
}

/**
 * Customer data from Google (google field) included into JWT token payload for GCP marketplace account setup POST
 * request.
 * Example:
 * {
 *   "sub": "26595010-10b5-4ee4-8b36-e1b03374031a",
 *   "aud": "console.us-east-1.aws.squid.cloud",
 *   "iat": 1717143079,
 *   "exp": 1717143379,
 *   "google": {
 *     "user_identity": "107304556281205641809",
 *     "roles": [
 *       "account_admin"
 *     ]
 *   },
 *   "iss":...
 *  }
 */
export interface GcpMpAccountSetupCustomerData {
  user_identity: number;
  roles: Array<string>;
}

/**
 * An event stored in Squid database every time user clicks 'Set up your account' or 'Manage on Provider'
 * (https://console.cloud.google.com/marketplace/product/squidcloud-public/squid-cloud-baas-for-ai-mobile-and-web?project=squidcloud-public).
 */
export interface GcpMpAccountSetupEvent extends GcpMpAccountSetupCustomerData {
  /** Unique record id. */
  id: string;

  /** 'sub' value from the JWT. */
  sub: string;

  /** The timestamp of the click. */
  clickDate: Date;
}

/** URL parameter passed by the webhook as a part of the redirect to the console.  */
export const GCP_MP_ACCOUNT_SETUP_EVENT_ID_URL_PARAMETER = 'gcpAccountSetupEventId';

export interface ActivateGcpPurchaseRequest {
  gcpMpAccountSetupEventId: string;
}

export const activateGcpPurchaseRequestAssertion = objectAssertion<ActivateGcpPurchaseRequest>({
  gcpMpAccountSetupEventId: assertString,
});
