import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GlobalUiService } from '../../global/services/global-ui.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { CreateSecretDialogComponent } from './create-secret-dialog/create-secret-dialog.component';
import { SecretService } from './secret.service';
import { UpdateSecretDialogComponent } from './update-secret-dialog/update-secret-dialog.component';
import { CpSecret } from '@squidcloud/console-common/types/application.types';
import { SecretsScriptDialogComponent } from '@squidcloud/console-web/app/application/secrets/create-secrets-script-dialog/create-secrets-script-dialog.component';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import {
  CommonConnectionPrivateKeyAsSecrets,
  ConnectionOptionsPasswordAsSecrets,
  isBaseIntegrationConfigWithConfiguration,
  isConnectionOptionsWithSecrets,
  isIntegrationConfigurationWithConnectionOptions,
} from '@squidcloud/internal-common/types/integrations/schemas';

@Injectable({
  providedIn: 'root',
})
export class SecretUiService {
  constructor(
    private readonly dialog: MatDialog,
    private readonly globalUiService: GlobalUiService,
    private readonly secretService: SecretService,
    private readonly applicationService: ApplicationService,
    private readonly snackBar: SnackBarService,
  ) {}

  showCreateSecretDialog(): MatDialogRef<CreateSecretDialogComponent> {
    const config: MatDialogConfig = {
      maxWidth: '496px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
    };
    return this.dialog.open(CreateSecretDialogComponent, config);
  }

  showCreationScriptDialog(): MatDialogRef<unknown> {
    return SecretsScriptDialogComponent.show(this.dialog);
  }

  async showDeleteSecretDialog(secret: CpSecret): Promise<void> {
    // Check that the secret is not used by any integration.
    // We do not allow removing secrets if it is used.
    const { integrations } = this.applicationService.getCurrentApplicationOrFail();
    for (const [id, integration] of Object.entries(integrations)) {
      if (isBaseIntegrationConfigWithConfiguration(integration)) {
        const { configuration } = integration;
        if (isIntegrationConfigurationWithConnectionOptions(configuration)) {
          if (configuration.connectionOptions && isConnectionOptionsWithSecrets(configuration.connectionOptions)) {
            const { secrets } = configuration.connectionOptions;
            const isUsed =
              (secrets as ConnectionOptionsPasswordAsSecrets).password === secret.key ||
              (secrets as CommonConnectionPrivateKeyAsSecrets).privateKey === secret.key;
            if (isUsed) {
              const isConfirmed = await this.globalUiService.showConfirmationDialogAsync(
                'Review Before Proceeding',
                [
                  `The secret is currently used by an integration with ID: <b>${id}</b>.`,
                  `Are you sure you want to proceed with deleting this secret?`,
                ],
                'Proceed',
              );
              if (!isConfirmed) {
                return;
              }
              break;
            }
          }
        }
      }
    }
    await this.globalUiService.showDeleteDialog(
      `Deleting a secret cannot be undone or recovered. This will permanently delete the <span class="bold">${secret.key}</span> secret.`,
      async () => {
        try {
          await this.secretService.deleteSecret(secret.key, true);
          this.snackBar.success('Secret deleted');
        } catch (e) {
          console.error('Unable to delete secret', e);
          this.snackBar.warning('Unable to delete secret, please try again later');
        }
      },
    );
  }

  showUpdateSecretDialog(secret: CpSecret): void {
    this.dialog.open(UpdateSecretDialogComponent, {
      maxWidth: '496px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data: secret,
    });
  }
}
