import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { copyText } from '../../../utils/copy-utils';

@Component({
  selector: 'integration-id',
  templateUrl: './integration-id.component.html',
  styleUrls: ['./integration-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationIdComponent {
  @Input()
  integrationId!: string;

  constructor(private readonly snackBar: SnackBarService) {}

  async copyId(): Promise<void> {
    await copyText(this.integrationId);
    this.snackBar.success('Copied ID to clipboard');
  }
}
