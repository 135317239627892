import { BehaviorSubject, filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export type DestructorFn = () => Promise<void> | void;

export class DestructManager {
  private readonly preDestructors: Array<DestructorFn> = [];
  private readonly destructors: Array<DestructorFn> = [];
  private readonly isDestructedSubject = new BehaviorSubject<boolean>(false);

  get isDestructing(): boolean {
    return this.isDestructedSubject.value;
  }

  observeIsDestructing(): Observable<void> {
    return this.isDestructedSubject.asObservable().pipe(
      filter(Boolean),
      map(() => undefined),
    );
  }

  onPreDestruct(fn: DestructorFn): void {
    this.preDestructors.push(fn);
  }

  onDestruct(fn: DestructorFn): void {
    this.destructors.push(fn);
  }

  async destruct(): Promise<void> {
    this.reportDestructed();
    const fns = this.preDestructors.concat(this.destructors);
    let fn = fns.shift();
    while (fn) {
      try {
        await fn();
      } catch (e) {
        console.error('Error while destructing Squid', e);
      }
      fn = fns.shift();
    }
  }

  reportDestructed(): void {
    if (this.isDestructing) {
      return;
    }
    this.isDestructedSubject.next(true);
  }
}
