const E2E_TEST_USER_EMAIL_PREFIX = 'joey+synthetic-tests';
const E2E_TMP_TEST_USER_EMAIL_PREFIX = `${E2E_TEST_USER_EMAIL_PREFIX}-tmp-`;
const E2E_TEST_USER_EMAIL_SUFFIX = '@squid.cloud';

/**
 * An account used to test signup flows.
 * We do not use real Auth0 registration for every E2E test because it is too expensive.
 * Instead of this we use a pre-registered Auth0 user and cleanup all Squid resources for it during the test start.
 */
export const E2E_SIGN_UP_TEST_USER_EMAIL = `${E2E_TEST_USER_EMAIL_PREFIX}-signup${E2E_TEST_USER_EMAIL_SUFFIX}`;

export function isE2eTestUserEmail(email: string | undefined): boolean {
  return !!email && email.startsWith(E2E_TEST_USER_EMAIL_PREFIX) && email.endsWith(E2E_TEST_USER_EMAIL_SUFFIX);
}

/**
 * Checks that the mail is a new test user emails.
 * Such users are created during the test and removed by the sentinel.ts later.
 *
 * WARNING: See Auth0 (exports.onExecutePostLogin) for the email verification logic that must be in sync.
 */
export function isTemporaryTestUserEmail(email: string | undefined): boolean {
  return !!email && email.startsWith(E2E_TMP_TEST_USER_EMAIL_PREFIX) && email.endsWith(E2E_TEST_USER_EMAIL_SUFFIX);
}

/** Lucene email filter for temporary E2E user accounts. Lucene's syntax is used by Auth0. */
export const E2E_TEMPORARY_TEST_USER_LUCENE_FILTER = `${E2E_TMP_TEST_USER_EMAIL_PREFIX}*${E2E_TEST_USER_EMAIL_SUFFIX}`;
