import { Injectable } from '@angular/core';
import { filter, from, Observable, shareReplay, switchMap, timer } from 'rxjs';
import { OrganizationService } from '../organization/organization.service';
import { Squid } from '@squidcloud/client';
import { map } from 'rxjs/operators';
import { BillingPlan } from '@squidcloud/console-common/types/billing.types';
import { QuotaName } from '@squidcloud/internal-common/quota.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { MILLIS_PER_MINUTE } from '@squidcloud/internal-common/types/time-units';

interface QuotaUsageStat {
  quota: number;
  usage: number;
}

@Injectable({
  providedIn: 'root',
})
export class UsageService {
  private readonly usageStatsObs: Observable<Partial<Record<QuotaName, QuotaUsageStat>>>;

  constructor(
    private readonly organizationService: OrganizationService,
    private readonly squid: Squid,
  ) {
    this.usageStatsObs = this.organizationService.observeCurrentOrganization().pipe(
      filter(Boolean),
      switchMap(organization =>
        timer(0, 5 * MILLIS_PER_MINUTE).pipe(
          switchMap(() => from(callBackendExecutable(this.squid, 'getUsageStats', organization.id))),
          map(usageStats => {
            const { billingPlan } = organization;
            const { quotas } = billingPlan;
            const lifetimeQuotas = billingPlan.lifetimeQuotas || {};
            const gpt4Quota = quotas['numberOfGpt4Chats'] || lifetimeQuotas['numberOfGpt4Chats'] || Number.MAX_VALUE;
            const gpt35Quota = quotas['numberOfGpt4Chats'] || lifetimeQuotas['numberOfGpt35Chats'] || Number.MAX_VALUE;
            // TODO: Make sure to count new models in here
            const gpt4Usage = usageStats['gpt-4'] || 0;
            const gpt35Usage = usageStats['gpt-3.5-turbo'] || 0;
            const res: Partial<Record<keyof BillingPlan['quotas'], QuotaUsageStat>> = {
              numberOfGpt4Chats: {
                quota: gpt4Quota,
                usage: gpt4Usage,
              },
              numberOfGpt35Chats: {
                quota: gpt35Quota,
                usage: gpt35Usage,
              },
            };
            return res;
          }),
        ),
      ),
      shareReplay(1),
    );
  }

  observeIsOverQuota(): Observable<boolean> {
    return this.usageStatsObs.pipe(
      map(usageState => {
        return Object.values(usageState).some(({ quota, usage }) => quota !== Number.MAX_VALUE && usage >= quota);
      }),
    );
  }
}
