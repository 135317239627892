import { RpcManager } from './rpc.manager';
import { ListSchedulersResponse, SchedulerInfo, UpdateSchedulerOptions, UpdateSchedulersRequest } from './public-types';

export class SchedulerClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async list(): Promise<SchedulerInfo[]> {
    const response = await this.rpcManager.post<ListSchedulersResponse>('/scheduler/listSchedulers', {});
    return response.schedulers;
  }

  async enable(schedulerId: string | string[]): Promise<void> {
    await this.update(this.createUpdateSchedulerOptions(schedulerId, true));
  }

  async disable(schedulerId: string | string[]): Promise<void> {
    await this.update(this.createUpdateSchedulerOptions(schedulerId, false));
  }

  private async update(updateSchedulerOptions: UpdateSchedulerOptions[]): Promise<void> {
    const request = { schedulers: updateSchedulerOptions } as UpdateSchedulersRequest;
    await this.rpcManager.post('scheduler/updateSchedulers', request);
  }

  private createUpdateSchedulerOptions(
    schedulerId: string | string[],
    enabled: boolean | undefined,
  ): UpdateSchedulerOptions[] {
    return Array.isArray(schedulerId)
      ? schedulerId.map(id => ({ schedulerId: id, enabled }))
      : [{ schedulerId, enabled }];
  }
}
