<form (ngSubmit)="emitFormSubmitted()" [formGroup]="form">
  <div class="form_fields">
    <mat-form-field [hideRequiredMarker]="true"
                    [ngClass]="{valid: form.controls['name'].valid && form.controls['name'].touched}"
                    appearance="outline"
                    [matTooltip]="form.controls['name'].touched && !form.controls['name'].valid ? form.controls['name'].errors?.['invalidAppName'] : ''"
                    class="form_field dialog_form_field">
      <mat-label>Name your application</mat-label>
      <input autofocus [placeholder]="defaultAppName" autocomplete="off" formControlName="name" matInput
             data-testid="application-name-input"
             name="application_name">
      <div class="suffix_required_text" matSuffix>Required</div>
      <mat-icon class="suffix_check_icon" matSuffix svgIcon="check_icon"></mat-icon>
    </mat-form-field>

    <div class="cloud_text">Select cloud provider and region</div>
    <div class="region_and_cloud_container">
      <div class="all_clouds_container">
        @for (cloud of squidSupportedClouds; track cloud.id) {
          <div class="cloud_container"
               [ngClass]="{selected: cloud.id === selectedCloud.id, disabled: cloud.environments.length === 0}"
               (click)="switchCloud(cloud)"
               matTooltipClass="tooltip_above"
               [matTooltip]="cloud.tooltip || ''"
               matTooltipPosition="above"
               [attr.data-testid]="'cloud-' + cloud.id + '-button'"
          >
            <mat-icon class="cloud_icon" [svgIcon]="cloud.icon + '_dark'"></mat-icon>
          </div>
        }
      </div>

      @let regionControl = form.controls['region'] ;
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: regionControl.valid && regionControl.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-select formControlName="region" class="select_box gray_select">
          @for (option of selectedCloud.environments; track option.id) {
            <mat-option [value]="option">{{ option.name }} - {{ option.id }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
