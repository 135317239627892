import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';

@Component({
  selector: 'storylane-dialog',
  templateUrl: './storylane-dialog.component.html',
  styleUrl: './storylane-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorylaneDialogComponent implements OnInit {
  demoId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: { demoId: string },
    private readonly dialogRef: MatDialogRef<unknown>,
    private readonly sanitizer: DomSanitizer,
    private readonly analytics: AnalyticsService,
  ) {
    this.demoId = data.demoId;
  }

  async ngOnInit(): Promise<void> {
    await this.analytics.track('Storylane Demo Shown', { demoId: this.demoId });
  }

  get iframeSource(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://app.storylane.io/demo/${this.demoId}`);
  }

  close(): void {
    this.dialogRef.close();
  }
}
