import { AppId, ServiceFunctionName } from '@squidcloud/client';
import { assertTruthy, formatError, objectAssertion, undefinedOr, ValueAssertion } from 'assertic';
import { assertApplicationId } from '@squidcloud/console-common/utils/console-assertions';

export const BUNDLE_DATA_FUNCTION_TYPES = [
  'aiFunction',
  'executable',
  'openapi',
  'scheduler',
  'security',
  'trigger',
  'webhook',
] as const;

export type BundleDataFunctionType = (typeof BUNDLE_DATA_FUNCTION_TYPES)[number];

export interface GetBackendMetricsRequest {
  appId: AppId;
  periodType?: BackendMetricPeriodType;
}

export interface BackendFunctionExecutionCounts {
  errors: number;
  success: number;
  successP95: number;
  successMedian: number;
}

export interface ExtractedBundleDataFunction {
  type: BundleDataFunctionType;
  serviceFunctionName: ServiceFunctionName;
  extras: Record<string, unknown>;
}

export interface BundleDataFunctionRow {
  functionData: ExtractedBundleDataFunction;
  counts: BackendFunctionExecutionCounts;
}

export interface BackendMetricsChartData {
  /** Count of successful executions per point period. */
  successfulExecutionCountsSeries: Array<number>;
  /** Count of error executions per point period. */
  errorExecutionCountsSeries: Array<number>;
  /** Average single successful execution time per point period. */
  successfulExecutionAverageTimeSeries: Array<number>;
  /** Average single successful execution time for the whole requested time period. */
  successfulExecutionAverageTime: number;
  /** Successful + error execution count / point period time in seconds. */
  averageQpsSeries: Array<number>;
  /** Timestamp values for all other series. ISO dates. */
  timestampSeries: Array<string>;
  /** Successful + error execution count / request period time in seconds. */
  averageQps: number;
  /** Total executions of the function in the requested time period: both successful and error ones. */
  totalExecutions: number;
  /** Total executions of the function in the requested time period: both successful and error ones. */
  totalErrors: number;
}

export interface BackendMetricsSection {
  functions: Array<BundleDataFunctionRow>;
  charts: BackendMetricsChartData;
}

export const BACKEND_METRIC_PERIOD_TYPES = ['last-hour', 'last-day', 'last-week', 'last-30-days'] as const;
export type BackendMetricPeriodType = (typeof BACKEND_METRIC_PERIOD_TYPES)[number];

export interface GetBackendMetricsResponse {
  appId: AppId;
  functionsExecutionCounts: Record<BundleDataFunctionType, BackendMetricsSection>;
  /** Period type to query. If not provided 'last-hour' is used. */
  periodType?: BackendMetricPeriodType;
}

/**
 * Returns service name part (class name) from the fully qualified service function name.
 * Example: MetricsApiService:migrateApplicationsToClickHouse -> MetricsApiService
 */
export function getServiceNameFromFunctionName(serviceName: string): string {
  return serviceName.split(':')[0];
}

/*** Asserts that *value* is a valid period type value for backend metrics.  */
export const assertBackendMetricPeriodType: ValueAssertion<BackendMetricPeriodType> = (
  value: unknown,
  context = undefined,
): asserts value is AppId => {
  assertTruthy(BACKEND_METRIC_PERIOD_TYPES.includes(value as BackendMetricPeriodType), () =>
    formatError(context, 'Invalid application period type', value),
  );
};

export const getBackendMetricsRequestAssertion = objectAssertion<GetBackendMetricsRequest>({
  appId: assertApplicationId,
  periodType: undefinedOr(assertBackendMetricPeriodType),
});
