import { assertString, assertTruthy, assertUuid, formatError, ValueAssertion } from 'assertic';
import { OrganizationId } from '@squidcloud/console-common/types/organization.types';
import { isIntegrationId, isOrganizationId } from '@squidcloud/console-common/utils/console-validators';
import { AppId, IntegrationId, IntegrationType } from '@squidcloud/client';
import { MARKETPLACE_TYPES, MarketplaceType } from '@squidcloud/console-common/types/marketplace.types';

/*** Asserts that *value* is a valid organization ID.  */
export const assertOrganizationId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is OrganizationId => {
  assertTruthy(isOrganizationId(value), () => formatError(context, 'Invalid organization id', value));
};

/*** Asserts that *value* is a valid application ID.  */
export const assertApplicationId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is AppId => {
  // TODO: improve.
  assertTruthy(typeof value === 'string' && value.length > 1, () =>
    formatError(context, 'Invalid application id', value),
  );
};

/** Bills IDs are generated by M3ter and are UUIDs. */
export const assertBillId = assertUuid;

/*** Asserts that `value` is a valid integration ID.  */
export const assertIntegrationId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is IntegrationId => {
  assertTruthy(isIntegrationId(value), () => formatError(context, 'Invalid integration id', value));
};

/*** Asserts that `value` is a valid integration type and is not a built-in integration type.  */
export const assertNotBuiltInIntegrationType: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is OrganizationId => {
  assertString(value, context);
  assertTruthy(
    ![IntegrationType.built_in_db, IntegrationType.built_in_queue, IntegrationType.built_in_s3].includes(
      value as IntegrationType,
    ),
    () => formatError(context, `The value can't be a built-in integration type`, value),
  );
};

// TODO: improve.
export const assertBillingPlanTemplateId = assertString;

export const assertEventRegistrantData = (data: object): asserts data is object => {
  assertTruthy('name' in data && 'email' in data, () => formatError(undefined, 'Invalid event registrant data', data));
};

export const assertMarketplaceType: ValueAssertion<MarketplaceType> = (
  value: unknown,
  context = undefined,
): asserts value is AppId => {
  assertTruthy(MARKETPLACE_TYPES.includes(value as MarketplaceType), () =>
    formatError(context, 'Invalid marketplace type', value),
  );
};
