<div mat-dialog-title>Store secret</div>

<mat-dialog-content class="create_secret_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_normal_text">Loose lips sink ships, the best secrets are never shared. Store secrets here and
    easily link to them.
  </div>
  <form (ngSubmit)="submitCreateSecret()" [formGroup]="form" class="form">
    <div class="form_fields">
      @let keyControl = form.controls['key'] ;
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: keyControl.valid && keyControl.touched}"
                      [matTooltip]="keyControl.touched && !keyControl.valid ? keyControl?.errors?.['invalidSecretKey'] : ''"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label labelWithPrefix>Secret key</mat-label>
        <mat-hint>Use a key that enables you find and manage it easily.</mat-hint>
        <input autocomplete="off" formControlName="key" matInput name="secret_key" type="text">
        <div class="suffix_required_text" matSuffix>Required</div>
        <mat-icon class="lock_icon" matPrefix svgIcon="lock_icon"></mat-icon>
        <mat-icon class="suffix_check_icon" matSuffix svgIcon="check_icon"></mat-icon>
      </mat-form-field>

      @let valueControl = form.controls['value'] ;
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: valueControl.valid && valueControl.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field">
        <mat-label>Value</mat-label>
        <textarea autocomplete="off" class="secret_value_textarea" formControlName="value" matInput
                  name="secret_value"></textarea>
      </mat-form-field>
    </div>
    @if (errorMessage) {
      <div class="dialog_error">{{ errorMessage }}</div>
    }
    <div class="dialog_buttons">
      <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
        Cancel
      </button>
      <button [disabled]="form.invalid || serverRequestInProgress" class="primary_button xl_button"
              mat-flat-button type="submit">
        Save
      </button>
    </div>
  </form>
</mat-dialog-content>
