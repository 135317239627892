import { Injectable } from '@angular/core';

export type LocalStorageKey = 'currentOrganizationId' | 'currentApplicationId' | 'theme' | 'invitationKey';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly prefix = 'SQUID';

  getItem<T>(key: LocalStorageKey): T | undefined {
    const keyWithPrefix = this.getKeyWithPrefix(key);
    try {
      const resultStr: string | null = localStorage.getItem(keyWithPrefix);
      if (!resultStr) return undefined;
      return JSON.parse(resultStr);
    } catch (e) {
      console.error('Unable to get item from localStorage', keyWithPrefix, e);
      return undefined;
    }
  }

  removeItem(key: LocalStorageKey): void {
    const keyWithPrefix = this.getKeyWithPrefix(key);
    localStorage.removeItem(keyWithPrefix);
  }

  setItem(key: LocalStorageKey, value: unknown): void {
    if (value === undefined) return this.removeItem(key);
    const keyWithPrefix = this.getKeyWithPrefix(key);
    localStorage.setItem(keyWithPrefix, JSON.stringify(value));
  }

  getKeyWithPrefix(key: LocalStorageKey): string {
    return `${this.prefix}_${key}`;
  }
}
