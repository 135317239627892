import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {
  ERROR_PAGE_PATH,
  STATUS_PAGE_BUTTON_LABEL_QUERY_PARAMETER,
  STATUS_PAGE_ICON_QUERY_PARAMETER,
  STATUS_PAGE_MESSAGE_QUERY_PARAMETER,
  STATUS_PAGE_PATH,
  STATUS_PAGE_SUCCESS_STATE_QUERY_PARAMETER,
  STATUS_PAGE_TITLE_QUERY_PARAMETER,
} from '@squidcloud/console-common/types/shared-url-constants';

export const STATUS_PAGE_ICON_TYPES = ['error', 'status'] as const;
export type StatusPageIconType = (typeof STATUS_PAGE_ICON_TYPES)[number];

/**
 * A reusable utility page that shows some action status message to a user.
 * Can be used as an error page or as an action confirmation page (like an email confirmation redirect from Auth0).
 *
 * The title, message and button texts are passed as a query parameters: 'title', 'message', 'button'
 * The style (error or info) is defined by the 'success' query parameters.
 */
@Component({
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPageComponent {
  /** Header (title) line of the page. */
  readonly title: string;

  /** Plain text message. Shown to a user as a preformatted HTML. */
  readonly message: string;

  /** Text to show on the 'Back' button. */
  readonly buttonLabel: string;

  /** Type of the icon to show. Default is 'error'. */
  readonly iconType: StatusPageIconType;

  // Note: the page should use minimal dependencies to avoid errors in sub-services to prevent the page from rendering.
  constructor({ snapshot }: ActivatedRoute) {
    this.title = getTitleFromParameters(snapshot);
    this.message = getMessageFromParameters(snapshot);
    this.buttonLabel = getButtonLabelFromParameters(snapshot);
    this.iconType = getIconTypeFromParameters(snapshot);
  }

  /** Builds a router path of the status page (with no domain prefix). */
  static buildStatusPagePath(message: string, title?: string, buttonText?: string): string {
    return this.buildPagePath(STATUS_PAGE_PATH, message, title, buttonText, 'status');
  }

  /** Builds a router path of the error page (with no domain prefix). */
  static buildErrorPagePath(message: string, title?: string): string {
    return this.buildPagePath(ERROR_PAGE_PATH, message, title);
  }

  private static buildPagePath(
    pathPrefix: string,
    message: string,
    title?: string,
    buttonText?: string,
    icon?: StatusPageIconType,
  ): string {
    let url = `/${pathPrefix}?${STATUS_PAGE_MESSAGE_QUERY_PARAMETER}=${encodeURIComponent(message)}`;
    if (title) {
      url += `&${STATUS_PAGE_TITLE_QUERY_PARAMETER}=${encodeURIComponent(title)}`;
    }
    if (buttonText) {
      url += `&${STATUS_PAGE_BUTTON_LABEL_QUERY_PARAMETER}=${encodeURIComponent(buttonText)}`;
    }
    if (icon) {
      url += `&${STATUS_PAGE_ICON_QUERY_PARAMETER}=${encodeURIComponent(icon)}`;
    }
    return url;
  }
}

/** Absolute path of the email confirmation status page. Used by Auth0 as a callback for email confirmation. */
export const AUTH0_EMAIL_CONFIRMATION_PAGE_PATH = 'email-confirmation-status';

// Auth0 status messages sent as a part of email verification callback.
// These messages are rewritten with the Console's own texts.
// https://www.figma.com/file/kUddj2qJpeidYiXv91BeJZ/Console?type=design&node-id=6056-68935&mode=design&t=sjQpihQr2D5nwOdM-0.
const AUTH0_EMAIL_VERIFICATION_PREFIX_SUCCESS = 'Your email was verified';
const AUTH0_EMAIL_VERIFICATION_PREFIX_ALREADY_VERIFIED = 'This account is already verified';

function getTitleFromParameters(snapshot: ActivatedRouteSnapshot): string {
  let title = snapshot.queryParams[STATUS_PAGE_TITLE_QUERY_PARAMETER];
  if (!title) {
    const messageParam = snapshot.queryParams[STATUS_PAGE_MESSAGE_QUERY_PARAMETER];
    if (isErrorState(snapshot)) {
      if (messageParam?.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_ALREADY_VERIFIED)) {
        // Use a custom message for Auth0 email verification callback in case of Auth0 error.
        title = 'Email verified!';
      }
      if (!title) {
        title = 'Error!';
      }
    } else {
      // Success.
      if (messageParam?.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_SUCCESS)) {
        title = 'Email verified!'; // Use a custom message for Auth0 email verification callback.
      }
      if (!title) {
        title = 'Information';
      }
    }
  }
  return title;
}

function getMessageFromParameters(snapshot: ActivatedRouteSnapshot): string {
  let message = snapshot.queryParams[STATUS_PAGE_MESSAGE_QUERY_PARAMETER];
  if (!message) {
    if (isErrorState(snapshot)) {
      message = 'Unexpected internal error. Please try again or contact our support.';
    } else {
      message = 'Action details are missed. Please try again or contact our support.';
    }
  }
  if (message.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_SUCCESS)) {
    // Override Auth0 status with our own message.
    message = 'Your email address was successfully verified';
  }
  return message;
}

function getButtonLabelFromParameters({ queryParams }: ActivatedRouteSnapshot): string {
  let label = queryParams[STATUS_PAGE_BUTTON_LABEL_QUERY_PARAMETER];
  const message = queryParams[STATUS_PAGE_MESSAGE_QUERY_PARAMETER];
  if (
    message?.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_SUCCESS) ||
    message?.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_ALREADY_VERIFIED)
  ) {
    label = 'Sign In';
  }
  if (!label) {
    label = 'Back to Home';
  }
  return label;
}

function getIconTypeFromParameters({ queryParams }: ActivatedRouteSnapshot): StatusPageIconType {
  const icon = queryParams[STATUS_PAGE_ICON_QUERY_PARAMETER];
  if (STATUS_PAGE_ICON_TYPES.includes(icon)) {
    return icon;
  }
  const message = queryParams[STATUS_PAGE_MESSAGE_QUERY_PARAMETER];
  if (message?.startsWith(AUTH0_EMAIL_VERIFICATION_PREFIX_SUCCESS)) {
    return 'status';
  }
  return 'error';
}

function isErrorState(snapshot: ActivatedRouteSnapshot): boolean {
  return (
    snapshot.queryParams[STATUS_PAGE_SUCCESS_STATE_QUERY_PARAMETER] === 'false' ||
    snapshot.url[0]?.path === ERROR_PAGE_PATH
  );
}
