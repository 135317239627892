import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ApiInjectionSchema } from '@squidcloud/client';
import { GlobalUiService } from '../../../../global/services/global-ui.service';
import { InjectionTableComponent } from '../../../components/injection-table/injection-table.component';

@Component({
  selector: 'injection-table-form',
  templateUrl: './injection-table-form.component.html',
  styleUrls: ['../../integration-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTableFormComponent implements OnInit {
  @Input() control!: AbstractControl<ApiInjectionSchema>;
  @Input() name!: string;
  @Input() description?: string;

  expanded = false;
  hidden = true;

  @ViewChild('injectionTable') injectionTable!: InjectionTableComponent;

  constructor(
    private readonly globalUiService: GlobalUiService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.empty) {
      this.expanded = true;
      this.hidden = false;
    }
  }

  get empty(): boolean {
    return !Object.keys(this.control.value).length;
  }

  handleSchemaChange(schema: ApiInjectionSchema): void {
    this.control.setValue(schema);
    this.control.markAsDirty();
    this.control.markAsTouched();
  }

  async toggle(event: MatSlideToggleChange): Promise<void> {
    if (event.checked && this.empty) {
      const result = await this.injectionTable.showFieldDialog();
      if (!result) {
        this.expanded = false;
      }
    } else if (!event.checked && !this.empty) {
      const result = await this.globalUiService.showDeleteDialog(
        'Disabling injected request headers will delete any injections you have added. This cannot be undone, and you will have to manually re-enter these if needed.',
        () => {
          this.handleSchemaChange({});
        },
      );
      if (!result) {
        this.expanded = true;
      }
    } else {
    }

    this.hidden = !this.expanded;
    this.cdr.markForCheck();
  }
}
