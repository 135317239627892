<div class="widget_contact light">
  <form (ngSubmit)="captchaRef.execute();" class="form" [formGroup]="form">
    <div class="form_fields">
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: form.controls['name'].valid && form.controls['name'].touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Name</mat-label>
        <input autofocus autocomplete="off" formControlName="name" matInput
               name="name">
      </mat-form-field>
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: form.controls['email'].valid && form.controls['email'].touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Email</mat-label>
        <input autofocus autocomplete="off" formControlName="email" matInput
               name="email">
      </mat-form-field>

      @let messageControl = form.controls['message'];
      <mat-form-field [hideRequiredMarker]="true"
                      [ngClass]="{valid: messageControl.valid && messageControl.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field textarea_form_field message_form_field">
        <mat-label>Message</mat-label>
        <textarea autocomplete="off" class="feedback_textarea" formControlName="message" matInput
                  name="message"></textarea>
      </mat-form-field>
      <re-captcha
        #captchaRef="reCaptcha"
        id="recaptcha"
        size="invisible"
        (resolved)="captchaResolved($event)"
        (errored)="captchaError($event)"
      >
      </re-captcha>
      <div class="form_buttons">
        <button class="primary_button large_button"
                mat-flat-button>
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
