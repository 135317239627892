import { ChangeDetectionStrategy, Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import {
  DocumentData,
  IntegrationId,
  IntegrationType,
  SimpleCondition,
  Squid,
  SquidDocument,
} from '@squidcloud/client';
import { copy } from '@squidcloud/console-web/app/utils/copy-utils';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { DataSchemaService } from '@squidcloud/console-web/app/integrations/schema/data-schema/data-schema.service';
import { ActivatedRoute } from '@angular/router';
import { getRequiredPageParameter, INTEGRATION_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { BehaviorSubject, firstValueFrom, from, Observable, switchMap, take, tap } from 'rxjs';
import { IntegrationService } from '@squidcloud/console-web/app/integrations/integration.service';
import { Integrations } from '@squidcloud/console-web/app/integrations/utils/content';
import { map } from 'rxjs/operators';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { DatabaseIntegrationConfig } from '@squidcloud/internal-common/types/integrations/schemas';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { DialogWithForm } from '@squidcloud/console-web/app/global/components/dialog-with-form/dialog-with-form.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  DbBrowserFilter,
  DbBrowserFilterDialogComponent,
  DbBrowserFilterType,
} from '@squidcloud/console-web/app/db-browser/db-browser-filter-dialog/db-browser-filter-dialog.component';
import { JsonEditorOptions } from '@squidcloud/console-web/app/db-browser/json-editor.component';
import { assertString } from 'assertic';

interface SortOrder {
  field: string;
  sortOrder: boolean;
}

@Component({
  selector: 'app-db-browser',
  templateUrl: './db-browser.component.html',
  styleUrl: './db-browser.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbBrowserComponent {
  @Output()
  headerTemplateChange = new EventEmitter<TemplateRef<unknown>>();

  readonly IntegrationType = IntegrationType;
  readonly integrationObs: Observable<DatabaseIntegrationConfig>;
  readonly collectionListObs: Observable<Array<string>>;
  readonly documentsObs: Observable<Array<DocumentData>>;
  Integrations = Integrations;
  collectionsExpanded = true;
  documentsExpanded = true;
  jsonViewEnabled = false;
  private readonly integrationId: IntegrationId;
  private readonly squid: Promise<Squid>;
  selectedCollectionNameSubject = new BehaviorSubject<string | undefined>(undefined);
  selectedDocumentSubject = new BehaviorSubject<DocumentData | undefined>(undefined);
  sortOrdersSubject = new BehaviorSubject<Array<SortOrder>>([]);
  filtersSubject = new BehaviorSubject<Array<DbBrowserFilter>>([]);

  editorOptions = {
    enableSort: false,
    mode: 'view',
    history: false,
    expandAll: true,
    enableTransform: false,
    mainMenuBar: false,
    navigationBar: false,
    search: false,
    statusBar: false,
    sortObjectKeys: false,
  } as JsonEditorOptions;

  constructor(
    private readonly snackBarService: SnackBarService,
    private readonly schemaService: DataSchemaService,
    private readonly integrationService: IntegrationService,
    private readonly applicationService: ApplicationService,
    private readonly globalUiService: GlobalUiService,
    private readonly dialog: MatDialog,
    activatedRoute: ActivatedRoute,
  ) {
    this.squid = this.applicationService.getApplicationSquid();
    this.integrationId = getRequiredPageParameter(INTEGRATION_ID_PARAMETER, activatedRoute.snapshot);
    this.integrationObs = this.integrationService.observeIntegration(this.integrationId);
    this.collectionListObs = this.integrationObs.pipe(
      switchMap(integration => {
        return from(this.schemaService.initializeSchema(integration)).pipe(
          switchMap(() => {
            if (integration.type === IntegrationType.built_in_db) {
              return from(this.schemaService.discoverSchema()).pipe(
                map(schema => {
                  if (!schema) return [];
                  return Object.keys(schema.collections).sort();
                }),
              );
            } else {
              return this.schemaService.observeSchema().pipe(
                map(schema => {
                  if (!schema) return [];
                  return Object.keys(schema.collections).sort();
                }),
              );
            }
          }),
        );
      }),
      tap(collections => {
        const selectedCollectionName = this.selectedCollectionNameSubject.value;
        if (selectedCollectionName === undefined || !collections.includes(selectedCollectionName)) {
          this.selectCollection(collections[0]);
        }
      }),
    );

    this.documentsObs = this.selectedCollectionNameSubject.pipe(
      switchMap(collectionName => {
        if (!collectionName) return [];
        return this.filtersSubject.pipe(
          switchMap(filters => {
            return this.sortOrdersSubject.pipe(
              switchMap(sortOrders => {
                return from(this.squid).pipe(
                  switchMap(squid => {
                    const query = squid.collection(collectionName, this.integrationId).query();
                    if (sortOrders.length) {
                      for (const sort of sortOrders) {
                        query.sortBy(sort.field, sort.sortOrder);
                      }
                    }
                    if (filters.length) {
                      for (const filter of filters) {
                        const condition: SimpleCondition = {
                          fieldName: filter.name,
                          operator: filter.operator,
                          value: this.convertToValue(filter.type, filter.value),
                        };
                        query.where(condition.fieldName, condition.operator, condition.value);
                      }
                    }
                    return query.limit(100).dereference().snapshots();
                  }),
                  map(documents => {
                    // Doing this because jsoneditor does not like Date objects
                    return JSON.parse(JSON.stringify(documents)) as DocumentData[];
                  }),
                  tap(documents => {
                    const selectedDocument = this.selectedDocumentSubject.value;
                    if (!selectedDocument) {
                      this.selectDocument(documents[0]);
                    } else {
                      const selectedDocumentId = this.getSquidDocumentId(selectedDocument);
                      const selectedDoc = documents.find(doc => this.getSquidDocumentId(doc) === selectedDocumentId);
                      this.selectDocument(selectedDoc || documents[0]);
                    }
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  }

  selectCollection(collectionName: string): void {
    this.clearFilters();
    this.clearSortOrders();
    this.selectedDocumentSubject.next(undefined);
    this.selectedCollectionNameSubject.next(collectionName);
  }

  getSquidDocumentId(document: DocumentData): string {
    const docId = (document as SquidDocument).__docId__;
    assertString(docId, 'Not a SquidDocument');
    return docId;
  }

  selectDocument(document: DocumentData): void {
    this.selectedDocumentSubject.next(document);
  }

  copyJsonDate(document: DocumentData): void {
    void copy(this.getJsonData(document), 'JSON data', this.snackBarService);
  }

  async showFilterDialog(data?: DbBrowserFilter, index?: number): Promise<void> {
    const config: MatDialogConfig = {
      maxWidth: '496px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: ['modal'],
      data,
    };
    const condition: DbBrowserFilter | boolean = await firstValueFrom(
      this.dialog.open(DbBrowserFilterDialogComponent, config).afterClosed().pipe(take(1)),
    );

    // This means the user pressed 'Cancel'
    if (!condition || condition === true) {
      return;
    }

    if (index !== undefined) {
      const currentFilters = this.filtersSubject.value;
      currentFilters[index] = condition;
      this.filtersSubject.next(currentFilters);
    } else {
      this.addFilter(condition);
    }
  }

  deleteFilter(index: number): void {
    const currentFilters = this.filtersSubject.value;
    if (!currentFilters.length) return;
    this.filtersSubject.next(currentFilters.filter((_, i) => i !== index));
  }

  deleteSortOrder(index: number): void {
    const currentSortOrders = this.sortOrdersSubject.value;
    if (!currentSortOrders.length) return;
    this.sortOrdersSubject.next(currentSortOrders.filter((_, i) => i !== index));
  }

  showSortDialog(data?: SortOrder, index?: number): void {
    const defaultSortOrder = data?.sortOrder !== undefined ? data?.sortOrder : true;
    const dialogWithFormData: DialogWithForm<SortOrder> = {
      title: 'Sort documents',
      autoFocus: true,
      textLines: [],
      submitButtonText: 'Apply',
      formElements: [
        {
          type: 'input',
          required: true,
          nameInForm: 'field',
          label: 'Filter by field',
          defaultValue: data?.field,
        },
        {
          type: 'select',
          options: [
            { value: true, name: 'Ascending' },
            { value: false, name: 'Descending' },
          ],
          required: true,
          defaultValue: defaultSortOrder,
          nameInForm: 'sortOrder',
          label: 'Sort order',
        },
      ],
      onSubmit: async (sortOrder: SortOrder) => {
        if (index !== undefined) {
          const currentSortOrders = this.sortOrdersSubject.value;
          currentSortOrders[index] = sortOrder;
          this.sortOrdersSubject.next(currentSortOrders);
        } else {
          this.addSortField(sortOrder);
        }
      },
    };

    void this.globalUiService.showDialogWithForm(dialogWithFormData);
  }

  getJsonMarkdown(document: DocumentData): string | undefined {
    if (!document) return undefined;
    return '```json\n' + this.getJsonData(document) + '\n```';
  }

  private addSortField(sortOrder: SortOrder): void {
    const currentSortOrders = this.sortOrdersSubject.value || [];
    this.sortOrdersSubject.next([...currentSortOrders, sortOrder]);
  }

  private getJsonData(document: DocumentData): string {
    return JSON.stringify(document, null, 2);
  }

  private addFilter(condition: DbBrowserFilter): void {
    const currentFilters = this.filtersSubject.value || [];
    this.filtersSubject.next([...currentFilters, condition]);
  }

  private clearFilters(): void {
    this.filtersSubject.next([]);
  }

  private clearSortOrders(): void {
    this.sortOrdersSubject.next([]);
  }

  private convertToValue(type: DbBrowserFilterType, value: string): unknown {
    if (type === 'number') {
      return parseFloat(value);
    }
    if (type === 'boolean') {
      return value === 'true';
    }
    if (type === 'date') {
      return new Date(value);
    }
    if (type === 'null') {
      return null;
    }
    return value;
  }

  handleFilterClicked(filter: DbBrowserFilter, index: number, clickedOn: 'bubble' | 'close'): void {
    if (clickedOn === 'close') {
      this.deleteFilter(index);
      return;
    }

    void this.showFilterDialog(filter, index);
  }

  handleSortOrderClicked(sortOrder: SortOrder, index: number, clickedOn: 'bubble' | 'close'): void {
    if (clickedOn === 'close') {
      this.deleteSortOrder(index);
      return;
    }

    void this.showSortDialog(sortOrder, index);
  }
}
