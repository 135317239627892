<div *ngIf="accountService.user$ | async as user" class="root">
  <div class="avatar_and_name_container">
    <avatar [imageUrl]="user.picture" [name]="user.name" class="avatar"></avatar>
    <div class="name_and_email_container">
      <div class="name ellipsis" [title]="user.name">{{ user.name }}</div>
      <div class="email ellipsis" [title]="user.email">{{ user.email }}</div>
    </div>
  </div>
  <div class="menu_divider"></div>
  <button class="profile_settings_button" mat-menu-item routerLink="/profile">
    <mat-icon class="icon" svgIcon="profile_icon"></mat-icon>
    Profile Settings
  </button>
  <button class="theme_button" mat-menu-item *ngIf="themeService.theme$ | async as theme"
          (click)="switchTheme(theme === 'dark' ? 'light' : 'dark')">
    <mat-icon class="icon" [svgIcon]="theme === 'dark' ? 'light_theme_icon' : 'dark_theme_icon'"></mat-icon>
    {{ theme === 'dark' ? 'Light' : 'Dark' }} Mode
  </button>
  <div class="menu_divider"></div>
  <button (click)="signOut()" class="log_out_button" mat-menu-item>
    <mat-icon class="icon" svgIcon="logout_icon"></mat-icon>
    Log out
  </button>
</div>
