import { BehaviorSubject, combineLatest, filter, lastValueFrom, take } from 'rxjs';
import { assertTruthy, truthy } from 'assertic';

type LockMutex = string;
type IsLockedSubject = BehaviorSubject<boolean>;

/**
 * A simple lock manager that locks a list of mutexes.
 * When locking a list of mutexes, the lock will start only when all the mutexes are available - preventing partial lock
 * and potential deadlocks.
 */
export class LockManager {
  private readonly locks: Record<LockMutex, IsLockedSubject> = {};

  async lock(...mutexes: LockMutex[]): Promise<void> {
    if (this.canGetLock(...mutexes)) {
      this.lockSync(...mutexes);
      return;
    }

    const relevantLocks = Object.entries(this.locks)
      .filter(([mutex]) => mutexes.includes(mutex))
      .map(([, isLockedSubject]) => isLockedSubject);

    await lastValueFrom(
      combineLatest(relevantLocks).pipe(
        filter((isLockedArray: boolean[]) => !isLockedArray.includes(true)),
        take(1),
      ),
    );
    await this.lock(...mutexes);
  }

  release(...mutexes: LockMutex[]): void {
    for (const mutex of mutexes) {
      const isLockedSubject = truthy(this.locks[mutex]);
      isLockedSubject.next(false);
      isLockedSubject.complete();
      delete this.locks[mutex];
    }
  }

  canGetLock(...mutexes: LockMutex[]): boolean {
    return !mutexes.some(mutex => this.locks[mutex]?.value);
  }

  lockSync(...mutexes: LockMutex[]): void {
    assertTruthy(this.canGetLock(...mutexes), 'Cannot acquire lock sync');
    for (const mutex of mutexes) {
      this.locks[mutex] = new BehaviorSubject<boolean>(true);
    }
  }
}
