import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { countQuotedApps, countQuotedIntegrations, getQuotaValue } from '@squidcloud/console-common/utils/quota-utils';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuotaService {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly applicationService: ApplicationService,
  ) {}

  /**
   * Checks if a new application can be created for the organization from the billing plan quotas POV.
   * Shows an error dialog and returns 'false' if the quota is already reached.
   * Returns true if it is safe to proceed with the application creation.
   */
  async checkCanCreateApplication(): Promise<boolean> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const maxNumberOfApplications = getQuotaValue(organization.billingPlan, 'maxNumberOfApplications');
    if (maxNumberOfApplications === undefined) {
      return true;
    }
    const prodAndDevApps = await firstValueFrom(this.applicationService.observeApplicationsForCurrentOrganization());
    if (prodAndDevApps === undefined) {
      return false; // Unexpected database state: organization is not found.
    }
    const currentAppCount = countQuotedApps(prodAndDevApps);
    return currentAppCount < maxNumberOfApplications;
  }

  /**
   * Checks if a new integration can be created for the organization from the billing plan quotas POV.
   * Shows an error dialog and returns 'false' if the quota is already reached.
   * Returns true if it is safe to proceed with the integration creation.
   */
  async checkCanAddIntegration(): Promise<boolean> {
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const maxNumberOfIntegrations = getQuotaValue(organization.billingPlan, 'maxNumberOfIntegrations');
    if (maxNumberOfIntegrations === undefined) {
      return true;
    }
    const application = this.applicationService.getCurrentApplicationOrFail();
    const currentIntegrationCount = countQuotedIntegrations(application);
    return currentIntegrationCount < maxNumberOfIntegrations;
  }
}
