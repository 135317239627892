import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorylaneDialogComponent } from '@squidcloud/console-web/app/global/components/storylane-dialog/storylane-dialog.component';

export enum StorylaneDemo {
  AI_SPLASH = '97sqhod5jxi1',
  AI_LEARN = 'qs317fuzz3hg',
  AI_CONGRATS = 'wt11nldabjyy',
  AI_HOW_TO = 'jc5dvnsf7wac',
}

@Injectable({
  providedIn: 'root',
})
export class StorylaneDialogService {
  closeOnNextCTA = false;
  playTimeout: ReturnType<typeof setTimeout> | null = null;
  private playResolve: ((value: boolean | PromiseLike<boolean>) => void) | null = null;
  private dialogRef: MatDialogRef<StorylaneDialogComponent> | null = null;

  constructor(public dialog: MatDialog) {
    window.addEventListener('message', message => {
      if (message.data?.message !== 'storylane-demo-event') return;
      const { event } = message.data.payload;

      switch (event) {
        case 'flow_end':
          this.closeOnNextCTA = true;
          break;
        case 'primary_cta':
        case 'secondary_cta':
          if (!this.closeOnNextCTA) break;
          this.dialogRef?.close();
          this.closeOnNextCTA = false;
          break;
        default:
          break;
      }
    });
  }

  play(demoId: string, delay = 0): Promise<boolean> {
    this.clear();

    return new Promise(resolve => {
      this.playResolve = resolve;
      this.playTimeout = setTimeout(async () => {
        this.dialogRef = this.dialog.open(StorylaneDialogComponent, {
          width: '80%',
          maxWidth: 1200,
          autoFocus: false,
          disableClose: true,
          panelClass: 'dialog-panel-overflow',
          backdropClass: 'dialog-backdrop-dark',
          data: { demoId },
        });
        this.playTimeout = null;
        resolve(true);
      }, delay);
    });
  }

  clear(): void {
    if (this.playTimeout) {
      window.clearTimeout(this.playTimeout);
      this.playTimeout = null;
    }
    if (this.playResolve) {
      this.playResolve(false);
      this.playResolve = null;
    }
  }

  close(): void {
    this.dialogRef?.close();
  }
}
