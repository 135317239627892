<div (click)="activate.emit()"
     class="schema_select_row"
     [ngClass]="{selected, no_hover: !activate.observed}"
     [attr.data-testid]="'schema-select-row-' + title"
>
  <div class="name_and_icon" [ngClass]="{'folder': inFolder}">
    <mat-icon class="icon" [svgIcon]="icon" />
    <span class="name truncate" [ngClass]="{mono}">
      {{ title }}
      <span *ngIf="isModified" class="modified_dot"></span>
    </span>
    <ng-content />
  </div>
</div>


