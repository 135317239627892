export const CONTACT_LIST_COLLECTION = 'contactList';

export type CpContact = {
  email: string;
  name?: string;
  phone?: string;
  message?: string;
  referralSource?: string;
  captcha: string;
  location?: ContactLocation;
  reason?: ContactReason;
  submissionDate: Date;
};

export type ContactReason = 'demo' | 'request_access' | 'contact_developers' | 'billing_or_quota';

export type ContactLocation = 'ai-chat' | 'welcome' | 'contact-page' | 'contact-us-dialog' | 'registration-page';

export const ZOOM_CLIENT_ID = '3pLZuwemTM6zfrvJBsMA';

// TODO: remove Yoon's email (Zoom reviewer)
export const ZOOM_EMAILS = ['jen@squid.cloud', 'jen@getsquid.ai', 'etai@squid.cloud', 'yoon.user1@zoomappsec.us'];
