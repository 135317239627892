import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { firstValueFrom, take } from 'rxjs';
import {
  DocumentationWithComponentDialogComponent,
  DocumentationWithComponentDialogData,
} from '@squidcloud/console-web/app/application/documentation-with-component-dialog/documentation-with-component-dialog.component';
import { DialogWithForm, DialogWithFormComponent } from '../components/dialog-with-form/dialog-with-form.component';
import { requiredTextValidator } from '../utils/validators';
import {
  DocumentationDialogComponent,
  DocumentationDialogData,
} from '@squidcloud/console-web/app/application/documentation-dialog/documentation-dialog.component';
import { AngularComponent } from '@squidcloud/console-web/app/utils/angular-utils';
import { FormCallbackResponse } from '@squidcloud/console-web/app/utils/form';

@Injectable({
  providedIn: 'root',
})
export class GlobalUiService {
  constructor(private readonly dialog: MatDialog) {}

  async showMessageDialog(
    title: string,
    textOrTextLines: string | string[],
    submitButtonText: string = 'Close',
  ): Promise<void> {
    await this.showDialogWithForm({
      title,
      autoFocus: false,
      onSubmit: () => {},
      textLines: Array.isArray(textOrTextLines) ? textOrTextLines : [textOrTextLines],
      submitButtonText,
      hideCancelButton: true,
      formElements: [],
      buttonRowClass: 'button_row_with_no_top_margin single_button_mode',
    });
  }

  showConfirmationDialog(
    title: string,
    textOrTextLines: string | string[],
    submitButtonText: string,
    onSubmitFn: (formDetails: Record<string, unknown>) => FormCallbackResponse,
  ): void {
    void this.showDialogWithForm({
      title,
      autoFocus: false,
      onSubmit: onSubmitFn,
      textLines: Array.isArray(textOrTextLines) ? textOrTextLines : [textOrTextLines],
      submitButtonText,
      formElements: [],
      buttonRowClass: 'button_row_with_no_top_margin',
    });
  }

  async showConfirmationDialogAsync(
    title: string,
    textOrTextLines: string | string[],
    submitButtonText = 'Confirm',
  ): Promise<boolean> {
    let isActionConfirmed = false;
    await this.showDialogWithForm({
      title,
      autoFocus: false,
      onSubmit: async () => {
        // When 'onSubmit' is called the action is confirmed.
        isActionConfirmed = true;
      },
      textLines: Array.isArray(textOrTextLines) ? textOrTextLines : [textOrTextLines],
      submitButtonText,
      formElements: [],
      buttonRowClass: 'button_row_with_no_top_margin',
    });
    return isActionConfirmed;
  }

  showDialogWithForm<FormDetailsType = Record<string, unknown>>(
    data: DialogWithForm<FormDetailsType>,
    wide = false,
  ): Promise<FormDetailsType | undefined> {
    const config: MatDialogConfig = {
      maxWidth: wide ? `800px` : '496px',
      width: '100%',
      autoFocus: !!data.autoFocus,
      restoreFocus: false,
      panelClass: ['modal', wide ? 'wide' : ''],
      data,
    };
    return firstValueFrom(this.dialog.open(DialogWithFormComponent, config).afterClosed().pipe(take(1)));
  }

  showDocWithComponentDialog(input: DocWithComponentDialogInput): void {
    const data: DocumentationWithComponentDialogData = { docComponent: input.component, title: input.title };
    const config: MatDialogConfig = {
      maxWidth: '792px',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: input.withNoBorder ? `documentation_modal_borderless` : `documentation_modal`,
      data,
    };
    this.dialog.open(DocumentationWithComponentDialogComponent, config);
  }

  showDocDialog(data: DocumentationDialogData, maxWidth: string | number = 792, withBorder = true): void {
    const config: MatDialogConfig = {
      maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth,
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: withBorder ? `documentation_modal` : `documentation_modal_borderless`,
      data,
    };
    this.dialog.open(DocumentationDialogComponent, config);
  }

  async showDeleteDialog(
    description: string,
    onSubmitFn: (
      formDetails: Record<string, unknown>,
    ) => Promise<string | undefined | void> | string | undefined | void,
    title = 'Arrrrr you sure?',
    requiredText = 'downwiththeship',
    submitButtonText = 'Delete',
  ): Promise<{ name: string } | undefined> {
    return this.showDialogWithForm<{ name: string }>({
      title,
      autoFocus: true,
      onSubmit: onSubmitFn,
      minRole: 'ADMIN',
      textLines: [description, `Please type <span class="bold">${requiredText}</span> to confirm`],
      submitButtonText,
      formElements: [
        {
          type: 'input',
          required: true,
          nameInForm: 'name',
          label: 'Type here',
          extraValidators: [requiredTextValidator(requiredText)],
        },
      ],
    });
  }
}

export interface DocWithComponentDialogInput {
  component: AngularComponent;
  title?: string;
  withNoBorder?: boolean;
}
