<div mat-dialog-title>Select AI Function</div>
<mat-dialog-content>
  <dialog-close-button></dialog-close-button>

  <mat-form-field class="select_field" appearance="outline">
    <mat-label>Available AI Functions</mat-label>
    <mat-select (selectionChange)="onSelectedFunctionChanged($event.value)">
      @for (f of data.functions; track f.serviceFunction) {
        <mat-option [value]="f.serviceFunction">{{ getFunctionName(f.serviceFunction) }}</mat-option>
      }
    </mat-select>
  </mat-form-field>

  @if (selectedFunction) {
    <div class="description">
      {{ selectedFunction.description }}
    </div>
  }


  <div class="dialog_buttons">
    <button mat-stroked-button [mat-dialog-close]="true" (click)="dialogRef.close()"
            class="close_button outline_button xl_button">
      Cancel
    </button>
    <button mat-flat-button (click)="onAddButtonClicked()"
            [disabled]="!selectedFunction"
            class="primary_button xl_button">
      Select
    </button>
  </div>
</mat-dialog-content>
