declare let window: any;
declare let self: any;
declare let global: any;

/** @internal */
export function getGlobal(): any {
  if (typeof window !== 'undefined') {
    return window; // Browser environment
  }
  if (typeof global !== 'undefined') {
    return global; // Node.js environment
  }
  if (typeof self !== 'undefined') {
    return self; // Web Worker environment
  }
  throw new Error('Unable to locate global object');
}

/** @internal */
export function isDebugEnabled(): boolean {
  const globalObj = getGlobal();
  return globalObj?.['SQUID_LOG_DEBUG_ENABLED'] === true;
}

/** @internal */
export function enableDebugLogs(mode = true): void {
  const globalObj = getGlobal();
  globalObj['SQUID_LOG_DEBUG_ENABLED'] = mode;
}

function isSquidDebugEnabledByDefault(): boolean {
  let param = '';
  if (typeof window !== 'undefined' && window.location) {
    const searchParams = new URLSearchParams(window.location.search);
    param = searchParams.get('SQUID_DEBUG') || '';
  } else if (typeof process !== 'undefined' && process.env) {
    param = process.env['SQUID_DEBUG'] || '';
  }
  return param === '1' || param === 'true';
}

enableDebugLogs(isSquidDebugEnabledByDefault());

/** @internal */
export function disableTimestampsInLog(): void {
  const globalObj = getGlobal();
  globalObj['SQUID_LOG_TIMESTAMPS_DISABLED'] = true;
}

export function isTimestampEnabled(): boolean {
  const globalObj = getGlobal();
  return globalObj?.['SQUID_LOG_TIMESTAMPS_DISABLED'] === true;
}

/** @internal */
export function disableDebugLogs(): void {
  const globalObj = getGlobal();
  globalObj['SQUID_LOG_DEBUG_ENABLED'] = false;
}

/** @internal */
export class DebugLogger {
  static log(...args: any[]): void {
    console.log(`${getLogPrefixString()} LOG`, ...args);
  }

  static info(...args: any[]): void {
    console.info(`${getLogPrefixString()} INFO`, ...args);
  }

  static warn(...args: any[]): void {
    console.warn(`${getLogPrefixString()} WARN`, ...args);
  }

  static error(...args: any[]): void {
    console.error(`${getLogPrefixString()} ERROR`, ...args);
  }

  static debug(...args: any[]): void {
    if (!isDebugEnabled()) return;
    console.debug(`${getLogPrefixString()} DEBUG`, ...args);
  }
}

function getLogPrefixString(): string {
  if (isTimestampEnabled()) {
    const date = new Date();
    return `[${date.toLocaleTimeString()}.${date.getMilliseconds()}] squid`;
  } else {
    return 'squid';
  }
}
