<div *ngIf="organizationObs | async as organization" class="organization_details">
  <div class="description_and_input_container">
    <div class="left">
      <div class="title">Organization Name</div>
      <div class="description">This can be your company name or a personal name and can be updated.</div>
    </div>
    <div class="right">
      <div class="fake_input_wrapper">
        <div class="fake_input">
          <div class="text">{{ organization.name }}</div>
          <mat-icon (click)="showUpdateOrganizationNameDialog(organization);"
                    *ngIf="('ADMIN' | minRole)"
                    class="icon_action"
                    svgIcon="edit_icon"
                    title="Edit"></mat-icon>
          <mat-icon *ngIf="!('ADMIN' | minRole)"
                    class="icon_action_disabled"
                    svgIcon="edit_icon"
                    title="Only admins can edit organization names"></mat-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="description_and_input_container">
    <div class="left">
      <div class="title">Organization ID</div>
      <div class="description">Your organization ID</div>
    </div>
    <div class="right">
      <div class="fake_input_wrapper">
        <div class="fake_input">
          <div class="text">{{ organization.id }}</div>
          <mat-icon (click)="copyId(organization.id);" class="icon_action" svgIcon="copy_icon"
                    title="Copy ID"></mat-icon>
        </div>
      </div>

    </div>
  </div>

  <div class="danger_zone" *ngIf="('ADMIN' | minRole)">
    <div class="danger_zone_title">Danger Zone</div>
    <action-card
      title="Delete Organization"
      description="Once this organization is deleted there is no going back. A sunken ship cannot be resurfaced."
      (action)="showDeleteOrganizationDialog(organization)"
      cta="Delete This Organization"
      [warning]="true">
    </action-card>
  </div>
</div>
