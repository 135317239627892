@if (integrationObs | async; as integration) {
  <div class="graphql-schema">
    @let schema = schemaObs | async ;
    <portal class="flex_shrink_0" [emitter]="headerTemplateChange">
      <integration-header [integrationType]="integration.type"
                          [title]="integration.id">
        <ng-container buttons>
          <button (click)="showRediscoverSchemaDialog()"
                  [disabled]="!schema"
                  class="discover_schema secondary_button large_button"
                  mat-flat-button
                  type="submit">Rediscover Schema
          </button>
        </ng-container>
      </integration-header>
    </portal>

    @if (schema && graphqlUrl) {
      @let headers = headersPromise | async ;
      @let activated = activatedSubject | async ;
      @if (headers && activated) {
        <react-graphiql class="react-graphiql"
                        [url]="graphqlUrl"
                        [headers]="headers"
                        [integrationId]="integration.id"
                        [schema]="schema.introspection">
        </react-graphiql>
      }
    }
  </div>
}
