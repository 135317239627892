import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';
import { MatCellDef, MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

/**
 * [matCellDef] from Angular uses `any` as a table element type by default.
 * This extension adds type safety to the element.
 * Usage: <td *matCellDef="let element; typedBy: elementsDataSource">
 */
@Directive({
  selector: '[matCellDef]',
  providers: [{ provide: CdkCellDef, useExisting: MatCellDefTypedByDirective }],
})
export class MatCellDefTypedByDirective<T> extends MatCellDef {
  @Input() matCellDefTypedBy!: Array<T> | Observable<Array<T>> | MatTableDataSource<T>;

  static ngTemplateContextGuard<T>(
    _: MatCellDefTypedByDirective<T>,
    ctx: unknown,
  ): ctx is {
    $implicit: T;
    index: number;
  } {
    return true;
  }
}
