<div class="carousel" [ngClass]="{'overflow': hasOverflow}">
  <div class="carousel_content" #carousel>
    <ng-content />
  </div>
</div>
<div class="prev" [ngClass]="{'overflow': hasOverflow}">
  <button (click)="prev()"><mat-icon svgIcon="caret_down" /></button>
</div>
<div class="next" [ngClass]="{'overflow': hasOverflow}">
  <button (click)="next()"><mat-icon svgIcon="caret_down" /></button>
</div>
