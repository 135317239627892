import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

/** Usage page for the current application. */
@Component({
  templateUrl: './application-usage.component.html',
  styleUrl: './application-usage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationUsageComponent {
  constructor(protected readonly applicationService: ApplicationService) {}
}
