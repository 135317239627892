<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="appObs | async as app">
    <doc *ngIf="apiKeySubject | async as apiKey"
         [markdownFileUrl]="'assets/docs/application-overview/create-env-file.md'"
         [replaceableParams]="{
         appId: getProdAppId(app.appId),
         region: getRegion(app),
         apiKey,
         environmentId: 'dev',
         squidDeveloperId: user.squidDeveloperId
       }">
    </doc>
  </ng-container>
</ng-container>
