import { ChangeDetectionStrategy, Component } from '@angular/core';
import { filter, Observable, switchMap } from 'rxjs';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { getApplicationUrl } from '@squidcloud/internal-common/utils/http';
import { Squid } from '@squidcloud/client';
import { map } from 'rxjs/operators';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { convertToSquidRegion, DEFAULT_EGRESS_IPS } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';

@Component({
  selector: 'app-ip-addresses-doc',
  templateUrl: './ip-addresses-doc.component.html',
  styleUrl: './ip-addresses-doc.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpAddressesDocComponent {
  readonly appObs = this.applicationService.observeCurrentApplication().pipe(filter(Boolean));
  readonly egressIpsObs: Observable<string> = this.appObs.pipe(
    switchMap(app => callBackendExecutable(this.squid, 'getRegionAndCloudMapping', app.appId)),
    map(regionAndCloudMapping => {
      const egressIpArray = regionAndCloudMapping?.egressIps || DEFAULT_EGRESS_IPS;
      return egressIpArray.join(', ');
    }),
  );

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly squid: Squid,
  ) {}

  getIngressHostname(app: CpApplication): string {
    const region = convertToSquidRegion(app.cloudId, app.region, app.shard, environment.stage);
    const applicationUrl = getApplicationUrl(region, app.appId, '/');
    return new URL(applicationUrl).hostname;
  }
}
