import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationGuard implements CanActivate {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const { integrationId } = route.params;
    const application = await firstValueFrom(this.applicationService.observeCurrentApplication());
    const integration = Object.keys(application?.integrations || {}).find(id => id === integrationId);
    // Redirect to the application route if the integration does not exist for the current application..
    return integration ? true : this.router.createUrlTree(['/application', application?.appId]);
  }
}
