import { type IntrospectionQuery } from 'graphql';

/** @internal */
export interface GraphQLRequest {
  operationName?: string;
  query?: string;
  variables?: string | Record<string, any>;
}

/** @internal */
export interface SecureGraphQLRequest extends GraphQLRequest {
  isGraphiQL: boolean;
}

/** @internal */
export const EmptyIntrospection: IntrospectionQuery = {
  __schema: {
    queryType: { name: 'Query', kind: 'OBJECT' },
    mutationType: null,
    subscriptionType: null,
    types: [
      {
        name: 'Query',
        kind: 'OBJECT',
        fields: [
          {
            name: 'isEmpty',
            type: {
              kind: 'SCALAR',
              name: 'Boolean',
            },
            args: [],
            isDeprecated: false,
            deprecationReason: null,
          },
        ],
        interfaces: [],
      },
      {
        name: 'Boolean',
        kind: 'SCALAR',
      },
    ],
    directives: [],
  },
};
