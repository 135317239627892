import { deserializeObj, normalizeJsonAsString } from '../utils/serialization';
import { IntegrationId, SquidDocId } from '../public-types/communication.public-types';
import { CollectionName, DocId, SquidDocIdObj } from '../public-types/document.public-types';

/**
 * The collection name with the db name or schema depends on the database type (e.g, mySchema.myTable)
 * @internal
 */
export type FullCollectionName = string;

/** @internal */
export const SquidPlaceholderId = '__squidId';

/** @internal */
export function parseSquidDocId(squidDocId: SquidDocId): SquidDocIdObj {
  return deserializeObj(squidDocId);
}

/** @internal */
export function getSquidDocId(squidDocIdObj: SquidDocIdObj): SquidDocId;
/** @internal */
export function getSquidDocId(docId: DocId, collectionName: CollectionName, integrationId: IntegrationId): SquidDocId;
/** @internal */
export function getSquidDocId(...args: unknown[]): SquidDocId {
  const [a, b, c] = args;
  const squidDocIdObjObj =
    typeof a === 'object'
      ? (a as SquidDocIdObj)
      : {
          docId: a,
          collectionName: b,
          integrationId: c,
        };
  // Handle nulls and empty strings
  if (!squidDocIdObjObj.integrationId) squidDocIdObjObj.integrationId = undefined;
  return normalizeJsonAsString(squidDocIdObjObj);
}
