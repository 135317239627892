import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { SecretService } from '@squidcloud/console-web/app/application/secrets/secret.service';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';

@Component({
  templateUrl: './create-secrets-script-dialog.component.html',
  styleUrl: './create-secrets-script-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecretsScriptDialogComponent {
  readonly apiScriptSubject = new ReplaySubject<string>(1);

  protected applicationId = '';
  protected apiKey = '';

  constructor(
    private readonly secretService: SecretService,
    private readonly applicationService: ApplicationService,
  ) {
    const currentApplication = this.applicationService.getCurrentApplicationOrFail();
    this.applicationId = currentApplication.appId;
    void this.updateScript(true);
  }

  static show(dialog: MatDialog): MatDialogRef<unknown> {
    return dialog.open(SecretsScriptDialogComponent, {
      maxWidth: 'min(80vw, 1200px)',
      width: '100%',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'documentation_modal',
    });
  }

  async updateScript(isAppComponentInit?: boolean): Promise<void> {
    const undefinedIfEmpty = (val: string | undefined): string | undefined => (val ? val : undefined);
    const options = {
      appId: undefinedIfEmpty(this.applicationId),
      apiKey: undefinedIfEmpty(this.apiKey),
    };
    const { apiKey, script } = await this.secretService.getCreationScript(options);
    if (isAppComponentInit) {
      this.apiKey = apiKey;
    }
    this.apiScriptSubject.next(script);
  }
}
