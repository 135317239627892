<div mat-dialog-title>Filter documents</div>

<mat-dialog-content class="db_browser_filter_dialog">
  <dialog-close-button></dialog-close-button>
  <form (ngSubmit)="submitFilters()" [formGroup]="form" class="form">
    <div class="fields">
      <mat-form-field *ngIf="form.controls['name'] as control"
                      [hideRequiredMarker]="true"
                      [ngClass]="{valid: control.valid && control.touched}"
                      appearance="outline"
                      class="form_field dialog_form_field">
        <mat-label>Filter by field</mat-label>
        <input autocomplete="off" formControlName="name" matInput name="field_name">
      </mat-form-field>

      <div>
        <div class="pretext">Only show documents where the specified field is...</div>
        @let operatorControl = form.controls['operator'] ;
        <mat-form-field [hideRequiredMarker]="true"
                        [ngClass]="{valid: operatorControl.valid && operatorControl.touched}"
                        appearance="outline"
                        class="form_field dialog_form_field">
          <mat-label>Operator</mat-label>
          <mat-select class="select_box gray_select"
                      formControlName="operator"
                      panelClass="select_box_panel">
            @for (operator of ALL_OPERATORS; track $index) {
              <mat-option [value]="operator">{{ operator }}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div class="type_and_value_container">
          @if (form.controls['type']; as control) {
            <mat-form-field [hideRequiredMarker]="true"
                            [ngClass]="{valid: control.valid && control.touched}"
                            appearance="outline"
                            class="form_field dialog_form_field">
              <mat-label>Type</mat-label>
              <mat-select class="select_box gray_select"
                          formControlName="type"
                          panelClass="select_box_panel"
                          (selectionChange)="onTypeChanged($event)">
                @for (type of ALL_TYPES; track $index) {
                  <mat-option [value]="type">{{ type | titlecase }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
          @if (form.controls['value']; as control) {
            <mat-form-field [hideRequiredMarker]="true"
                            [ngClass]="{valid: control.valid && control.touched}"
                            appearance="outline"
                            floatLabel="always"
                            class="form_field dialog_form_field">
              <mat-label>Value</mat-label>

              @let valueType = form.controls['type'].value ;
              @if (valueType === 'boolean') {
                <mat-select class="select_box gray_select"
                            formControlName="value"
                            panelClass="select_box_panel"
                            [value]="control.value">
                  @for (boolValue of ['false', 'true']; track $index) {
                    <mat-option [value]="boolValue">{{ boolValue }}</mat-option>
                  }
                </mat-select>
              } @else {
                <input autocomplete="off" formControlName="value" matInput name="value"
                       [type]="valueType === 'number' ? 'number' : 'text'" />
              }
            </mat-form-field>
          }
        </div>
      </div>
    </div>
    @if (errorMessage) {
      <div class="dialog_error">{{ errorMessage }}</div>
    }
    <div class="dialog_buttons">
      <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
        Cancel
      </button>
      <div class="inline_block">
        <button [disabled]="form.disabled || form.invalid" class="confirm_button primary_button xl_button"
                mat-flat-button
                type="submit">
          Apply
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
