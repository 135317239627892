<div class="onboarding_content">
  <div class="onboarding_done">
    <h1>You're done</h1>
    <div class="text text1">
      In just a few minutes you created your first application with Squid.
    </div>
    <div class="text text2">
      From your console, there is so much more that you can learn and do.
      Explore recommended next steps with our quick start guide and build your
      next app in real-time with Squid.
    </div>
    <a routerLink="/application/quick-start">
      <button
        class="primary_button extra_large_button"
        mat-flat-button
        data-testid="onboarding-explore-button"
      >
        Explore next steps
        <mat-icon
          svgIcon="chevron_right_icon"
          iconPositionEnd
          class="chevron_right"
        ></mat-icon>
      </button>
    </a>
  </div>
</div>
