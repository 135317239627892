import {
  ApiEndpoint,
  ApiInjectionField,
  ApiInjectionParameterLocation,
  ApiParameterLocation,
  ApiResponseParameterLocation,
  FieldName,
  HttpMethod,
} from '@squidcloud/client';
import { truthy } from 'assertic';
import { FormElement } from '@squidcloud/console-web/app/utils/form';

export type EndpointForm = {
  name: string;
  relativePath: string;
  method: HttpMethod;
};

export function getEndpointFormElements(endpointId?: string, endpoint?: ApiEndpoint): Array<FormElement> {
  return [
    {
      type: 'input',
      label: 'Endpoint name',
      nameInForm: 'name',
      defaultValue: endpointId,
      required: true,
    },
    {
      type: 'input',
      label: 'Relative path',
      nameInForm: 'relativePath',
      required: true,
      defaultValue: endpoint?.relativePath,
    },
    {
      type: 'select',
      label: 'HTTP method',
      nameInForm: 'method',
      required: true,
      defaultValue: endpoint?.method,
      options: supportedMethodTypes().map(method => ({ name: method.toUpperCase(), value: method })),
    },
    {
      type: 'textarea',
      label: 'Description',
      nameInForm: 'description',
      required: false,
      defaultValue: endpoint?.description,
      attributes: { autosize: true, minRows: 3, maxRows: 10 },
    },
  ];
}

export type RequestFieldForm = {
  name: string;
  location: ApiParameterLocation;
  description?: string;
};

export function getRequestFieldFormElements(endpoint: ApiEndpoint, fieldName?: string): Array<FormElement> {
  const field = fieldName ? truthy(endpoint.requestSchema?.[fieldName]) : undefined;
  const locations = endpoint.method === 'get' ? ['header', 'query', 'path'] : ['header', 'query', 'path', 'body'];
  return [
    {
      type: 'input',
      label: 'Field name',
      nameInForm: 'name',
      defaultValue: fieldName,
      required: true,
    },
    {
      type: 'select',
      label: 'Location',
      nameInForm: 'location',
      required: true,
      defaultValue: field?.location,
      options: locations.map(location => ({
        name: location.toUpperCase(),
        value: location,
      })),
    },
    {
      type: 'textarea',
      label: 'Description',
      nameInForm: 'description',
      required: false,
      defaultValue: field?.description,
      attributes: { autosize: true, minRows: 3, maxRows: 10 },
    },
  ];
}

export type ResponseFieldForm = {
  name: string;
  location: ApiResponseParameterLocation;
  path: string;
  description?: string;
};

export function getResponseFieldFormElements(endpoint: ApiEndpoint, fieldName?: string): Array<FormElement> {
  const field = fieldName ? truthy(endpoint.responseSchema?.[fieldName]) : undefined;
  const locations = ['body', 'header'];
  return [
    {
      type: 'input',
      label: 'Field name',
      nameInForm: 'name',
      defaultValue: fieldName,
      required: true,
    },
    {
      type: 'select',
      label: 'Location',
      nameInForm: 'location',
      required: true,
      defaultValue: field?.location,
      options: locations.map(location => ({
        name: location.toUpperCase(),
        value: location,
      })),
    },
    {
      type: 'input',
      label: 'Field path',
      nameInForm: 'path',
      required: false,
      defaultValue: field?.path,
    },
    {
      type: 'textarea',
      label: 'Description',
      nameInForm: 'description',
      required: false,
      defaultValue: field?.description,
      attributes: { autosize: true, minRows: 3, maxRows: 10 },
    },
  ];
}

export type InjectionFieldForm = {
  name: string;
  location: ApiInjectionParameterLocation;
  value: string;
  isSecret: boolean;
  secret: string;
};

export function getInjectionFieldFormElements(
  injectionSchema: Record<FieldName, ApiInjectionField>,
  fieldName?: string,
): Array<FormElement> {
  const field = fieldName ? injectionSchema?.[fieldName] : undefined;
  const locations = ['header', 'query', 'path'];

  return [
    {
      type: 'input',
      label: 'Field name',
      nameInForm: 'name',
      defaultValue: fieldName,
      required: true,
    },
    {
      type: 'select',
      label: 'Location',
      nameInForm: 'location',
      required: true,
      defaultValue: field?.location,
      options: locations.map(location => ({
        name: location.toUpperCase(),
        value: location,
      })),
      grouped: true,
    },
    {
      onChange: (formElement: FormElement, data: InjectionFieldForm): FormElement => {
        formElement.hidden = data['isSecret'];
        return formElement;
      },
      type: 'input',
      label: 'Field value',
      nameInForm: 'value',
      required: true,
      defaultValue: field?.type === 'regular' ? field?.value : undefined,
      hidden: field?.type === 'secret',
      grouped: true,
    },
    {
      type: 'boolean',
      label: 'Secret',
      hint: 'Is this a secret value?',
      nameInForm: 'isSecret',
      required: true,
      defaultValue: field?.type === 'secret',
    },
    {
      onChange: (formElement: FormElement, data: InjectionFieldForm): FormElement => {
        formElement.hidden = !data['isSecret'];
        return formElement;
      },
      type: 'secret',
      label: 'Secret value',
      nameInForm: 'secret',
      required: true,
      hidden: field?.type !== 'secret',
      defaultValue: field?.type === 'secret' ? field?.value : undefined,
      grouped: true,
    },
  ];
}

function supportedMethodTypes(): Array<HttpMethod> {
  return ['post', 'get', 'delete', 'patch', 'put'];
}
