<mat-dialog-content class="quota_dialog">
  <dialog-close-button></dialog-close-button>

  <div class="logo_title_and_message">
    <div class="logo">
      <!--suppress AngularNgOptimizedImage. Reason: image is resized by CSS. -->
      <img src="assets/images/rudder_stressed.png" alt="Logo" />
    </div>
    <div class="title_and_message">
      <div class="title">Argh, you have hit your <br>{{ quotedEntityName }} limit!</div>
      <div class="message">
        @if (data.quotaName === 'maxNumberOfIntegrations') {
          Integration limits apply across environments.<br>
        }
        Learn more about quota limits in our
        <a href="https://docs.squid.cloud/docs/orgs/quotas" target="_blank">documentation</a><br>
        or contact us to upgrade your plan.
      </div>
    </div>
  </div>
  <div class="buttons_row">
    <a mat-flat-button [mat-dialog-close]="true" (click)="showContactUsDialog()"
       target="_blank" class="primary_button large_button">Contact us</a>
  </div>

</mat-dialog-content>
