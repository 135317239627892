import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, from, Observable, of, switchMap } from 'rxjs';
import { AppId, EnvironmentId, IntegrationId, SquidRegion } from '@squidcloud/client';
import { ApplicationService } from '../../../../application/application.service';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';

interface IntegrationIdAndProfileId {
  integrationId: IntegrationId;
  profileId: string | undefined;
  aiQuery: boolean;
  introText: string;
}

export interface AiTestChatWidgetParams {
  appId: AppId;
  integrationId: IntegrationId;
  profileId: string | undefined;
  region: SquidRegion;
  aiQuery: boolean;
  environmentId?: EnvironmentId;
  apiKey: string;
  introText: string;
}

const INTRO_TEXT = 'Hi, I am Rudder, an AI from Squid. How may I assist you?';

export type ChatMessage = {
  id: string;
  type: 'ai' | 'user';
  message: string;
};

@Injectable({
  providedIn: 'root',
})
export class AiTestChatFlyOutService {
  private readonly testChatHistorySubject = new BehaviorSubject<Array<ChatMessage>>([]);
  private readonly testChatActiveSubject = new BehaviorSubject<boolean>(false);
  private readonly integrationIdAndProfileIdSubject = new BehaviorSubject<IntegrationIdAndProfileId | undefined>(
    undefined,
  );

  constructor(private readonly applicationService: ApplicationService) {}

  private readonly widgetParamsObs: Observable<AiTestChatWidgetParams> = this.integrationIdAndProfileIdSubject.pipe(
    distinctUntilChanged((a, b) => a?.integrationId === b?.integrationId && a?.profileId === b?.profileId),
    filter(Boolean),
    switchMap(integrationDetails => {
      return this.applicationService.observeCurrentApplication().pipe(
        filter(Boolean),
        switchMap(application => {
          return from(this.applicationService.getApiKey()).pipe(
            switchMap(apiKey => {
              const parsedAppId = parseAppId(application.appId);
              const result: AiTestChatWidgetParams = {
                appId: parsedAppId.appId,
                environmentId: parsedAppId.environmentId,
                profileId: integrationDetails.profileId,
                aiQuery: integrationDetails.aiQuery,
                integrationId: integrationDetails.integrationId,
                introText: integrationDetails.introText,
                region: convertToSquidRegion(
                  application.cloudId,
                  application.region,
                  application.shard,
                  environment.stage,
                ),
                apiKey,
              };
              return of(result);
            }),
          );
        }),
      );
    }),
  );

  observeWidgetParams(): Observable<AiTestChatWidgetParams> {
    return this.widgetParamsObs;
  }

  setIntegrationIdAndProfileId(
    integrationId: IntegrationId,
    profileId: string | undefined,
    aiQuery = false,
    introText = INTRO_TEXT,
  ): void {
    this.closeTestChat();
    this.integrationIdAndProfileIdSubject.next({ integrationId, profileId, aiQuery, introText });
  }

  observeTestChatActive(): Observable<boolean> {
    return this.testChatActiveSubject.asObservable();
  }

  observeChatHistory(): Observable<Array<ChatMessage>> {
    return this.testChatHistorySubject.asObservable();
  }

  setHistory(history: Array<ChatMessage>): void {
    this.testChatHistorySubject.next(history);
  }

  toggleTestChat(): void {
    this.testChatActiveSubject.next(!this.testChatActiveSubject.value);
    if (!this.testChatActiveSubject.value) {
      this.testChatHistorySubject.next([]);
      this.integrationIdAndProfileIdSubject.next(undefined);
    }
  }

  closeTestChat(): void {
    this.testChatActiveSubject.next(false);
    this.testChatHistorySubject.next([]);
    this.integrationIdAndProfileIdSubject.next(undefined);
  }
}
