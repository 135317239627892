import { assertTruthy } from 'assertic';
import { firstValueFrom } from 'rxjs';
import { appIdWithEnvironmentIdAndDevId } from '../../internal-common/src/types/communication.types';
import { getApplicationHttpHeaders, getApplicationUrl } from '../../internal-common/src/utils/http';
import { LockManager } from '../../internal-common/src/utils/lock.manager';
import { normalizeJsonAsString } from '../../internal-common/src/utils/serialization';
import { AiChatbotClientFactory } from './ai-chatbot-client.factory';
import { AiClient } from './ai.types';
import { ApiClient } from './api-client';
import { AuthManager } from './auth.manager';
import { BackendFunctionManager } from './backend-function.manager';
import { ClientIdService } from './client-id.service';
import { CollectionReference } from './collection-reference';
import { CollectionReferenceFactory } from './collection-reference.factory';
import { ConnectionDetails } from './connection-details';
import { DataManager } from './data.manager';
import { DestructManager } from './destruct.manager';
import { DistributedLock, DistributedLockManager } from './distributed-lock.manager';
import DocumentIdentityService from './document-identity.service';
import { DocumentReferenceFactory } from './document-reference.factory';
import { DocumentStore } from './document-store';
import { MutationSender } from './mutation/mutation-sender';
import { NativeQueryManager } from './native-query-manager';
import {
  ApiKey,
  AppId,
  CollectionName,
  DocumentData,
  EnvironmentId,
  IntegrationId,
  IntegrationType,
  SquidDeveloperId,
  SquidRegion,
} from './public-types';
import { LocalQueryManager } from './query/local-query-manager';
import { QueryBuilderFactory } from './query/query-builder.factory';
import { QuerySender } from './query/query-sender';
import { QuerySubscriptionManager } from './query/query-subscription.manager';
import { QueueManager, QueueManagerFactory } from './queue.manager';
import { RpcManager } from './rpc.manager';
import { SecretClient } from './secret.client';
import { SocketManager } from './socket.manager';
import { StorageClient } from './storage-client';
import { TransactionId } from './types';
import {
  MongoNativeQueryContext,
  RelationalNativeQueryContext,
} from '../../internal-common/src/public-types-backend/native-query.public-context';
import { ObservabilityClient } from './observability-client';
import { ExtractionClient } from './extraction-client';
import { DebugLogger } from '../../internal-common/src/utils/global.utils';
import { PersonalStorageClient } from './personal-storage-client';
import { SchedulerClient } from './scheduler-client';
import { IntegrationClient } from './integration-client';

/** The different options that can be used to initialize a Squid instance. */
export interface SquidOptions {
  /**
   * A function that can be used to wrap messages coming from Squid to the application. This is useful for
   * different frameworks that need to wrap messages in order to detect changes (like Angular).
   * @param fn The function to wrap.
   */
  messageNotificationWrapper?: (fn: () => any) => any;

  /**
   * The application ID that is used to identify the application in Squid. The ID can be found in the Squid Cloud
   * Console.
   */
  appId: AppId;

  /**
   * The application API key, using the API key can be used to bypass security rules and other restrictions.
   * The API key can be found in the Squid Console.
   */
  apiKey?: ApiKey;

  /**
   * Access token provider for the Squid instance.
   * Used for managing the process of verifying the identity and authorization of users who attempt to access this
   * application via the current Squid instance.
   *
   * When the authProvider is set, the Squid service will fetch a token and include it with every request to the Squid
   * backend.
   *
   * On the backend, Squid will validate the access token.
   */
  authProvider?: SquidAuthProvider;

  /**
   * The region that the application is running in. This is used to determine the URL of the Squid API.
   */
  region: SquidRegion;

  /**
   * The environment ID to work with, if not specified the default environment (prod) will be used.
   */
  environmentId?: EnvironmentId;

  /**
   * The user ID of the developer that runs the environment locally.
   */
  squidDeveloperId?: SquidDeveloperId;
}

type SquidOptionsStr = string;

/** Authentication data provider for Squid requests. */
export interface SquidAuthProvider {
  /**
   * Returns a valid AccessToken or undefined if there is no active authorized session.
   * Called by Squid every time a Squid client makes requests to the Squid backend.
   */
  getToken(): Promise<string | undefined> | string | undefined;

  /**
   * Optional Auth integration id.
   * Sent as a part of all Squid requests to the backend.
   */
  integrationId: string;
}

/**
 * The main entry point to the Squid Client SDK.
 *
 * The Squid class provides a comprehensive array of functionality for accessing the different integrations, executing
 * backend functions, managing data, and more. Upon instantiating the Squid class, you will have access to all of these
 * capabilities.
 * All public Squid functions are bound to `this` and can be used with a destructuring patterns,
 * like `const {setAuthProvider} = useSquid()`
 */
export class Squid {
  private readonly socketManager: SocketManager;
  private readonly rpcManager: RpcManager;
  private readonly dataManager: DataManager;
  private readonly documentReferenceFactory: DocumentReferenceFactory;
  private readonly documentStore: DocumentStore;
  private readonly lockManager: LockManager;
  private readonly querySubscriptionManager: QuerySubscriptionManager;
  private readonly localQueryManager: LocalQueryManager;
  private readonly queryBuilderFactory: QueryBuilderFactory;
  private readonly collectionReferenceFactory: CollectionReferenceFactory;
  private readonly backendFunctionManager: BackendFunctionManager;
  private readonly nativeQueryManager: NativeQueryManager;
  private readonly destructManager = new DestructManager();
  private readonly documentIdentityService: DocumentIdentityService;
  private readonly distributedLockManager: DistributedLockManager;
  private readonly authManager: AuthManager;
  private readonly clientIdService: ClientIdService;
  private readonly aiClientFactory: AiChatbotClientFactory;
  private readonly _connectionDetails: ConnectionDetails;
  private readonly secretClient: SecretClient;
  private readonly querySender: QuerySender;
  private static readonly squidInstancesMap: Record<SquidOptionsStr, Squid> = {};
  private readonly aiClient: AiClient;
  private readonly apiClient: ApiClient;
  private readonly observabilityClient: ObservabilityClient;
  private readonly queueManagerFactory: QueueManagerFactory;
  private readonly schedulerClient: SchedulerClient;
  private readonly integrationClient: IntegrationClient;
  private readonly _appId: AppId;

  /**
   * Creates a new instance of Squid with the given options.
   *
   * @param options The options for initializing the Squid instance.
   */
  constructor(public readonly options: SquidOptions) {
    assertTruthy(options.appId, 'APP_ID_MUST_BE_PROVIDED');

    // Bind all public functions to 'this'.
    // This functionality is needed to support React-style destructuring like `const {setAuthProvider} = useSquid().
    // Only private function are not bound. The naming convention for the private functions: name starts with '_'.
    for (const methodName of Object.getOwnPropertyNames(Object.getPrototypeOf(this))) {
      const method = (this as Record<string, unknown>)[methodName];
      if (typeof method === 'function' && methodName !== 'constructor' && !methodName.startsWith('_')) {
        (this as Record<string, unknown>)[methodName] = method.bind(this);
      }
    }
    const shouldAddDeveloperId = options.environmentId !== 'prod' && options.squidDeveloperId;
    const appId = appIdWithEnvironmentIdAndDevId(
      options.appId,
      options.environmentId,
      shouldAddDeveloperId ? options.squidDeveloperId : undefined,
    );
    const httpHeaders = getApplicationHttpHeaders(options.region, appId);
    this.clientIdService = new ClientIdService(this.destructManager);

    DebugLogger.debug(this.clientIdService.getClientId(), 'New Squid instance created');

    this.authManager = new AuthManager(options.apiKey, options.authProvider);
    this.socketManager = new SocketManager(
      this.clientIdService,
      options.region,
      appId,
      options.messageNotificationWrapper,
      this.destructManager,
      this.authManager,
    );
    this.rpcManager = new RpcManager(
      options.region,
      appId,
      this.destructManager,
      httpHeaders,
      this.authManager,
      this.clientIdService,
    );

    this.aiClientFactory = new AiChatbotClientFactory(this.rpcManager, this.socketManager);
    this.aiClient = new AiClient(this.aiClientFactory, this.rpcManager);
    this.apiClient = new ApiClient(this.rpcManager);
    this.documentStore = new DocumentStore();
    this.lockManager = new LockManager();
    this.distributedLockManager = new DistributedLockManager(this.socketManager, this.destructManager);
    this.documentIdentityService = new DocumentIdentityService(this.documentStore, this.destructManager);
    this.documentReferenceFactory = new DocumentReferenceFactory(this.documentIdentityService);
    this.querySender = new QuerySender(this.rpcManager, this.destructManager);
    this.observabilityClient = new ObservabilityClient(this.rpcManager);
    this.schedulerClient = new SchedulerClient(this.rpcManager);
    this.integrationClient = new IntegrationClient(this.rpcManager);
    this._appId = appId;

    this.querySubscriptionManager = new QuerySubscriptionManager(
      this.rpcManager,
      this.clientIdService,
      this.documentStore,
      this.destructManager,
      this.documentIdentityService,
      this.querySender,
    );

    this.localQueryManager = new LocalQueryManager(
      this.documentStore,
      this.documentReferenceFactory,
      this.querySubscriptionManager,
    );

    const mutationSender = new MutationSender(this.rpcManager, this.lockManager, this.querySender);

    this.queryBuilderFactory = new QueryBuilderFactory(
      this.querySubscriptionManager,
      this.localQueryManager,
      this.documentReferenceFactory,
      this.documentIdentityService,
    );

    this.dataManager = new DataManager(
      this.documentStore,
      mutationSender,
      this.socketManager,
      this.querySubscriptionManager,
      this.queryBuilderFactory,
      this.lockManager,
      this.destructManager,
      this.documentIdentityService,
      this.querySender,
    );

    this.collectionReferenceFactory = new CollectionReferenceFactory(
      this.documentReferenceFactory,
      this.queryBuilderFactory,
      this.querySubscriptionManager,
      this.dataManager,
    );

    this.documentReferenceFactory.setDataManager(this.dataManager);
    this.backendFunctionManager = new BackendFunctionManager(this.clientIdService, this.rpcManager);
    this.nativeQueryManager = new NativeQueryManager(this.rpcManager);

    this.secretClient = new SecretClient(this.rpcManager);
    this._connectionDetails = new ConnectionDetails(this.clientIdService, this.socketManager);
    this.queueManagerFactory = new QueueManagerFactory(this.rpcManager, this.socketManager, this.destructManager);
  }

  /**
   * Returns the global Squid instance with the given options, creating a new instance if one with the same options
   * does not exist.
   *
   * @param options The options for initializing the Squid instance.
   * @returns A global Squid instance with the given options.
   */
  static getInstance(options: SquidOptions): Squid {
    const optionsStr = normalizeJsonAsString(options);
    let instance: Squid | undefined = Squid.squidInstancesMap[optionsStr];
    if (instance) return instance;
    instance = new Squid(options);
    Squid.squidInstancesMap[optionsStr] = instance;
    return instance;
  }

  /**
   * Returns all the global Squid instances.
   *
   * @returns An array of all the global Squid instances.
   */
  static getInstances(): Array<Squid> {
    return Object.values(Squid.squidInstancesMap);
  }

  /**
   * Sets the authorization access token (OAuth2.0) provider that will be sent to the server and will be used for
   * providing the `auth` object to the security rules.
   *
   * @param authProvider The OAuth2.0 access token provider invoked for every backend request by Squid.
   *    When the provider returns undefined, no authorization information is sent.
   *    When a new provider is set, all future Squid backend requests will use the new token provider, and exising
   *    in-flight requests won't be affected.
   * @returns void.
   */
  setAuthProvider(authProvider: SquidAuthProvider): void {
    this.authManager.setAuthProvider(authProvider);
  }

  /**
   * Returns a reference to the collection in the provided integration.
   *
   * If the integrationId is not provided, the `built_in_db` integration id will be used.
   *
   * For more information on the CollectionReference object, please refer to the
   * {@link https://docs.getsquid.ai/docs/database/collection-reference documentation}.
   *
   * @param collectionName The name of the collection.
   * @param integrationId The id of the integration, default to `built_in_db`.
   * @returns A reference to the collection in the provided integration.
   * @typeParam T The type of the documents in the collection.
   */
  collection<T extends DocumentData>(
    collectionName: CollectionName,
    integrationId: IntegrationId = IntegrationType.built_in_db,
  ): CollectionReference<T> {
    this._validateNotDestructed();
    return this.collectionReferenceFactory.get(collectionName, integrationId);
  }

  /**
   * Runs the given callback as an atomic change. All the mutations that are executed using the provided transactionId
   * will be atomic. Note that mutations for different integrations will not be atomic.
   *
   * For more information about transactions in Squid, please refer to the
   * {@link https://docs.getsquid.ai/docs/database/transactions documentation}.
   *
   * @param fn The callback to run as an atomic change. The function receives a transactionId that should be used for
   * all the mutations that should be atomic. The function should return a promise.
   *
   * @returns A promise that resolves when the transactions are committed on the server.
   */
  runInTransaction<T = any>(fn: (transactionId: TransactionId) => Promise<T>): Promise<T> {
    this._validateNotDestructed();
    return this.dataManager.runInTransaction<T>(fn);
  }

  /**
   * Executes the given backend function with the given parameters and returns a promise with the result.
   *
   * For more information about backend functions in Squid, please refer to the
   * {@link https://docs.getsquid.ai/docs/backend/executables/ documentation}.
   *
   * @param functionName The name of the function to execute on the server.
   * @param params The parameters to pass to the function.
   * @returns A promise that resolves with the result of the function.
   * @typeParam T The type of the result of the function.
   */
  executeFunction<T = any>(functionName: string, ...params: any[]): Promise<T> {
    this._validateNotDestructed();
    return firstValueFrom(this.backendFunctionManager.executeFunctionAndSubscribe<T>(functionName, ...params));
  }

  /**
   * Executes a native relational query with the given parameters and returns a promise with the result.
   * See https://docs.getsquid.ai/docs/database/native-queries.
   *
   * @param integrationId The id of the integration that the query is associated with.
   * @param query The raw SQL or other database-specific query to execute.
   * @param params (Optional) The parameters to pass to the query. Defaults to an empty object.
   * @returns A promise that resolves with the result of the query.
   * @type {Promise<Array<SquidDocument>>}
   */
  executeNativeRelationalQuery<T = any>(
    integrationId: IntegrationId,
    query: string,
    params: Record<string, any> = {},
  ): Promise<Array<T>> {
    const request: RelationalNativeQueryContext = { type: 'relational', query, params, integrationId };
    return this.nativeQueryManager.executeNativeQuery(request);
  }

  /**
   * Executes a native Mongo/built-in-db query with the given pipeline and returns a promise with the result.
   * See https://docs.getsquid.ai/docs/database/native-queries#native-mongodb-queries.
   *
   * @param integrationId The id of the integration that the query is associated with.
   * @param collectionName The collection to query.
   * @param aggregationPipeline The aggregation pipeline for the query.
   * @returns A promise that resolves with the result of the query.
   * @type {Promise<Array<SquidDocument>>}
   */
  executeNativeMongoQuery<T = any>(
    integrationId: IntegrationId,
    collectionName: string,
    aggregationPipeline: Array<any | undefined>,
  ): Promise<Array<T>> {
    const request: MongoNativeQueryContext = { type: 'mongo', collectionName, aggregationPipeline, integrationId };
    return this.nativeQueryManager.executeNativeQuery(request);
  }

  /**
   * Returns a set of AI specific clients.
   *
   * @returns A set of AI specific clients.
   */
  ai(): AiClient {
    this._validateNotDestructed();
    return this.aiClient;
  }

  api(): ApiClient {
    this._validateNotDestructed();
    return this.apiClient;
  }

  storage(integrationId: IntegrationId = 'built_in_storage'): StorageClient {
    this._validateNotDestructed();
    return new StorageClient(integrationId, this.rpcManager);
  }

  personalStorage(integrationId: IntegrationId): PersonalStorageClient {
    this._validateNotDestructed();
    return new PersonalStorageClient(integrationId, this.rpcManager);
  }

  extraction(): ExtractionClient {
    this._validateNotDestructed();
    return new ExtractionClient(this.rpcManager);
  }

  get secrets(): SecretClient {
    return this.secretClient;
  }

  get observability(): ObservabilityClient {
    return this.observabilityClient;
  }

  get schedulers(): SchedulerClient {
    return this.schedulerClient;
  }

  get integrations(): IntegrationClient {
    return this.integrationClient;
  }

  get appId(): AppId {
    return this._appId;
  }

  /**
   * Returns a distributed lock for the given mutex. The lock can be used to synchronize access to a shared resource.
   * The lock will be released when the release method on the returned object is invoked or whenever the connection
   * with the server is lost.
   * @param mutex A string that uniquely identifies the lock.
   * @returns A promise that resolves with the lock object. The promise will reject if failed to acquire the lock.
   */
  acquireLock(mutex: string): Promise<DistributedLock> {
    this._validateNotDestructed();
    return this.distributedLockManager.lock(mutex);
  }

  /**
   * Executes the given callback while holding a lock for the given mutex. The lock will be released when the callback
   * finishes.
   * @param mutex A string that uniquely identifies the lock.
   * @param fn The callback to execute while holding the lock.
   * @returns A promise that resolves with the result of the callback. The promise will reject if failed
   *   to acquire the lock.
   */
  async withLock<T>(mutex: string, fn: (lock: DistributedLock) => Promise<T>): Promise<T> {
    const lock = await this.acquireLock(mutex);
    try {
      return await fn(lock);
    } finally {
      void lock.release();
    }
  }

  /**
   * Returns a queue manager for the given topic name and integration id. Using the queue manager you can consume and
   * produce messages
   */
  queue<T>(topicName: string, integrationId: IntegrationId = IntegrationType.built_in_queue): QueueManager<T> {
    this._validateNotDestructed();
    return this.queueManagerFactory.get<T>(integrationId, topicName);
  }

  /**
   * Destructs the Squid Client. Unsubscribes from all ongoing queries or requests, and clears the local data.
   * After invoking this method, the Squid client will not be usable.
   *
   * @returns A promise that resolves when the destruct process is complete.
   */
  async destruct(): Promise<void> {
    return this.destructManager.destruct().finally(() => {
      const entry = Object.entries(Squid.squidInstancesMap).find(([, value]) => value === this);
      if (entry) delete Squid.squidInstancesMap[entry[0]];
    });
  }

  /** Provides information about the connection to the Squid Server. */
  connectionDetails(): ConnectionDetails {
    this._validateNotDestructed();
    return this._connectionDetails;
  }

  /**
   * @internal
   */
  async _unsubscribe(): Promise<void> {
    this.querySubscriptionManager.unsubscribe();
    await this.rpcManager.awaitAllSettled();
  }

  private _validateNotDestructed(): void {
    assertTruthy(!this.destructManager.isDestructing, 'The client was already destructed.');
  }

  /**
   * An interface provided by Squid to @squidcloud/* packages but not exposed to users.
   * @internal.
   */
  internal(): SquidInternalForGraphQl {
    const { rpcManager } = this;
    return {
      getApplicationUrl(region: string, appId: string, integrationId: string): string {
        return getApplicationUrl(region, appId, integrationId);
      },
      getStaticHeaders(): Record<string, string> {
        return rpcManager.getStaticHeaders();
      },
      getAuthHeaders(): Promise<Record<string, string>> {
        return rpcManager.getAuthHeaders();
      },
      appIdWithEnvironmentIdAndDevId(
        appId: AppId,
        environmentId: EnvironmentId | undefined,
        developerId: SquidDeveloperId | undefined,
      ): AppId {
        return appIdWithEnvironmentIdAndDevId(appId, environmentId, developerId);
      },
    };
  }
}

/**
 * The interface supported by Squid and used by @squidcloud/graphql, but not exposed to public.
 * @internal.
 */
interface SquidInternalForGraphQl {
  getApplicationUrl: (region: string, appId: string, integrationId: string) => string;
  appIdWithEnvironmentIdAndDevId: (
    appId: AppId,
    environmentId: EnvironmentId | undefined,
    developerId: SquidDeveloperId | undefined,
  ) => AppId;
  getStaticHeaders: () => Record<string, string>;
  getAuthHeaders: () => Promise<Record<string, string>>;
}
