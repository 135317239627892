<div class="page">
  <div class="back_to_squid_block">
    <a mat-stroked-button href="https://getsquid.ai" class="xl_button">Back to Squid AI</a>
  </div>
  <div class="container">
    <div class="left_block">
      <mat-icon class="logo" svgIcon="logo" />
      <h3 class="headline">Welcome to Squid AI</h3>
      <p class="text">
        Please complete this form so we can provide one-on-one onboarding and
        access to Squid AI.
      </p>
      @if (isMarketplaceMode) {
        <p class="text">
          For support, please contact <a href="mailto:{{SQUID_SUPPORT_EMAIL}}"
                                         class="link">{{ SQUID_SUPPORT_EMAIL }}</a>.
        </p>
      }
    </div>
    <div class="right_block">
      <div class="form">
        <magic-form [data]="formConfig"></magic-form>
      </div>
    </div>
  </div>
</div>
