<div class="onboarding_content">
  <div class="onboarding_cards">
    <h1>How would you like to get started?</h1>
    <onboarding-step />
    <div class="carousel-wrapper">
      <carousel>
        <div class="container">
          @for (card of cards; track card.id) {
            <button class="card carousel-item"
                    [routerLink]="['/onboarding', 'application', card.id]"
                    [attr.data-testid]="'onboarding-' + card.id + '-button'"
            >
              <div class="content">
                <span class="title">{{ card.title }}</span>
                <p class="description">{{ card.description }}</p>
              </div>
              <img src="assets/images/onboarding/{{ card.id }}.png" />
            </button>
          }
        </div>
      </carousel>
    </div>
    <a
      mat-flat-button
      (click)="onLearnMore()"
      target="__blank"
      class="learn_more secondary_button large_button"
      href="https://docs.squid.cloud/docs/getting-started/what-is-squid"
    >
      Not here to build at the moment. Where can I learn more?
    </a>
  </div>
</div>
