import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

const DURATION = 5000;

@Injectable({
  providedIn: 'root',
})
export class SnackBarService extends MatSnackBar {
  override open(
    message: string,
    action: string | null = 'Dismiss',
    config?: MatSnackBarConfig,
  ): MatSnackBarRef<TextOnlySnackBar> {
    const modifiedConfig = { duration: DURATION, ...config };
    return super.open(message, action || undefined, modifiedConfig);
  }

  success(message: string, action?: string | null, config?: MatSnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
    const modifiedConfig = { panelClass: 'success', ...config };
    return this.open(message, action, modifiedConfig);
  }

  warning(message: string, action?: string | null, config?: MatSnackBarConfig): MatSnackBarRef<TextOnlySnackBar> {
    const modifiedConfig = { panelClass: 'warning', ...config };
    return this.open(message, action, modifiedConfig);
  }
}
