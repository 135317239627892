import { Injectable } from '@angular/core';
import { AppId, Squid } from '@squidcloud/client';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { BackendMetricPeriodType, GetBackendMetricsResponse } from '@squidcloud/console-common/types/metrics.types';

@Injectable({
  providedIn: 'root',
})
export class MetricsService {
  constructor(private readonly squid: Squid) {}

  async getMetrics(appId: AppId, periodType: BackendMetricPeriodType): Promise<GetBackendMetricsResponse> {
    return callBackendExecutable(this.squid, 'getBackendMetrics', { appId, periodType });
  }
}
