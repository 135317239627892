import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ApiInjectionFieldType, ApiInjectionParameterLocation, ApiInjectionSchema } from '@squidcloud/client';
import { GlobalUiService } from '../../../global/services/global-ui.service';
import { getInjectionFieldFormElements, InjectionFieldForm } from '../../schema/api-schema/utils/dialog';
import { findAvailableDuplicateFieldName } from '../../schema/utils';
import { cloneDeep } from '@squidcloud/internal-common/utils/object';

@Component({
  selector: 'injection-table',
  templateUrl: './injection-table.component.html',
  styleUrls: ['./injection-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InjectionTableComponent implements OnChanges {
  @Input() schema!: ApiInjectionSchema;
  @Input() isModified!: boolean;

  @Output() schemaChanged = new EventEmitter<ApiInjectionSchema>();
  @Output() modifiedChanged = new EventEmitter<boolean>();

  fields: Array<{ name: string; location: string; secret: boolean; value: string }> = [];

  constructor(private readonly globalUiService: GlobalUiService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schema']) {
      this.setFields(changes['schema'].currentValue);
    }
  }

  duplicateField(fieldName: string): void {
    const properties = { ...this.schema };
    const duplicateName = findAvailableDuplicateFieldName(properties, fieldName);
    properties[duplicateName] = cloneDeep(properties[fieldName]);
    this.schemaChanged.emit(properties);
    this.modifiedChanged.emit(true);
  }

  showDeleteFieldDialog(fieldName: string): void {
    const message = `This will delete the ${fieldName} property.`;

    this.globalUiService.showConfirmationDialog('Arrrrr you sure?', message, 'Delete', () => {
      const properties = { ...this.schema };
      delete properties[fieldName];
      this.schemaChanged.emit(properties);
      this.modifiedChanged.emit(true);
    });
  }

  async showFieldDialog(fieldName?: string): Promise<InjectionFieldForm | undefined> {
    const isEdit = !!fieldName;

    const formElements = getInjectionFieldFormElements(this.schema, fieldName);

    return this.globalUiService.showDialogWithForm<InjectionFieldForm>({
      title: `${isEdit ? 'Edit' : 'Add'} injection field`,
      textLines: [],
      submitButtonText: isEdit ? 'Update' : 'Add field',
      minRole: 'ADMIN',
      formElements,
      onSubmit: (data): void => {
        if (!data) return;
        const name = data['name'];
        const location = data['location'];
        const isSecret = data['isSecret'];
        const type = isSecret ? 'secret' : 'regular';
        const value = isSecret ? data['secret'] : data['value'];
        this.setInjectionField(fieldName, name, location, type, value);
      },
    });
  }

  setFields(schema: ApiInjectionSchema): void {
    this.fields = Object.entries(schema)
      .map(([fieldName, injectionField]) => {
        return {
          name: fieldName,
          location: injectionField.location,
          secret: injectionField.type === 'secret',
          value: injectionField.value || '',
        };
      })
      .sort((f1, f2) => f1.name.localeCompare(f2.name));
  }

  setInjectionField(
    currentName: string | undefined,
    newName: string,
    location: ApiInjectionParameterLocation | undefined,
    type: ApiInjectionFieldType,
    value: string | undefined,
  ): void {
    const properties = { ...this.schema };
    const isEdit = currentName !== undefined;

    if (isEdit) {
      if (newName !== currentName) {
        properties[newName] = properties[currentName];
        delete properties[currentName];
      }
    }

    const field = properties[newName] || {};

    if (location) field.location = location;
    if (type) field.type = type;
    if (value) field.value = value;

    properties[newName] = field;

    this.schemaChanged.emit(properties);
    this.modifiedChanged.emit(true);
  }
}
