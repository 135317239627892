import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { assertTruthy } from 'assertic';

@Component({
  selector: 'schema-select-row',
  templateUrl: './schema-select-row.component.html',
  styleUrls: ['./schema-select-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchemaSelectRowComponent implements OnInit, OnChanges {
  @Input({ required: true }) title!: string;
  @Input({ required: true }) icon!: string;
  @Input() isModified = false;
  @Input() selected = false;
  @Input() mono = false;
  @Input() inFolder = false;

  /** Emitted when a user activates the row element (clicks the row). */
  @Output() activate = new EventEmitter<void>();

  ngOnChanges(): void {
    this.checkRequiredInputs();
  }

  ngOnInit(): void {
    this.checkRequiredInputs();
  }

  private checkRequiredInputs(): void {
    assertTruthy(this.title, `'title' must be set`);
    assertTruthy(this.icon, `'icon' must be set`);
  }
}
