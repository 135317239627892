export const numberString = (number: number): string => {
  const MAP: Record<number, string> = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
  };
  return MAP[number] || String(number);
};

export const plural = (string: string, count: number, options: { suffix?: string; alt?: string } = {}): string => {
  if (options.alt) return count === 1 ? string : options.alt;
  if (count === 1) return string;
  return string + (options.suffix || 's');
};

export const titleCase = (string: string): string => {
  return string.replace(/\w\S*/g, text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
};
