import { Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { CollectionReference, Squid } from '@squidcloud/client';
import { CpUser, CreateUserRequest } from '@squidcloud/console-common/types/account.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { UserFeatureId } from '@squidcloud/internal-common/features/user-features';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { checkArraysHaveEqualElements, truthy } from 'assertic';
import { SegmentService } from 'ngx-segment-analytics';
import { BehaviorSubject, distinctUntilChanged, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { StatusPageComponent } from '../global/components/status-page/status-page.component';
import {
  forgetMarketplaceAccountSetupEventId,
  getActiveMarketplaceAccountSetupEvent,
} from '@squidcloud/console-web/app/utils/marketplace-web-utils';
import { UserLockPageComponent } from '@squidcloud/console-web/app/global/components/user-lock-page/user-lock-page.component';
import { REGISTRATION_IS_NOT_ALLOWED_ERROR } from '@squidcloud/console-common/utils/account-utils';
import { LocalStorageService } from '@squidcloud/console-web/app/global/services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  private readonly currentUserSubject = new BehaviorSubject<CpUser | undefined>(undefined);
  private userObs: Observable<CpUser | undefined> = this.auth0Service.user$.pipe(
    switchMap(auth0User => {
      if (!auth0User) return of(undefined);
      const userId = truthy(auth0User.sub, 'INVALID_USER_ID');
      return this.getUserCollection()
        .query()
        .where('id', '==', userId)
        .snapshots()
        .pipe(
          map(results => results[0]?.data),
          tap(async cpUser => {
            if (cpUser) {
              void callBackendExecutable(this.squid, 'executePostLoginActions', undefined);
              return;
            }
            const userId = truthy(auth0User.sub, 'INVALID_USER_ID');
            const userName = truthy(auth0User.name, 'INVALID_NAME');
            const invitationKey = this.localStorageService.getItem<string>('invitationKey');
            const marketplaceSetupEvent = getActiveMarketplaceAccountSetupEvent();
            let cleanupMarketplaceSetupEvent = true;
            try {
              const createUserRequest: CreateUserRequest = {
                id: userId,
                name: userName,
                invitationKey,
                marketplaceType: marketplaceSetupEvent?.type,
                marketplaceSetupEventId: marketplaceSetupEvent?.eventId,
              };
              const { id } = await callBackendExecutable(this.squid, 'createUser', createUserRequest);

              if (id !== auth0User.sub) {
                // The account was linked. Kill the current (linked) session since this user does not exist
                // anymore and ask a user to sign in with any of the linked accounts again. It will result to
                // a new Auth0 session with a master account id.
                cleanupMarketplaceSetupEvent = false; // Backend does not handle MP org creation on account
                // linking.
                const title = 'Account Linked Successfully';
                const message =
                  'Your current account has been linked to your original account.\nPlease re-login using any of your credentials to access the linked account.';
                const buttonText = 'Re-login';
                this.auth0Service.logout({
                  logoutParams: {
                    returnTo:
                      window.location.origin + StatusPageComponent.buildStatusPagePath(message, title, buttonText),
                  },
                });
              }
            } catch (error) {
              const messageFromError = getMessageFromError(error);
              // Signing out so that the user won't be stuck not being able to sign out.
              let postLogoutRedirectUrl: string;
              if (messageFromError.includes(REGISTRATION_IS_NOT_ALLOWED_ERROR)) {
                postLogoutRedirectUrl =
                  window.location.origin + UserLockPageComponent.buildPagePath(auth0User.email || '', 'new-user');
              } else {
                console.log('Failed to create user', error);
                // TODO: it can be any error here (including internal errors).
                const errorMessage = `Sign up failed: ${messageFromError}.\nPlease try again later.`;
                postLogoutRedirectUrl = window.location.origin + StatusPageComponent.buildErrorPagePath(errorMessage);
              }
              this.auth0Service.logout({ logoutParams: { returnTo: postLogoutRedirectUrl } });
            } finally {
              if (invitationKey) {
                this.localStorageService.removeItem('invitationKey');
              }
              if (marketplaceSetupEvent && cleanupMarketplaceSetupEvent) {
                forgetMarketplaceAccountSetupEventId(marketplaceSetupEvent.eventId);
              }
            }
          }),
        );
    }),
    shareReplay(1),
  );

  /**
   * Time of the last marketplace purchase activation start.
   * Successful purchase activation results to organization creation.
   */
  mpPurchaseActivationEventTime = -1;

  constructor(
    private readonly auth0Service: Auth0Service,
    private readonly squid: Squid,
    private readonly segment: SegmentService,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.userObs.subscribe(async cpUser => {
      this.currentUserSubject.next(cpUser);
      // On every user sign-in activate pending GCP & AWS Marketplace orders.
      if (cpUser) {
        const marketplaceSetupEvent = getActiveMarketplaceAccountSetupEvent();
        if (marketplaceSetupEvent) {
          try {
            this.mpPurchaseActivationEventTime = Date.now();
            switch (marketplaceSetupEvent.type) {
              case 'aws':
                await callBackendExecutable(this.squid, 'activateAwsPurchase', {
                  awsMpAccountSetupEventId: marketplaceSetupEvent.eventId,
                });
                break;
              case 'gcp':
                await callBackendExecutable(this.squid, 'activateGcpPurchase', {
                  gcpMpAccountSetupEventId: marketplaceSetupEvent.eventId,
                });
                break;
            }
          } finally {
            forgetMarketplaceAccountSetupEventId(marketplaceSetupEvent.eventId);
          }
        }
      }
    });
  }

  async updateName(newName: string): Promise<void> {
    await this.updateUser({ name: newName });
  }

  async updateUser(updates: Partial<CpUser>): Promise<void> {
    const cpUser = this.getUserOrFail();
    await this.getUserCollection().doc(cpUser.id).update(updates);
  }

  get user$(): Observable<CpUser | undefined> {
    return this.userObs;
  }

  observeUser(): Observable<CpUser | undefined> {
    return this.userObs;
  }

  /** Returns an observable over the list of the active user features. */
  get features$(): Observable<Array<UserFeatureId>> {
    return this.user$.pipe(
      map(u => u?.features || []),
      distinctUntilChanged((before, after) => checkArraysHaveEqualElements(before, after)),
    );
  }

  getUserOrFail(): CpUser {
    return truthy(this.currentUserSubject.value, 'NO_CURRENT_USER');
  }

  logout(): void {
    this.segment.reset();
    this.auth0Service.logout({
      logoutParams: {
        // Return to the current domain, but not the first one in Auth0 config.
        returnTo: window.location.origin,
      },
    });
  }

  private getUserCollection(): CollectionReference<CpUser> {
    return this.squid.collection<CpUser>('user');
  }

  dismissPaymentDetailsBanner(): void {
    this.getUserCollection()
      .doc(this.getUserOrFail().id)
      .setInPath('uiPreferences.dismissedPaymentDetailsBanner', true)
      .then();
  }

  setSideBarCollapseState(collapsed: boolean): void {
    this.getUserCollection().doc(this.getUserOrFail().id).setInPath('uiPreferences.sideBarCollapsed', collapsed).then();
  }
}
