import { SquidAuthProvider } from './squid';
import { ApiKey, AuthToken, IntegrationId } from './public-types';

/** Holds authentication token for the specified integration. */
export interface AuthData {
  token: string | undefined;
  integrationId?: IntegrationId;
}

/** @internal */
export class AuthManager {
  constructor(
    private readonly apiKey: ApiKey | undefined,
    private authProvider?: SquidAuthProvider,
  ) {}

  /**
   * Sets a new auth-token provider to Squid.
   * All future squid backend requests will use this token provider.
   * Exising in-flight requests won't be affected.
   */
  setAuthProvider(authProvider: SquidAuthProvider): void {
    this.authProvider = authProvider;
  }

  async getAuthData(): Promise<AuthData> {
    return {
      token: await this.getTokenFromAuthProvider(),
      integrationId: this.authProvider?.integrationId,
    };
  }

  private async getTokenFromAuthProvider(): Promise<string | undefined> {
    const promiseOrPrimitive = this.authProvider?.getToken();
    return typeof promiseOrPrimitive === 'object' ? await promiseOrPrimitive : promiseOrPrimitive;
  }

  getApiKey(): ApiKey | undefined {
    return this.apiKey;
  }

  /**
   * Returns a valid AuthToken.
   * Tries to use `apiKey` first if set up.
   * Falls back to `await authTokenProvider()` result.
   */
  async getToken(): Promise<AuthToken | undefined> {
    if (this.apiKey) {
      return { type: 'ApiKey', token: this.apiKey };
    }
    const token = await this.getTokenFromAuthProvider();
    if (!token) return undefined;
    return { type: 'Bearer', token, integrationId: this.authProvider?.integrationId };
  }
}
