import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { Auth0IdentityProviderType, CpUser } from '@squidcloud/console-common/types/account.types';
import { SnackBarService } from '@squidcloud/console-web/app/global/services/snack-bar.service';
import { GlobalUiService } from '@squidcloud/console-web/app/global/services/global-ui.service';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { Squid } from '@squidcloud/client';
import { isPrimaryUserAccount } from '@squidcloud/console-common/utils/account-utils';

@Component({
  selector: 'linked-accounts',
  templateUrl: './linked-accounts.component.html',
  styleUrl: './linked-accounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedAccountsComponent {
  readonly identityProviderOptions: Array<IdentityProviderOption> = [
    {
      provider: 'auth0',
      label: 'Email and Password',
      tooltip: 'Sign in to Squid using your email and password credentials',
    },
    {
      provider: 'google-oauth2',
      label: 'Google',
      tooltip: 'Sign in to Squid with your Google account',
    },
    {
      provider: 'github',
      label: 'GitHub',
      tooltip: 'Sign in to Squid using your GitHub account',
    },
  ];

  constructor(
    protected readonly accountService: AccountService,
    private readonly snackBar: SnackBarService,
    private readonly globalUiService: GlobalUiService,
    private readonly squid: Squid,
  ) {}

  checkAccountIsLinked(option: IdentityProviderOption, user: CpUser): boolean {
    return (
      isPrimaryUserAccount(user.id, option.provider) ||
      (user.auth0Info?.identities || []).some(o => o.provider === option.provider)
    );
  }

  getButtonTooltipText(option: IdentityProviderOption, isLinked: boolean, user: CpUser): string {
    let result = option.tooltip + '.\n\n';
    if (isLinked) {
      result += isPrimaryUserAccount(user.id, option.provider)
        ? 'This is your primary account and cannot be unlinked.'
        : 'Click to forget this account.';
    } else {
      result += 'Click to link your account.';
    }
    return result;
  }

  async handleLinkButtonClick(user: CpUser, option: IdentityProviderOption): Promise<void> {
    if (isPrimaryUserAccount(user.id, option.provider)) {
      this.snackBar.warning('Primary account cannot be unlinked');
      return;
    }
    if (this.checkAccountIsLinked(option, user)) {
      this.unlinkUserAccount(option.provider);
      return;
    }
    this.initiateUserAccountLinking();
  }

  private unlinkUserAccount(type: Auth0IdentityProviderType): void {
    this.globalUiService.showConfirmationDialog(
      'Unlink Account',
      [
        'Are you sure you want to unlink this account?',
        'Once this account is unlinked, you will no longer be able to use it.',
      ],
      'Unlink',
      async () => {
        try {
          await callBackendExecutable(this.squid, 'unlinkUserAccount', type);
          this.snackBar.success('User account was unlinked');
        } catch (error) {
          console.error('Failed to unlink user account', error);
          this.snackBar.warning('Failed to unlink the account. Please try again later or contact support.');
        }
      },
    );
  }

  private initiateUserAccountLinking(): void {
    this.globalUiService.showConfirmationDialog(
      'Link Account',
      [
        'To link a new account, first log in with the desired identity provider (Password, Google, or GitHub).',
        'Your current account will be linked after logging in with the new provider.',
      ],
      'Link Account',
      async () => {
        this.accountService.logout();
      },
    );
  }
}

interface IdentityProviderOption {
  provider: Auth0IdentityProviderType;
  label: string;
  tooltip: string;
}
