import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Chart } from '@squidcloud/console-web/app/metric/chart/chart.types';

/**
 * A component that makes ngx charts to load lazily (deferred).
 * Currently, uses an old-style lazy loading pattern for Angular.
 *
 * Note: To use it with a '@deferred {}' and remove the boilerplate code we either need to wait until next
 * Angular release or convert Squid Console app to be started as a standalone component itself, instead of NgModule.
 */
@Component({
  selector: 'app-chart',
  template: '<ng-container #chartContainer></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartLazyLoadedComponent implements OnInit, OnChanges {
  @Input({ required: true }) chart!: Chart;

  @ViewChild('chartContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

  private componentRef?: ComponentRef<{ chart: Chart }>;

  ngOnInit(): void {
    void this.loadNgxChart();
  }

  ngOnChanges(): void {
    this.updateInputs();
  }

  private updateInputs(): void {
    if (this.componentRef) {
      this.componentRef.instance.chart = this.chart;
      this.componentRef.injector.get(ChangeDetectorRef).markForCheck();
    }
  }

  async loadNgxChart(): Promise<void> {
    const chartComponent = await import('./chart.component');
    this.componentRef = this.container.createComponent(chartComponent.ChartComponent);
    this.updateInputs();
  }
}
