import { Injectable } from '@angular/core';
import { Squid } from '@squidcloud/client';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { SnackBarService } from '../../global/services/snack-bar.service';
import { IntegrationService } from '../integration.service';
import { Modifications } from './utils/modifications';
import { truthy } from 'assertic';
import { CpIntegration } from '@squidcloud/console-common/types/application.types';
import { IntegrationSchema } from '@squidcloud/internal-common/types/integrations/schemas';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseSchemaService<I extends CpIntegration, S extends IntegrationSchema> {
  protected readonly schemaSubject = new BehaviorSubject<S | undefined>(undefined);
  protected readonly modifications: Modifications = new Modifications();
  protected integration: I | undefined;

  private subscription: Subscription | undefined;

  protected constructor(
    protected readonly applicationService: ApplicationService,
    protected readonly integrationService: IntegrationService,
    protected readonly snackBar: SnackBarService,
    protected readonly squid: Squid,
  ) {}

  async initializeSchema(integration: I): Promise<void> {
    this.integration = integration;

    let schema: S | undefined;
    this.schemaSubject.next(undefined);
    this.modifications.clear();

    if (this.subscription) this.subscription.unsubscribe();

    schema = await this.fetchSchema();

    // Subscribe to changes to the integration, and update the schema subject
    // whenever something changes.
    this.subscription = this.integrationService.observeIntegration(this.integration.id).subscribe(async integration => {
      if (integration) {
        schema = await this.fetchSchema();
        if (schema) {
          this.schemaSubject.next(schema);
        }
      }
    });

    if (schema) {
      this.schemaSubject.next(schema);
      return;
    }

    // Automatically try to discover the schema if it does not exist.
    try {
      await this.discoverSchema(true);
    } catch (e) {
      this.snackBar.warning('Unable to discover schema, please try again later');
      console.error('Unable to discover schema for integration: ', integration, e);
      this.schemaSubject.next(this.getEmptySchema());
    }
  }

  observeModifications(): Observable<Modifications> {
    return this.modifications.subject.asObservable();
  }

  getModifications(): Modifications {
    return this.modifications.subject.value;
  }

  observeSchema(): Observable<S | undefined> {
    return this.schemaSubject.asObservable();
  }

  getSchemaOrFail(): S {
    return truthy(this.schemaSubject.value, 'NO_SCHEMA');
  }

  async saveSchema(): Promise<void> {
    await this.upsertSchema();
    this.modifications.clear();
  }

  async upsertSchema(): Promise<void> {
    const application = this.applicationService.getCurrentApplicationOrFail();
    await callBackendExecutable(this.squid, 'upsertIntegrationSchema', {
      organizationId: application.organizationId,
      applicationId: application.appId,
      integrationId: truthy(this.integration?.id),
      upsert: this.getSchemaOrFail(),
    });
  }

  private async fetchSchema(): Promise<S | undefined> {
    const schema = await callBackendExecutable(this.squid, 'getSchemaWithHiddenFields', {
      appId: this.applicationService.getCurrentApplicationOrFail().appId,
      integrationId: truthy(this.integration?.id),
    });
    return schema?.schema;
  }

  protected getSchema(): S {
    return truthy(this.schemaSubject.getValue(), 'No schema found');
  }
  /**
   * Discovers the schema and returns it.
   * @param autosave Determines whether the schema should be saved to the server if discovered successfully. Only the
   *                 API integration has this feature implemented (no other integration types will autosave).
   */
  abstract discoverSchema(autosave: boolean): Promise<S | undefined>;

  abstract getEmptySchema(): S;
}
