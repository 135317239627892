import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import { QuotaName } from '@squidcloud/internal-common/quota.types';
import { ContactUsDialogComponent } from '@squidcloud/console-web/app/global/components/contact-us-dialog/contact-us-dialog.component';

export interface QuotedActionDialogComponentData {
  quotaName: QuotaName;
}

/** The dialog used to show "Quota reached" message to user .*/
@Component({
  selector: 'app-quoted-action-dialog',
  templateUrl: './quoted-action-dialog.component.html',
  styleUrl: './quoted-action-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotedActionDialogComponent {
  readonly organizationId: string;
  readonly quotedEntityName: string;

  constructor(
    organizationService: OrganizationService,
    private readonly dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: QuotedActionDialogComponentData,
  ) {
    this.organizationId = organizationService.getCurrentOrganizationOrFail().id;
    switch (data.quotaName) {
      case 'maxNumberOfApplications':
        this.quotedEntityName = 'application';
        break;
      case 'maxNumberOfIntegrations':
        this.quotedEntityName = 'integration';
        break;
      default:
        this.quotedEntityName = 'resource';
    }
  }

  static show(dialog: MatDialog, data: QuotedActionDialogComponentData): MatDialogRef<void> {
    return dialog.open<void>(QuotedActionDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'modal',
      data,
    });
  }

  showContactUsDialog(): void {
    ContactUsDialogComponent.show(this.dialog, { reason: 'billing_or_quota' });
  }
}
