import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { assertTruthy } from 'assertic';
import { Environment, getSquidClouds, SquidCloudId, SquidCloud } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';

interface CreateApplicationFormValue {
  name: string;
  region: Environment;
  selectedCloudId: SquidCloudId;
}

@Component({
  selector: 'create-application-form',
  templateUrl: './create-application-form.component.html',
  styleUrls: ['./create-application-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateApplicationFormComponent {
  @Output()
  readonly formSubmitted = new EventEmitter<void>();
  readonly defaultAppName = 'My App';
  readonly squidSupportedClouds: Array<SquidCloud>;
  selectedCloud: SquidCloud;
  readonly form: FormGroup;
  private readonly region: FormControl<Environment>;

  constructor(
    private readonly formBuilder: FormBuilder,
    accountService: AccountService,
    organizationService: OrganizationService,
  ) {
    this.squidSupportedClouds = getSquidClouds(
      environment.stage,
      accountService.getUserOrFail(),
      // Current organization can be undefined here if this is an onboarding process.
      organizationService.getCurrentOrganization(),
    );
    this.selectedCloud = this.squidSupportedClouds[0];
    this.region = new FormControl(this.selectedCloud.environments[0], { nonNullable: true });
    this.region.addValidators(Validators.required);
    this.form = this.formBuilder.group({
      name: new FormControl('', []),
      region: this.region,
    });
  }

  switchCloud(cloud: SquidCloud): void {
    if (!cloud.environments.length) return;
    this.selectedCloud = cloud;
    this.region.setValue(this.selectedCloud.environments[0]);
  }

  emitFormSubmitted(): void {
    if (this.form.invalid) return;
    this.formSubmitted.emit();
  }

  getFormDetails(): CreateApplicationFormValue {
    assertTruthy(this.form.valid, 'INVALID_FORM');
    const formDetails = this.form.value;
    const name = formDetails.name?.trim() || this.defaultAppName;
    return {
      name,
      region: formDetails.region,
      selectedCloudId: this.selectedCloud.id,
    };
  }
}
