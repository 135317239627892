/** @internal */
export function binarySearch<T>(
  arr: T[],
  key: T,
  comparator = (a: T, b: T): number => (a > b ? 1 : a < b ? -1 : 0) as number,
  low = 0,
  high = arr.length - 1,
): number {
  if (high < low) return -1;
  const mid = Math.trunc((low + high) / 2);
  if (comparator(key, arr[mid]) === 0) return mid;
  if (comparator(key, arr[mid]) > 0) return binarySearch(arr, key, comparator, mid + 1, high);
  return binarySearch(arr, key, comparator, low, mid - 1);
}

/** @internal */
export function insertSorted<T>(
  arr: T[],
  key: T,
  comparator = (a: T, b: T): number => (a > b ? 1 : a < b ? -1 : 0) as number,
): void {
  if (binarySearch(arr, key, comparator) !== -1) {
    return;
  }
  const len = arr.length;
  let i;
  for (i = len - 1; i >= 0 && comparator(arr[i], key) > 0; i--) arr[i + 1] = arr[i];

  arr[i + 1] = key;
}

/** @internal */
export function removeSorted<T>(
  arr: T[],
  key: T,
  comparator = (a: T, b: T): number => (a > b ? 1 : a < b ? -1 : 0) as number,
): void {
  const index = binarySearch(arr, key, comparator);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

/** @internal */
export interface Grouped<T> {
  [key: string]: T[];
}

/** @internal */
export async function asyncGroupBy<T>(arr: T[], groupNamer: (element: T) => Promise<string>): Promise<Grouped<T>> {
  const groups: Grouped<T> = {};
  for (const element of arr) {
    const key = await groupNamer(element);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(element);
  }
  return groups;
}

/** @internal */
export const arrayMergeCustomizer = <T = unknown>(a: T | Array<T>, b: T | Array<T>): Array<T> | undefined => {
  if (Array.isArray(a)) {
    return a.concat(b);
  } else {
    return undefined;
  }
};
