/** A transactionId - alias for string */
export type TransactionId = string;

/**
 * @internal
 */
export const NOOP_FN = (fn: () => any): any => {
  return fn();
};

export interface BlobAndFilename {
  blob: Blob;
  name: string;
}
