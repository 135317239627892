import { Injectable } from '@angular/core';
import { AuthGuard as Auth0AuthGuard, AuthService as Auth0Service } from '@auth0/auth0-angular';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter, map, Observable, of, switchMap } from 'rxjs';
import { StatusPageComponent } from '../components/status-page/status-page.component';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';

@Injectable({
  providedIn: 'root',
})
export class ConsoleAuthGuard extends Auth0AuthGuard {
  constructor(
    private readonly auth0Service: Auth0Service,
    private readonly accountService: AccountService,
  ) {
    super(auth0Service);
  }

  override canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { pathname, search } = window.location;
    const isAuth0InternalError = pathname === '/' && search.startsWith('?error=');
    if (!isAuth0InternalError) {
      return super.canActivate(next, state).pipe(
        switchMap(hasAuth0User => {
          if (!hasAuth0User) {
            return of(false);
          }
          // Wait until Console user is ready.
          // Otherwise, accountService.getUserOrFail() may return 'undefined' for a valid Auth0 session.
          return this.accountService.observeUser().pipe(
            filter(Boolean),
            map(() => true),
          );
        }),
      );
    }
    // Here is a special case with Auth0: the user is not allowed to proceed to Console because of some valid reason
    // (see 'throw new Error()' cases in auth0.tf).
    //
    // This can only happen if we have a bug or an inconsistent DB/Auth0 state.
    //
    // In this case Auth0 redirects the user back to Console with the error details and no access token provided.
    //
    // By default, Console redirects every non-authenticated session back to Auth0.
    //
    // As the result Auth0 may automatically log in the user, and run post-login rules/actions (like Account linking)
    // and redirect with `?error` to the console again. This way an endless redirection loop will happen.
    //
    // The code below breaks this loop by signing out the user from Auth0 and redirecting to the unprotected error page.
    console.error('Enforcing logout on unexpected error!');

    const urlParams = new URLSearchParams(search);
    const originalError = urlParams.get('error');
    const originalDescription = urlParams.get('error_description');

    // Translate internal error messages to a user-friendly text.
    const title = originalError === 'access_denied' ? 'Sign In Failed' : originalError || 'Error';
    const message = originalDescription?.startsWith('Found multiple users with the same email:')
      ? 'User with the same email is already registered'
      : originalDescription || 'Unexpected error';

    const redirectUrl = window.location.origin + StatusPageComponent.buildErrorPagePath(message, title);
    return this.auth0Service.logout({ logoutParams: { returnTo: redirectUrl } }).pipe(map(() => false));
  }
}
