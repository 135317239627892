import { BehaviorSubject, filter, map, Observable, skip } from 'rxjs';
import { DestructManager } from './destruct.manager';
import { getGlobal } from '../../internal-common/src/utils/global.utils';
import { generateId } from '../../internal-common/src/public-utils/id-utils';

import { ClientId } from '../../internal-common/src/public-types/communication.public-types';

import { isPlaywrightTestMode } from '../../internal-common/src/utils/e2e-test-utils';

/**
 * Whenever a squid client is created, it assigns itself a client id.
 * Later on, if the squid client disconnects for a specified time interval, it will generate itself a new client id.
 * The client id is generated before the socket is reconnected, so it is possible that the new client id is generated,
 * but the socket has not connected yet.
 *
 * Short-term disconnects/reconnects of the socket do not cause the client id to be regenerated.
 * @internal
 */
export class ClientIdService {
  private readonly clientTooOldSubject = new BehaviorSubject<boolean>(false);
  private readonly clientIdSubject: BehaviorSubject<ClientId>;
  private readonly isTenant: boolean;

  constructor(private readonly destructManager: DestructManager) {
    this.isTenant = getGlobal()['squidTenant'] === true;
    this.clientIdSubject = new BehaviorSubject<ClientId>(this.generateClientId());
    this.destructManager.onDestruct(() => {
      this.clientTooOldSubject.complete();
      this.clientIdSubject.complete();
    });
  }

  observeClientId(): Observable<ClientId> {
    return this.clientIdSubject;
  }

  observeClientTooOld(): Observable<void> {
    return this.clientTooOldSubject.pipe(
      filter(v => v),
      map(() => undefined),
    );
  }

  /**  there was a long-term disconnection of the socket */
  notifyClientTooOld(): void {
    this.clientTooOldSubject.next(true);
    this.clientIdSubject.next(this.generateClientId());
  }

  notifyClientNotTooOld(): void {
    this.clientTooOldSubject.next(false);
  }

  observeClientReadyToBeRegenerated(): Observable<void> {
    return this.clientTooOldSubject.pipe(
      // skip the initial connection
      skip(1),
      filter(v => !v),
      map(() => undefined),
    );
  }

  getClientId(): ClientId {
    return this.clientIdSubject.value;
  }

  isClientTooOld(): boolean {
    return this.clientTooOldSubject.value;
  }

  private generateClientId(): string {
    const clientIdGenerator = getGlobal()[CLIENT_ID_GENERATOR_KEY] as ClientIdGeneratorFunction | undefined;
    if (clientIdGenerator) {
      return clientIdGenerator();
    }
    let clientId = `${this.isTenant ? 'tenant-' : ''}${generateId()}`;
    if (isPlaywrightTestMode()) {
      clientId = `e2e${clientId.substring(3)}`;
    }
    return clientId;
  }

  generateClientRequestId(): string {
    const clientIdGenerator = getGlobal()[CLIENT_REQUEST_ID_GENERATOR_KEY] as
      | ClientRequestIdGeneratorFunction
      | undefined;
    return clientIdGenerator ? clientIdGenerator() : generateId();
  }
}

/** @internal. */
export const CLIENT_ID_GENERATOR_KEY = 'SQUID_CLIENT_ID_GENERATOR';

/** @internal. */
export const CLIENT_REQUEST_ID_GENERATOR_KEY = 'SQUID_CLIENT_REQUEST_ID_GENERATOR';

/** @internal. */
export type ClientIdGeneratorFunction = () => string;

/** @internal. */
export type ClientRequestIdGeneratorFunction = () => string;
