/*** Common components used by admin & app modules. */
import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoadingIndicatorComponent } from '@squidcloud/console-web/app/global/components/loading-indicator/loading-indicator.component';
import { PageHeaderComponent } from '@squidcloud/console-web/app/global/components/page-header/page-header.component';
import { MatCellDefTypedByDirective } from '@squidcloud/console-web/app/global/directives/mat-cell-def-typed-by.directive';
import { MatRowDefTypedByDirective } from '@squidcloud/console-web/app/global/directives/mat-row-def-typed-by.directive';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarMenuItemComponent } from '@squidcloud/console-web/app/global/components/sidebar-layout/sidebar-menu-item/sidebar-menu-item.component';
import { MatIconModule } from '@angular/material/icon';
import { DialogCloseButtonComponent } from '@squidcloud/console-web/app/global/components/dialog-close-button/dialog-close-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { HeaderUserDropdownComponent } from '@squidcloud/console-web/app/global/components/header-user-dropdown/header-user-dropdown.component';
import { AvatarComponent } from '@squidcloud/console-web/app/account/avatar/avatar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MagicFormComponent } from '@squidcloud/console-web/app/global/components/magic-form/magic-form.component';
import { LoadingIndicatorOverlayComponent } from '@squidcloud/console-web/app/global/components/loading-state-wrapper/loading-indicator-overlay.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FileUploadComponent } from '@squidcloud/console-web/app/global/components/magic-form/file-upload/file-upload.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SelectSecretComponent } from '@squidcloud/console-web/app/application/secrets/select-secret/select-secret.component';
import { provideAuth0ForSquid, provideRecaptcha, provideSquid } from '@squidcloud/console-web/app/utils/app-init-utils';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';
import { provideHttpClient } from '@angular/common/http';

const commonComponents = [
  AvatarComponent,
  DialogCloseButtonComponent,
  FileUploadComponent,
  HeaderUserDropdownComponent,
  LoadingIndicatorComponent,
  LoadingIndicatorOverlayComponent,
  MagicFormComponent,
  MatCellDefTypedByDirective,
  MatRowDefTypedByDirective,
  PageHeaderComponent,
  SelectSecretComponent,
  SidebarMenuItemComponent,
];

@NgModule({
  declarations: commonComponents,
  exports: commonComponents,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    MatTabsModule,
    MatTooltip,
    NgOptimizedImage,
    ReactiveFormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    RouterModule,
  ],
  providers: [provideAuth0ForSquid(), provideHttpClient(), provideSquid(), provideRecaptcha()],
})
export class ConsoleCommonModule {}
