import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CreateApplicationDialogComponent } from '@squidcloud/console-web/app/application/create-application-dialog/create-application-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'no-application-zero-state',
  templateUrl: './no-application-zero-state.component.html',
  styleUrls: ['./no-application-zero-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoApplicationZeroStateComponent {
  @Input({ required: true }) title!: string;

  @Input({ required: true }) description!: string;

  constructor(private readonly dialog: MatDialog) {}

  createApplication(): void {
    CreateApplicationDialogComponent.show(this.dialog);
  }
}
