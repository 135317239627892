<ng-container *ngIf="appObs | async as app">
  <ng-container *ngIf="apiKeySubject | async as apiKey">
    <ng-container *ngIf="userObs | async as user">
      <div class="onboarding_content">
        <div class="onboarding_getting-started">
          <h1>Copy &amp; Paste</h1>
          <div class="text">
            Create a new sample app and backend using the Squid CLI.
            Alternatively, you can start building your own product by exploring
            our
            <a class="link" href="https://docs.squid.cloud" target="_blank"
              >documentation</a
            >
          </div>

          <doc
            [markdownFileUrl]="'assets/docs/onboarding/getting_started.md'"
            [replaceableParams]="{
              apiKey,
              appName: getAppName(app.name),
              flow: flow,
              appId: getProdAppId(app.appId),
              environmentId: 'dev',
              squidDeveloperId: user.squidDeveloperId,
              region: getRegion(app)
            }"
          >
          </doc>
          <button
            (click)="handleDone()"
            class="primary_button extra_large_button"
            mat-flat-button
            data-testid="onboarding-done-button"
          >
            Done
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
