import { trigger, animate, transition, style, query, animateChild, group } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ],
      { optional: true },
    ),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(':leave', [style({ height: 0, overflow: 'hidden' }), animateChild()], { optional: true }),
    group([
      // query(':leave', [animate('0.2s ease-out', style({ opacity: 0 }))], {
      //   optional: true,
      // }),
      query(':enter', [style({ position: 'relative' }), animate('0.3s ease-in', style({ opacity: 1 }))], {
        optional: true,
      }),
      query('@*', animateChild(), { optional: true }),
    ]),
  ]),
]);
