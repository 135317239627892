import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { OrganizationService } from '../../organization/organization.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard implements CanActivateChild {
  constructor(
    private readonly organizationService: OrganizationService,
    private readonly router: Router,
  ) {}

  async canActivateChild(): Promise<boolean | UrlTree> {
    const organization = await firstValueFrom(this.organizationService.observeCurrentOrganization());
    // Redirect to the onboarding route if there's no current organization.
    return organization ? true : this.router.createUrlTree(['/onboarding', 'welcome']);
  }
}
