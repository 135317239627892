import { StatusCode, ValidationError } from './validation';
import { assertTruthy, truthy } from 'assertic';
import { HttpStatus } from '../public-types/http-status.enum';

/** @internal */
export function assertValidateTruthy(
  value: unknown,
  message: string,
  statusCode: StatusCode = HttpStatus.BAD_REQUEST,
  details?: Record<string, any>,
): asserts value {
  assertTruthy(value, () => new ValidationError(message, statusCode, details));
}

/** @internal */
export function validateTruthy<T>(
  value: T,
  message: string,
  statusCode: StatusCode = HttpStatus.BAD_REQUEST,
  details?: Record<string, any>,
): NonNullable<T> {
  return truthy(value, () => new ValidationError(message, statusCode, details));
}
