import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentReference, Squid } from '@squidcloud/client';
import { CpUserId } from '@squidcloud/console-common/types/account.types';
import {
  InvitationKey,
  Organization,
  OrganizationContactInfo,
  OrganizationDeletionErrorCode,
  OrganizationId,
  OrganizationRole,
} from '@squidcloud/console-common/types/organization.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { replaceInUrl } from '@squidcloud/console-web/app/global/utils/url';
import { waitForAsyncUpdate } from '@squidcloud/console-web/app/utils/squid-utils';
import { validateTruthy } from '@squidcloud/internal-common/utils/assert';
import { assertTruthy, truthy } from 'assertic';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { AccountService } from '../account/account.service';
import { LocalStorageService } from '../global/services/local-storage.service';
import { MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';
import { isMarketplaceBillingType } from '@squidcloud/console-common/types/billing.types';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { UserLockPageComponent } from '@squidcloud/console-web/app/global/components/user-lock-page/user-lock-page.component';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  private readonly currentOrganizationSubject = new BehaviorSubject<Organization | undefined | null>(undefined);
  private readonly organizationCollection = this.squid.collection<Organization>('organization');

  private isLoadingOrganizations$ = new BehaviorSubject<boolean>(false);

  private organizationsObs: Observable<Array<Organization> | undefined> = this.accountService.observeUser().pipe(
    map(cpUser => cpUser?.id),
    distinctUntilChanged(),
    switchMap((cpUserId: string | undefined) => {
      if (!cpUserId) return of(undefined);
      this.isLoadingOrganizations$.next(true);
      return this.organizationCollection
        .query()
        .neq(`members.${cpUserId}`, null)
        .dereference()
        .snapshots()
        .pipe(
          tap({
            next: () => this.isLoadingOrganizations$.next(false),
            error: () => this.isLoadingOrganizations$.next(false),
          }),
          map(orgList => orgList.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))),
          tap(orgs => {
            // Console web app access is not allowed for users with not-onboarded marketplace orgs.
            const hasOrgsThatRequireOnboarding = hasNotOnboardedOrgs(orgs);
            if (hasOrgsThatRequireOnboarding) {
              const { email } = this.accountService.getUserOrFail();
              const postLogoutRedirectUrl =
                window.location.origin + UserLockPageComponent.buildPagePath(email, 'marketplace');
              this.auth0Service.logout({ logoutParams: { returnTo: postLogoutRedirectUrl } });
            }
          }),
        );
    }),
    shareReplay(1),
  );

  private lastProcessedMpPurchaseEventTime = 0;

  constructor(
    private readonly accountService: AccountService,
    private readonly auth0Service: Auth0Service,
    private readonly squid: Squid,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {
    // Listen for updates to the user's organizations.
    this.organizationsObs.subscribe(async organizations => {
      if (!organizations) {
        return;
      }

      if (this.checkNewMarketplaceOrgRedirect(organizations)) {
        // There was a redirect to new MP org.
        return;
      }

      const currentOrganization = this.currentOrganizationSubject.value;
      if (!currentOrganization) {
        return;
      }
      const updatedOrganization = organizations.find(o => o.id === currentOrganization.id);
      if (!updatedOrganization) {
        // If the current organization is deleted, navigate back to the index route.
        await this.router.navigate(['']);
      } else {
        this.currentOrganizationSubject.next(updatedOrganization);
      }
    });
  }

  async switchOrganizationUrl(organizationId: OrganizationId): Promise<void> {
    if (organizationId === this.getCurrentOrganization()?.id) return;
    const currentUrl = this.router.url;

    if (!currentUrl.includes('/organization/')) {
      await this.router.navigate(['/organization', organizationId]);
    } else {
      const { url, queryParams } = replaceInUrl(this.router.url, { organization: organizationId });
      await this.router.navigate([url], { queryParams });
    }
  }

  async switchOrganization(organizationId: OrganizationId | undefined): Promise<void> {
    if (organizationId && this.currentOrganizationSubject.value?.id === organizationId) return;
    const organization = organizationId ? await this.refreshOrganization(organizationId) : null;
    this.localStorageService.setItem('currentOrganizationId', organizationId);
    this.currentOrganizationSubject.next(organization);
  }

  async refreshOrganization(orgId: string): Promise<Organization | null> {
    return (await this.organizationCollection.doc(orgId).snapshot()) || null;
  }

  async createOrganization(name: string): Promise<OrganizationId> {
    const organizationId = await callBackendExecutable(this.squid, 'createOrganization', name);
    await waitForAsyncUpdate(
      this.observeOrganizations().pipe(filter(orgs => !!orgs?.some(org => org.id === organizationId))),
      'createOrganization',
    );
    return organizationId;
  }

  async updateOrganizationName(organizationId: OrganizationId, name: string): Promise<void> {
    const nameToSearch = name.toLowerCase().trim();
    const currentOrg = this.getCurrentOrganizationOrFail();
    if (currentOrg.id !== organizationId) {
      const allOrganizations = await firstValueFrom(this.observeOrganizations());
      const foundOrg = allOrganizations?.find(
        org => org.name.toLowerCase().trim() === nameToSearch && org.id !== currentOrg.id,
      );
      validateTruthy(!foundOrg, 'ORG_ALREADY_EXISTS');
    }
    this.organizationCollection.doc(organizationId).update({ name }).then();
  }

  observeRoleInCurrentOrg(): Observable<OrganizationRole> {
    return this.observeCurrentOrganization().pipe(
      filter(Boolean),
      map(currentOrganization => {
        const user = this.accountService.getUserOrFail();
        return truthy(currentOrganization.members[user.id], 'MEMBER_NOT_FOUND').role;
      }),
    );
  }

  getMyRoleInCurrentOrg(): OrganizationRole {
    return truthy(this.getMyRoleInCurrentOrgOrUndefined(), 'ORG_NOT_SELECTED');
  }

  getMyRoleInCurrentOrgOrUndefined(): OrganizationRole {
    const user = this.accountService.getUserOrFail();
    return this.getCurrentOrganizationOrFail().members?.[user.id]?.role;
  }

  async deleteCurrentOrganization(): Promise<OrganizationDeletionErrorCode | null> {
    this.verifyAdminPermissions();
    const doc = this.getCurrentOrgDoc();
    return callBackendExecutable(this.squid, 'deleteOrganization', doc.data.id);
  }

  /** Members */
  deleteMember(userId: CpUserId): void {
    const user = this.accountService.getUserOrFail();
    if (user.id === userId) return this.leaveCurrentOrganization();
    this.verifyAdminPermissions();
    const doc = this.getCurrentOrgDoc();
    doc.deleteInPath(`members.${userId}`).then();
  }

  leaveCurrentOrganization(): void {
    const user = this.accountService.getUserOrFail();
    const currentRole = this.getMyRoleInCurrentOrg();
    if (currentRole === 'ADMIN') {
      const orgAdmins = Object.values(this.getCurrentOrganizationOrFail().members).filter(
        member => member.role === 'ADMIN',
      );
      assertTruthy(orgAdmins.length > 1);
    }
    const doc = this.getCurrentOrgDoc();
    doc.deleteInPath(`members.${user.id}`).then();
  }

  changeRole(userId: CpUserId, newRole: OrganizationRole): void {
    this.verifyAdminPermissions();
    const user = this.accountService.getUserOrFail();
    truthy(userId !== user.id, 'CANNOT_CHANGE_MY_ROLE');
    const doc = this.getCurrentOrgDoc();
    doc.setInPath(`members.${userId}.role`, newRole).then();
  }

  /** Invitations */
  async createInvitation(email: string, role: OrganizationRole): Promise<void> {
    const org = this.getCurrentOrganizationOrFail();
    await callBackendExecutable(this.squid, 'createInvitation', { organizationId: org.id, email, role });
  }

  resendInvitation(invitationKey: InvitationKey): void {
    this.verifyAdminPermissions();
    const org = this.getCurrentOrganizationOrFail();
    validateTruthy(
      Object.values(org.invitations || {}).find(m => m.invitationKey === invitationKey),
      'INVITATION_NOT_FOUND',
    );
    // TODO: add error handling: show error to a client.
    callBackendExecutable(this.squid, 'resendInvitation', { organizationId: org.id, invitationKey }).then();
  }

  deleteInvitation(invitationKey: InvitationKey): void {
    this.verifyAdminPermissions();
    const doc = this.getCurrentOrgDoc();
    doc.deleteInPath(`invitations.${invitationKey}`).then();
  }

  async acceptInvitation(invitationKey: InvitationKey): Promise<OrganizationId> {
    return callBackendExecutable(this.squid, 'acceptInvitation', invitationKey);
  }

  getCurrentOrganization(): Organization | undefined {
    return this.currentOrganizationSubject.value ?? undefined;
  }

  getCurrentOrganizationOrFail(): Organization {
    return truthy(this.getCurrentOrganization(), 'ORG_NOT_SELECTED');
  }

  async getAllOrganizations(): Promise<Array<Organization>> {
    await firstValueFrom(this.isLoadingOrganizations$.pipe(filter(l => !l)));
    return (await firstValueFrom(this.organizationsObs)) || [];
  }

  observeOrganizations(): Observable<Array<Organization> | undefined> {
    return this.organizationsObs;
  }

  observeCurrentOrganization(): Observable<Organization | undefined> {
    return this.currentOrganizationSubject.pipe(
      filter(org => org !== undefined),
      map(org => org || undefined),
    );
  }

  get currentOrganization$(): Observable<Organization | undefined> {
    return this.observeCurrentOrganization();
  }

  private getCurrentOrgDoc(): DocumentReference<Organization> {
    const currentOrganization = this.getCurrentOrganizationOrFail();
    return this.organizationCollection.doc(currentOrganization.id);
  }

  private verifyAdminPermissions(): void {
    const role = this.getMyRoleInCurrentOrg();
    assertTruthy(role === 'ADMIN', 'PERMISSION_DENIED');
  }

  /**
   * Checks if the user already has an organization
   * and creates it if the user has no organization.
   * Sets the newly created org as current for the SPA session.
   */
  async createFirstUserOrganizationIfNotExists(): Promise<void> {
    const org = await firstValueFrom(this.observeCurrentOrganization());
    if (!org) {
      const orgId = await this.createOrganization('My Organization');
      await this.switchOrganization(orgId);
    }
  }

  /**
   * Redirects to a marketplace billed org that may be created silently (with no UI interaction) on every login.
   * Does the redirect only within 20 seconds after marketplace purchase activation.
   * Returns true if redirect was done and false otherwise.
   */
  private checkNewMarketplaceOrgRedirect(organizations: Array<Organization>): boolean {
    const mpRedirectTimeout = 20 * MILLIS_PER_SECOND;
    const { mpPurchaseActivationEventTime } = this.accountService;
    const isRedirectActiveTimeWindow = mpPurchaseActivationEventTime + mpRedirectTimeout > Date.now();
    const isActivationProcessed = this.lastProcessedMpPurchaseEventTime >= mpPurchaseActivationEventTime;
    if (isRedirectActiveTimeWindow && !isActivationProcessed) {
      const newestMpOrg = findNewestMarketplaceBilledOrganization(organizations);
      if (newestMpOrg) {
        const isCreatedWithinRedirectTimeout = newestMpOrg.creationDate.getTime() + mpRedirectTimeout > Date.now();
        if (isCreatedWithinRedirectTimeout) {
          this.lastProcessedMpPurchaseEventTime = mpPurchaseActivationEventTime;
          this.currentOrganizationSubject.next(newestMpOrg);
          return true;
        }
      }
    }
    return false;
  }

  async updateContactInfo(
    id: OrganizationId,
    contactInfo: OrganizationContactInfo,
    type: 'by-user' | 'by-sysadmin',
  ): Promise<void> {
    if (type === 'by-user') {
      await this.organizationCollection.doc(id).update({ contactInfo });
    } else {
      await this.organizationCollection.doc(id).update({ contactInfoBySysadmin: contactInfo });
      const doc = await this.organizationCollection.doc(id).snapshot();
      if (doc && !doc.contactInfo) {
        await this.organizationCollection.doc(id).update({ contactInfo });
      }
    }
  }

  async updateOnboardingStatus(id: OrganizationId, isOnboarded: boolean): Promise<void> {
    await this.organizationCollection.doc(id).update({ isOnboarded });
  }
}

function findNewestMarketplaceBilledOrganization(organizations: Array<Organization>): Organization | undefined {
  let result: Organization | undefined;
  for (const org of organizations) {
    if (isMarketplaceBillingType(org.billingType)) {
      if (result === undefined || result.creationDate < org.creationDate) {
        result = org;
      }
    }
  }
  return result;
}

const ONBOARDING_REQUIREMENT_START_DATE = new Date('2024-10-23');

/** Returns true if any of orgs in the list must be onboarded before use. */
function hasNotOnboardedOrgs(orgs: Array<Organization>): boolean {
  // Orgs created earlier that this date are whitelisted -> we didn't require onboarding before.
  return orgs.some(
    org =>
      isMarketplaceBillingType(org.billingType) &&
      !org.isOnboarded &&
      org.creationDate >= ONBOARDING_REQUIREMENT_START_DATE,
  );
}
