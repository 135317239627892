<div mat-dialog-title [ngClass]="{ no_text: !data.textLines.length }">
  {{ data.title }}
</div>

<mat-dialog-content class="dialog_with_form">
  <dialog-close-button></dialog-close-button>
  @for (text of data.textLines; track $index) {
    <div [innerHTML]="text" class="dialog_normal_text"></div>
  }
  <magic-form [data]="formConfig"></magic-form>
</mat-dialog-content>
