import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BillingService } from '../../billing.service';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom, timer } from 'rxjs';

@Component({
  selector: 'app-save-payment-details-dialog',
  templateUrl: './save-payment-details-dialog.component.html',
  styleUrls: ['./save-payment-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavePaymentDetailsDialogComponent implements OnInit {
  error?: string;
  requestInProgress = false;
  ready = false;

  constructor(
    private readonly billingService: BillingService,
    private readonly snackBarService: SnackBarService,
    private readonly matDialogRef: MatDialogRef<unknown>,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.billingService.mountSavePaymentDetailsElements();
    await firstValueFrom(timer(1000));
    this.ready = true;
    this.cdr.markForCheck();
  }

  static show(dialog: MatDialog): MatDialogRef<SavePaymentDetailsDialogComponent> {
    const config: MatDialogConfig = {
      maxWidth: '496px',
      width: '100%',
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'modal',
    };
    return dialog.open(SavePaymentDetailsDialogComponent, config);
  }

  async submitPaymentDetails(): Promise<void> {
    this.requestInProgress = true;
    try {
      this.error = await this.billingService.onDetailsSubmitted();
      if (!this.error) {
        this.snackBarService.open('Payment details saved');
        this.matDialogRef.close();
      }
    } finally {
      this.requestInProgress = false;
      this.cdr.markForCheck();
    }
  }
}
