@if (tasksObs | async; as tasks) {
  @let isExpanded = expandedObs | async ;
  <div class="quick_start_flyout_outer" [ngClass]="{expanded: isExpanded}">
    <div class="quick_start_flyout_inner">
      @if (getCounts(tasks); as counts) {
        <div class="header">
          <mat-icon class="close_button" svgIcon="close_icon" (click)="closeFlyout()"></mat-icon>
          <mat-icon class="tasks_rudder" [svgIcon]="getRudderImageSvg(counts)"></mat-icon>
          <div class="metadata">
            <div class="title" data-testid="quick-start-tasks-title">Quick Start Tasks</div>
            <div class="count">{{ counts.completed }} of {{ counts.total }} completed</div>
          </div>
        </div>
      }
      <div class="task_sections">
        @if (getNextTask(tasks); as nextTask) {
          <div class="section next_section">
            <div class="section_title">Next up</div>
            <div class="tasks">
              <quick-start-task [task]="nextTask"
                                taskDisplayType="next_task"
                                (markComplete)="markTaskComplete(nextTask)"
                                (startTask)="taskClicked(nextTask)">
              </quick-start-task>
            </div>
          </div>
        }
        @if (getOpenTasks(tasks); as openTasks) {
          @if (openTasks.length) {
            <div class="section open_tasks_section">
              <div class="section_title">Open tasks</div>
              <div class="tasks">
                @for (task of openTasks; track task.id) {
                  <quick-start-task [task]="task"
                                    (markComplete)="markTaskComplete(task)"
                                    (startTask)="taskClicked(task)"
                                    taskDisplayType="task" />
                }
              </div>
            </div>
          }
        }
        @if (getCompletedTasks(tasks); as completedTasks) {
          @if (completedTasks.length) {
            <div class="section completed_tasks_section">
              <div class="section_title">Completed Tasks</div>
              <div class="tasks">
                @for (task of completedTasks; track task.id) {
                  <quick-start-task [task]="task" taskDisplayType="completed_task" />
                }
              </div>
            </div>
          }
        }
      </div>
    </div>
  </div>
}
