<!-- Not external link -->
<a *ngIf="!isExternalLink"
   [ngClass]="{active: isActive, menu_collapsed: !isMenuExpanded}"
   [routerLink]="item.link && !isExternalLink ? item.link : null"
   (click)="onMenuItemClicked()"
   class="sidebar_menu_item"
   [attr.title]="isMenuExpanded ? undefined : item.text"
   [attr.data-testid]="'sidebar-menu-item-' + item.id"
>
  <mat-icon [svgIcon]="item.iconName" class="icon"></mat-icon>
  <div class="text">{{item.text}}</div>
</a>

<!-- External link -->
<a *ngIf="isExternalLink"
   [ngClass]="{menu_collapsed: !isMenuExpanded}"
   [attr.href]="isExternalLink ? item.link : null"
   [attr.target]="isExternalLink ? '_blank' : null"
   [attr.title]="isMenuExpanded ? undefined : item.text"
   class="sidebar_menu_item"
   [attr.data-testid]="'sidebar-menu-item-' + item.id"
>
  <mat-icon [svgIcon]="item.iconName" class="icon"></mat-icon>
  <div class="text">{{item.text}}</div>
</a>
