import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OnboardingService } from '@squidcloud/console-web/app/onboarding/onboarding.service';

@Component({
  selector: 'onboarding-step',
  templateUrl: './onboarding-step.component.html',
  styleUrl: './onboarding-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingStepComponent {
  constructor(readonly onboardingService: OnboardingService) {}

  getStep(number: [number, number]): string {
    return `${this.padZero(number[0])} of ${this.padZero(number[1])}`;
  }

  padZero(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }
}
