import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Squid } from '@squidcloud/client';
import { AccountService } from '../../../account/account.service';
import { ApplicationService } from '../../../application/application.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { OrganizationService } from '../../../organization/organization.service';
import { truthy } from 'assertic';
import { Feedback } from '@squidcloud/console-common/types/application.types';
import { generateId } from '@squidcloud/internal-common/public-utils/id-utils';

interface FormDetails {
  feedback: FormControl<string>;
}

@Component({
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDialogComponent {
  form = new FormGroup<FormDetails>({
    feedback: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    private readonly squid: Squid,
    private readonly accountService: AccountService,
    private readonly organizationService: OrganizationService,
    private readonly applicationService: ApplicationService,
    private readonly dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private readonly snackBar: SnackBarService,
  ) {}

  async submitFeedback(): Promise<void> {
    if (!this.form.valid) return;
    const feedback = truthy(this.form.value.feedback);
    const user = this.accountService.getUserOrFail();
    const organization = this.organizationService.getCurrentOrganizationOrFail();
    const application = this.applicationService.getCurrentApplication();

    const id = generateId();
    const request: Feedback = {
      id,
      appId: application?.appId,
      email: user.email,
      userId: user.id,
      organizationId: organization.id,
      feedback,
    };

    this.squid.collection('feedback').doc(id).insert(request).then();
    this.snackBar.success('Feedback submitted');
    this.dialogRef.close();
  }
}
