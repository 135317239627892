<div mat-dialog-title>Pricing Plans</div>

<mat-dialog-content class="billing_plans_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="subtitle">Start for free and explore the power of building with Squid</div>
  <div class="plans">
    @for (plan of billingPlansData; track plan.name) {
      <div class="plan">
        <div class="title_description_and_button">
          <div class="title_and_description">
            <div class="title_and_current_plan">
              <div class="title">{{ plan.name }}</div>
              <div class="your_current_plan"
                   *ngIf="(plan.standard && data.isOnStandardPlan) || (!plan.standard && !data.isOnStandardPlan)">
                <mat-icon class="check_icon" svgIcon="check_icon" />
                Your current plan
              </div>
            </div>
            <div class="description">{{ plan.description }}</div>
          </div>
          <div class="button_wrapper">
            <button class="large_icon_button secondary_icon_button" mat-flat-button
                    (click)="plan.expanded = !plan.expanded">
              <mat-icon class="icon" svgIcon="arrow_down" [ngClass]="{expanded: plan.expanded}"></mat-icon>
            </button>
          </div>
        </div>

        <ng-container *ngIf="plan.expanded">
          <div class="highlights_title">WHAT'S INCLUDED</div>
          <ul class="highlights">
            @for (highlight of plan.highlights; track highlight) {
              <li>{{ highlight }}</li>
            }
          </ul>

          <button class="contact_us_button primary_button xl_button" mat-flat-button (click)="showContactUsDialog()">
            Contact Us
          </button>

        </ng-container>
      </div>
    }
  </div>
</mat-dialog-content>
