import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmail } from 'assertic';
import { Squid } from '@squidcloud/client';
import { SnackBarService } from '../../services/snack-bar.service';
import { SendEmailVerificationLinkRequest } from '@squidcloud/console-common/types/account.types';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';

/**
 * This component is activated by a redirect made in the Auth0 "post-login" action for non-verified emails.
 * It shows an 'Email is not verified' page to a user using email address from URL parameters.
 * The component has a button to re-send an email verification message.
 */
@Component({
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationComponent {
  readonly email: string;
  readonly auth0Sub: string;
  isSendButtonDisabled = false;

  constructor(
    private readonly squid: Squid,
    router: Router,
    activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    private readonly snackbar: SnackBarService,
  ) {
    this.email = activatedRoute.snapshot.queryParams['email'];
    this.auth0Sub = activatedRoute.snapshot.queryParams['sub'] || '';
    if (!this.hasValidParameters) {
      // When invoked incorrectly, log a message to console, show an error to user
      // and fall back to the default application behavior.
      this.snackbar.warning('Invalid page parameters');
      console.warn('Invalid page parameters', activatedRoute.snapshot.queryParams);
      router.navigate(['/']).then();
    }
  }

  get hasValidParameters(): boolean {
    return isEmail(this.email) || this.auth0Sub.length > 0;
  }

  async resendEmailVerificationLink(): Promise<void> {
    try {
      this.isSendButtonDisabled = true;
      const request: SendEmailVerificationLinkRequest = { auth0Sub: this.auth0Sub };
      await callBackendExecutable(this.squid, 'sendEmailVerificationLink', request);
      this.snackbar.success('Email with a verification link was successfully sent');
    } catch (error) {
      console.error(error);
      // TODO: provide a detailed info why we can't send the email. It may be a rate limit error or a wrong user id.
      this.snackbar.warning('Failed to send email verification link');
    } finally {
      this.isSendButtonDisabled = false;
      this.cdr.markForCheck();
    }
  }
}
