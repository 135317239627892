<div *ngIf="bundleDataTableData as data" class="bundle_metadata_table_page">
  @if (bundleDataTableData.showPeriodSelector) {
    <div class="period_block">
      <label>Period:</label>
      <mat-select
        [(value)]="selectedPeriodType"
        (selectionChange)="onSelectedPeriodTypeChanged()"
        class="select_box gray_select"
        panelClass="select_box_panel"
      >
        @for (period of periods; track period.type) {
          <mat-option [value]="period.type">{{ period.name }}</mat-option>
        }
      </mat-select>
    </div>
  }
  @if (hasChartData) {
    <div class="charts_container">
      <app-chart [chart]="executionsChart!" class="chart"></app-chart>
      <app-chart [chart]="latencyChart!" class="chart"></app-chart>
      <app-chart [chart]="qpsChart!" class="chart"></app-chart>
    </div>
  }

  <table mat-table [dataSource]="sortedTableRows"
         matSort [matSortActive]="sort.active" [matSortDirection]="sort.direction"
         (matSortChange)="sortTableRows($event)"
         class="squid_mat_table bundle_data_table"
  >
    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames;"></tr>

    @for (column of columns; track i; let i = $index) {
      <ng-container matColumnDef="{{column.name}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header
            [ngClass]="column.align === 'right' ? 'align_right' : ''"
            [matTooltip]="column.tooltip"
            matTooltipPosition="above"
        >
          {{ column.name }}
        </th>
        <td mat-cell *matCellDef="let row; typedBy: tableRowType"
            [ngClass]="column.align === 'right' ? 'align_right' : ''">
          <a
            [ngClass]="[row.columns[i].link ? 'link' : '', row.columns[i].class || '']"
            [attr.href]="row.columns[i].link"
            [target]="row.columns[i].target ? row.columns[i].target : '_self'"
            [matTooltip]="row.columns[i].tooltip || ''">{{ row.columns[i].value }}
          </a>
        </td>
      </ng-container>
    }
  </table>
</div>
