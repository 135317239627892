<div class="injection_table_container">
  <schema-table-header
    [ngClass]="{'mb_24': fields.length}"
    title="Injections"
    icon="injection_icon"
    [isModified]="isModified"
    (add)="showFieldDialog()"
  />
  <ng-container *ngIf="fields.length">
    <div class="common_table injection_table">
      <div class="row header_row">
        <div class="column">Field Name</div>
        <div class="column">Location</div>
        <div class="column">Secret</div>
        <div class="column">Value</div>
      </div>
      @for (row of fields; track row.name) {
        <div class="row">
          <div class="column">{{ row.name }}</div>
          <div class="column">{{ row.location }}</div>
          <div class="column">
            <mat-icon *ngIf="row.secret" class="lock_icon" svgIcon="lock_icon"></mat-icon>
          </div>
          <div class="column">{{ row.value }}</div>
          <div class="column action_column">
            <schema-field-menu
              [element]="row.name"
              (edit)="showFieldDialog($event)"
              (delete)="showDeleteFieldDialog( $event)"
              (duplicate)="duplicateField($event)" />
          </div>
        </div>
      }
    </div>
  </ng-container>
</div>
