import { RpcManager } from './rpc.manager';
import { BlobAndFilename } from './types';
import {
  DocumentTextDataResponse,
  ExtractDataFromDocumentFileOptions,
} from '../../internal-common/src/public-types/extraction.public-types';

export class ExtractionClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async extractDataFromDocumentFile(
    file: File | BlobAndFilename,
    options?: ExtractDataFromDocumentFileOptions,
  ): Promise<DocumentTextDataResponse> {
    if (!options) {
      options = {};
    }
    return await this.rpcManager.post<DocumentTextDataResponse>(
      'documentExtraction/extractDataFromDocumentFile',
      options,
      [file],
    );
  }
}
