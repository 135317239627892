import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() title!: string;
  @Input() subtitle?: string;
  @Input() description?: string;
  @Input() image?: string;
  @Input() secondaryTitle?: string;
  @Input() secondaryDescription?: string;
  @Input() withBottomBorder = true;
  @Input() fullWidth = false;
}
