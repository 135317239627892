<div class="storylane-dialog" data-testid="storylane-dialog">
  <div class="storylane-dialog__container">
    <div class="storylane-dialog__viewport">
      <iframe class="sl-iframe" [src]="iframeSource" name="sl-iframe"></iframe>
    </div>
  </div>
  <a [href]="iframeSource" target="__blank"
    >Open in New window
    <mat-icon svgIcon="selection_foreground"></mat-icon>
  </a>
  <button (click)="close()" data-testid="storylane-close">
    <mat-icon svgIcon="close_icon"></mat-icon>
  </button>
</div>
