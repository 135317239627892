export function findAvailableDuplicateFieldName(properties: Record<string, unknown>, fieldName: string): string {
  const currentFieldNames = Object.keys(properties);
  const fieldNameToUse = fieldName.replace(/_copy([0-9]*)?$/g, '');
  let c = 0;
  while (true) {
    const potentialName = `${fieldNameToUse}_copy${c === 0 ? '' : `${c}`}`;
    c++;
    if (!currentFieldNames.includes(potentialName)) {
      return potentialName;
    }
  }
}
