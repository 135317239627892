<ng-container *ngIf="userObs | async as user">
  <ng-container *ngIf="appObs | async as app">
    <doc *ngIf="apiKeySubject | async as apiKey"
         [markdownFileUrl]="'assets/docs/application-overview/initialize-backend.md'"
         [replaceableParams]="{
            apiKey,
            backendName: getBackendName(app.name),
            appId: getProdAppId(app.appId),
            environmentId: 'dev',
            squidDeveloperId: user.squidDeveloperId,
            region: getRegion(app),
         }"
         data-testid="initialize-backend-tutorial-dialog"
    >
    </doc>
  </ng-container>
</ng-container>
