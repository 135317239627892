@let application = applicationService.currentApplication$ | async ;
<div class="functions_page">
  <div class="container">
    <page-header
      [title]="application?.name || 'No application'"
      subtitle="Backend"
      description="You can enhance the functionality and security of your application by writing backend code in Typescript that has access to necessary resources and can be tailored to your needs."
      [withBottomBorder]="!application">
    </page-header>

    @if (application) {
      <div class="tab_group_container">
        <mat-tab-group #tabGroup (selectedTabChange)="changeUrl(tabGroup.selectedIndex)"
                       [(selectedIndex)]="selectedIndex"
                       animationDuration="0ms"
                       color="primary"
                       disableRipple="true" mat-align-tabs="start"
                       mat-stretch-tabs="false">
          @for (tab of tabs; track tab.id) {
            <mat-tab>
              <ng-template matTabLabel>
                <div [attr.data-testid]="tab.id+'-tab-label'">{{ tab.name }}</div>
              </ng-template>
              <ng-template matTabContent>
                <ng-container *ngComponentOutlet="tab.component"></ng-container>
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>

        <!-- Show extra 'Docs' button when active tab shows a table with metrics. -->
        @if (backendService.activeBackendTabHasMetrics$ | async) {
          @if (tabs[selectedIndex]; as tab) {
            <div class="actions">
              <button mat-flat-button (click)="showDocDialog(tab.tutorialComponent)"
                      class="request_function_button secondary_button large_button"
              >
                {{ tab.name }} Docs
              </button>
            </div>
          }
        }
      </div>
    } @else {
      <no-application-zero-state title="Arrrr no backend yet..."
                                 description="Backend functions require an application, it looks like you do not have one yet.">
      </no-application-zero-state>
    }
  </div>
</div>
