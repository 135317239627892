import { IntegrationId, IntegrationInfo, IntegrationType } from './public-types';
import { RpcManager } from './rpc.manager';

export class IntegrationClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async list(type?: IntegrationType): Promise<IntegrationInfo[]> {
    const integrations = await this.rpcManager.post<IntegrationInfo[]>('integration/getIntegrations', {});
    return type ? integrations.filter(integration => integration.type === type) : integrations;
  }

  async get(id: IntegrationId): Promise<IntegrationInfo | undefined> {
    const integrations = await this.rpcManager.post<IntegrationInfo[]>('integration/getIntegrations', {});
    return integrations.find(integration => integration.id === id);
  }
}
