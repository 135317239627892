<ng-container *ngIf="appObs | async as app">
  <doc
    *ngIf="egressIpsObs | async as egressIps"
    [markdownFileUrl]="'assets/docs/application-overview/ip-addresses.md'"
    [replaceableParams]="{
         ingressHostname: getIngressHostname(app),
         egressIps: egressIps,
       }">
  </doc>
</ng-container>
