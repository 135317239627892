<div class="mini_bundle_metadata_table_container">
  <div class="title_container">
    <mat-icon [svgIcon]="data.iconName" class="icon"></mat-icon>
    <div class="title">{{ data.title }}</div>
  </div>

  <div [ngClass]="'grid_'+(data.headerRow.length + 1)" class="common_table">
    <div class="row header_row">
      @for (headerColumn of data.headerRow; track headerColumn) {
        <div class="column">{{ headerColumn }}</div>
      }
      <div class="column"></div>
    </div>
    @for (row of data.rows; track $index) {
      <div class="row">
        @for (column of row.columns; track column) {
          <div class="column">
            <div class="ellipsis">{{ column }}</div>
          </div>
        }
        <a *ngIf="row.link" [routerLink]="row.link" class="column action_column">
          <mat-icon class="link_icon" svgIcon="link_icon"></mat-icon>
        </a>
      </div>
    }
  </div>
</div>
