import { DocIdObj, DocumentData, FieldSort, Query, SquidDocId, SquidDocument } from './public-types';
import { isNonNullable, truthy } from 'assertic';
import { deserializeObj, normalizeJsonAsString } from '../../internal-common/src/utils/serialization';
import { cloneDeep, compareValues, getInPath, groupBy, replaceKeyInMap } from '../../internal-common/src/utils/object';
import { parseSquidDocId } from '../../internal-common/src/types/document.types';

export class DocumentStore {
  private readonly squidDocIdToDoc = new Map<SquidDocId, SquidDocument | undefined>();

  saveDocument(squidDocId: SquidDocId, properties: SquidDocument | undefined): SquidDocument | undefined {
    const doc = this.squidDocIdToDoc.get(squidDocId);

    if (doc === undefined && !properties) return undefined;
    if (doc !== undefined) {
      // Update
      if (properties) {
        const updateDoc = cloneDeep(properties);
        const data = this.removeInternalProperties(updateDoc);
        this.squidDocIdToDoc.set(squidDocId, data);
        return data;
      }

      // Delete
      this.squidDocIdToDoc.delete(squidDocId);
      return undefined;
    }

    // Insert
    const data = this.removeInternalProperties(properties);
    this.squidDocIdToDoc.set(squidDocId, data);
    return properties;
  }

  hasData(squidDocId: SquidDocId): boolean {
    const doc = this.squidDocIdToDoc.get(squidDocId);
    return doc !== undefined;
  }

  getDocument(squidDocId: SquidDocId): SquidDocument {
    return truthy(this.getDocumentOrUndefined(squidDocId));
  }

  getDocumentOrUndefined(squidDocId: SquidDocId): SquidDocument | undefined {
    return this.squidDocIdToDoc.get(squidDocId);
  }

  private compareSquidDocs(a: SquidDocument, b: SquidDocument, sortOrders: Array<FieldSort<any>>): number {
    for (const { fieldName, asc } of sortOrders) {
      const valueA = getInPath(a, fieldName);
      const valueB = getInPath(b, fieldName);
      const rc = compareValues(valueA, valueB);
      if (rc !== 0) {
        return asc ? rc : -rc;
      }
    }
    return 0;
  }

  group(sortedDocs: SquidDocument[], sortFieldNames: string[]): SquidDocument[][] {
    return Object.values(
      groupBy(sortedDocs, doc => normalizeJsonAsString(sortFieldNames.map(fieldName => getInPath(doc, fieldName)))),
    );
  }

  sortAndLimitDocs(docIdSet: Set<SquidDocId>, query: Query): Array<SquidDocument> {
    if (docIdSet.size === 0) {
      return [];
    }
    const docs = [...docIdSet].map(id => this.squidDocIdToDoc.get(id)).filter(isNonNullable);

    const { sortOrder, limitBy } = query;
    const sortedDocs = docs.sort((a, b) => this.compareSquidDocs(a, b, sortOrder));

    const mainLimit = query.limit < 0 ? 2000 : query.limit;

    if (!limitBy) {
      return sortedDocs.slice(0, mainLimit);
    }

    const { limit: internalLimit, fields, reverseSort } = limitBy;
    const sortedGroups = this.group(sortedDocs, fields);
    let limitedGroups: SquidDocument[][];
    if (reverseSort) {
      limitedGroups = sortedGroups.map(group => group.slice(-internalLimit));
    } else {
      limitedGroups = sortedGroups.map(group => group.slice(0, internalLimit));
    }
    return limitedGroups.flat().slice(0, mainLimit);
  }

  private removeInternalProperties(doc: SquidDocument | undefined): SquidDocument | undefined {
    if (!doc) return undefined;
    const data: DocumentData = { ...doc };
    delete data['__ts__'];
    return data as SquidDocument;
  }

  migrateDocId(squidDocId: SquidDocId, newSquidDocId: SquidDocId): void {
    const doc = this.getDocumentOrUndefined(squidDocId);
    if (!doc) return;

    replaceKeyInMap(this.squidDocIdToDoc, squidDocId, newSquidDocId);
    const newSquidDocIdObj = parseSquidDocId(newSquidDocId);
    const docIdObj: DocIdObj = deserializeObj(newSquidDocIdObj.docId);

    this.saveDocument(newSquidDocId, { ...doc, ...docIdObj, __docId__: newSquidDocIdObj.docId });
  }
}
