import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { LogEntry } from '../logs.types';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { copy } from 'clipboard';
import { SnackBarService } from '../../../global/services/snack-bar.service';

export interface LogEntryDialogData {
  logEntry: LogEntry;
}

@Component({
  templateUrl: './log-entry-dialog.component.html',
  styleUrls: ['./log-entry-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogEntryDialogComponent {
  protected readonly logEntry: LogEntry;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LogEntryDialogData,
    private readonly snackBarService: SnackBarService,
  ) {
    this.logEntry = data.logEntry;
  }

  copyLogMessage(): void {
    copy(this.logEntry.message);
    this.snackBarService.success('Log message copied');
  }

  getLogMessageAndTags(logEntry: LogEntry): string {
    if (logEntry.tags) {
      const tags = Object.keys(logEntry.tags).map(key => `${key}: ${logEntry.tags[key]}`);
      return `${logEntry.message}\n\nTags:\n${tags.join('\n')}`;
    }
    return logEntry.message;
  }
}
