import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IntrospectionQuery } from 'graphql';

/**
 * A component that makes ngx charts to load lazily (deferred).
 * Currently, uses an old-style lazy loading pattern for Angular.
 *
 * Note: To use it with a '@deferred {}' and remove the boilerplate code we either need to wait until next
 * Angular release or convert Squid Console app to be started as a standalone component itself, instead of NgModule.
 */
@Component({
  selector: 'react-graphiql',
  template: '<ng-container #reactGraphIqlContainer></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactGraphiqlLazyLoadedComponent implements OnInit, OnChanges {
  @Input({ required: true }) url!: string;
  @Input({ required: true }) headers!: Record<string, string>;
  @Input({ required: true }) integrationId!: string;
  @Input({ required: true }) schema!: IntrospectionQuery;

  @ViewChild('reactGraphIqlContainer', { read: ViewContainerRef, static: true }) container!: ViewContainerRef;

  private componentRef?: ComponentRef<{
    url: string;
    headers: Record<string, string>;
    integrationId: string;
    schema: IntrospectionQuery;
  }>;

  ngOnInit(): void {
    void this.loadComponent();
  }

  ngOnChanges(): void {
    this.updateInputs();
  }

  private updateInputs(): void {
    if (this.componentRef) {
      this.componentRef.instance.url = this.url;
      this.componentRef.instance.headers = this.headers;
      this.componentRef.instance.integrationId = this.integrationId;
      this.componentRef.instance.schema = this.schema;
      this.componentRef.injector.get(ChangeDetectorRef).markForCheck();
    }
  }

  async loadComponent(): Promise<void> {
    const component = await import('./react-graphiql.component');
    this.componentRef = this.container.createComponent(component.ReactGraphiqlComponent);
    this.updateInputs();
  }
}
