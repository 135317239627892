@if (isLoading && isInitialDelayWaitFinished) {
  <div class="indicator" [ngClass]="{
    white: color === 'white',
    blue: color === 'blue'
  }"
  ></div>
}

<div [ngClass]="{hidden_content: isContentHidden }">
  <ng-content></ng-content>
</div>

