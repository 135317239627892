import { OnboardingFlowId } from '@squidcloud/console-web/app/onboarding/onboarding.types';

export type OnboardingCard = {
  id: OnboardingFlowId;
  title: string;
  description: string;
};

export const ONBOARDING_CARDS: Record<OnboardingFlowId, OnboardingCard> = {
  'archer-lite': {
    id: 'archer-lite',
    title: 'I want to take a tour of Squid Cloud’s features.',
    description:
      'Check out Archer, a stock portfolio app built using Squid. Discover features of Squid while interacting with the web app.',
  },
  widget: {
    id: 'widget',
    title: 'I want to build an expert AI agent',
    description: 'Create an expert AI agent in just 3 easy steps, no code required. Add it to your website in minutes.',
  },
  todo: {
    id: 'todo',
    title: 'I want to build a full-stack web app',
    description:
      'Build an interactive, real-time app that seamlessly connects to a database and AI functions with a lightweight, easy to use backend.',
  },
  starter: {
    id: 'starter',
    title: 'I want to build with an empty starter app',
    description: 'Generate a React frontend and a Squid backend. A perfect option for hackathons and PoC applications!',
  },
};
