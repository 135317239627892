<div mat-dialog-title>Log entry</div>

<mat-dialog-content class="log_entry_dialog">
  <dialog-close-button></dialog-close-button>

  <div class="logs_table">
    <div class="row header_row">
      <div class="cell">Timestamp</div>
    </div>

    <div class="row">
      <div class="cell timestamp_cell">
        {{ logEntry.timestamp | date: 'medium' }}
      </div>
    </div>
  </div>

  <div class="log_message_container">
    <textarea class="log_message" readonly>{{ getLogTags(logEntry) }}</textarea>
  </div>
</mat-dialog-content>
