import { RpcManager } from './rpc.manager';
import { IntegrationId } from '../../internal-common/src/public-types/communication.public-types';
import { AiContextMetadata } from '../../internal-common/src/public-types/ai-chatbot.public-types';
import { DocumentTextDataResponse } from '../../internal-common/src/public-types/extraction.public-types';

interface PsConfig {
  integrationId: string;
  identifier: string;
}

interface PsSaveAuthCodeRequest {
  authCode: string;
  personalStorageConfig: PsConfig;
}

interface PsGetAccessTokenRequest {
  personalStorageConfig: PsConfig;
}

interface PsGetAccessTokenResponse {
  accessToken: string;
  expirationTime: Date;
}

interface PsIndexDocumentAiConfig {
  integrationId: string;
  profileId: string;
}

interface PsIndexDocumentOrFolderRequest {
  documentOrFolderId: string;
  metadata?: AiContextMetadata;
  personalStorageConfig: PsConfig;
  aiConfig: PsIndexDocumentAiConfig;
}

interface PsUnindexDocumentRequest {
  documentId: string;
  personalStorageConfig: PsConfig;
  aiConfig: PsIndexDocumentAiConfig;
}

interface PsExtractDataFromDocumentRequest {
  documentId: string;
  personalStorageConfig: PsConfig;
}

/**
 * Client to handle personal storage integrations such as Google Drive or Microsoft OneDrive.
 * Provides methods for saving authentication tokens, indexing documents or folders for AI processing,
 * and unindexing documents from AI processing.
 */
export class PersonalStorageClient {
  /**
   * Constructs a new PersonalStorageClient instance.
   *
   * @param integrationId - The unique ID of the integration (e.g., Google Drive, Microsoft OneDrive).
   * @param rpcManager - The RPC manager responsible for making API calls.
   * @internal
   */
  constructor(
    private readonly integrationId: IntegrationId,
    private readonly rpcManager: RpcManager,
  ) {}

  /**
   * Saves an authentication token for the personal storage service.
   *
   * @param authCode - The authorization code obtained from the OAuth flow for the personal storage service.
   * @param identifier - A user-provided identifier (usually a user ID) that will be associated with the token.
   * @returns A promise that resolves with the access token and expiration time.
   */
  async saveAuthCode(authCode: string, identifier: string): Promise<PsGetAccessTokenResponse> {
    const request: PsSaveAuthCodeRequest = {
      authCode,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<PsGetAccessTokenResponse>('personalStorage/saveAuthCode', request);
  }

  /**
   * Retrieves an access token for the personal storage service.
   *
   * @param identifier - A user-provided identifier (usually a user ID) that is associated with the token.
   * @returns A promise that resolves with the access token and expiration time.
   */
  async getAccessToken(identifier: string): Promise<PsGetAccessTokenResponse> {
    const request: PsGetAccessTokenRequest = {
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<PsGetAccessTokenResponse>('personalStorage/getAccessToken', request);
  }

  /**
   * Extracts text data from a document.
   * @param documentId - The ID of the document to extract text data from.
   * @param identifier - A user-provided identifier (usually a user ID) that is associated with the document.
   * @returns A promise that resolves with the extracted text data.
   */
  async extractDataFromDocument(documentId: string, identifier: string): Promise<DocumentTextDataResponse> {
    const request: PsExtractDataFromDocumentRequest = {
      documentId,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
    };
    return await this.rpcManager.post<DocumentTextDataResponse>('personalStorage/extractDataFromDocument', request);
  }

  /**
   * Indexes a document or folder for AI processing.
   *
   * @param documentOrFolderId - The ID of the document or folder to be indexed.
   * @param identifier - A user-provided identifier (usually a user ID) that will be associated with the document.
   * @param aiIntegrationId - The integration ID for the AI processing configuration.
   * @param aiProfileId - The profile ID for the AI processing configuration.
   * @param metadata - Metadata to include with the index
   * @returns A promise that resolves when the document or folder is successfully indexed.
   */
  async indexDocumentOrFolder(
    documentOrFolderId: string,
    identifier: string,
    aiIntegrationId: string,
    aiProfileId: string,
    metadata?: AiContextMetadata,
  ): Promise<void> {
    const request: PsIndexDocumentOrFolderRequest = {
      documentOrFolderId,
      metadata,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
      aiConfig: {
        profileId: aiProfileId,
        integrationId: aiIntegrationId,
      },
    };
    await this.rpcManager.post<void>('personalStorage/indexDocumentOrFolder', request);
  }

  /**
   * Unindexes a previously indexed document, removing it from AI processing.
   *
   * @param documentId - The ID of the document to be unindexed.
   * @param identifier - A user-provided identifier (usually a user ID) that was associated with the document.
   * @param aiProfileId - The profile ID for the AI processing configuration.
   * @param aiIntegrationId - The integration ID for the AI processing configuration.
   * @returns A promise that resolves when the document is successfully unindexed.
   */
  async unindexDocument(
    documentId: string,
    identifier: string,
    aiProfileId: string,
    aiIntegrationId: string,
  ): Promise<void> {
    const request: PsUnindexDocumentRequest = {
      documentId,
      personalStorageConfig: {
        identifier,
        integrationId: this.integrationId,
      },
      aiConfig: {
        profileId: aiProfileId,
        integrationId: aiIntegrationId,
      },
    };
    await this.rpcManager.post<void>('personalStorage/unindexDocument', request);
  }
}
