import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { SecretService } from '../secret.service';
import { truthy } from 'assertic';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';
import { isValidId } from '@squidcloud/internal-common/utils/validation';

interface FormDetails {
  key: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'app-create-secret-dialog',
  templateUrl: './create-secret-dialog.component.html',
  styleUrls: ['./create-secret-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateSecretDialogComponent {
  errorMessage?: string;
  serverRequestInProgress = false;

  readonly form = new FormGroup<FormDetails>({
    key: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, this.secretKeyValidator()],
    }),
    value: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor(
    private readonly matDialogRef: MatDialogRef<unknown>,
    private readonly secretService: SecretService,
    private readonly snackBar: SnackBarService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async submitCreateSecret(): Promise<void> {
    try {
      this.serverRequestInProgress = true;
      const allSecrets = await firstValueFrom(this.secretService.observeSecrets());
      const key = truthy(this.form.value.key);
      const value = truthy(this.form.value.value);
      if (key in allSecrets) {
        this.errorMessage = `Key '${key}' already exists`;
        return;
      }

      await this.secretService.upsertSecret(key, value);
      this.snackBar.success('Secret stored');
      this.matDialogRef.close({ key });
    } catch (error) {
      this.errorMessage = `Unable to save secret: ${getMessageFromError(error)}`;
    } finally {
      this.serverRequestInProgress = false;
      this.cdr.markForCheck();
    }
  }

  private secretKeyValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      return isValidId(control.value)
        ? null
        : { invalidSecretKey: 'Only letters, numbers, dashes and underscores allowed.' };
    };
  }
}
