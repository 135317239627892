import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SavePaymentDetailsDialogComponent } from './save-payment-details-dialog/save-payment-details-dialog.component';
import { BillingService } from '../billing.service';
import { OrganizationService } from '../organization.service';
import { distinctUntilChanged, filter, merge, Observable, Subject, switchMap, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { IconType, THEME_ICONS } from '../../global/services/theme.service';
import { BillingPlansDialogComponent } from './billing-plans-dialog/billing-plans-dialog.component';
import { BillingPlan, GetPaymentDetailsResponse } from '@squidcloud/console-common/types/billing.types';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { getBillingPlanDescription } from '@squidcloud/console-common/utils/billing-utils';

interface PaymentDetailsEnvelope {
  paymentDetails?: GetPaymentDetailsResponse;
}

@Component({
  selector: 'organization-billing',
  templateUrl: './organization-billing.component.html',
  styleUrls: ['./organization-billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationBillingComponent {
  cardIconsToShow: Array<IconType> = ['visa', 'mastercard', 'amex', 'discover'];
  private readonly organizationObs = this.organizationService.observeCurrentOrganization().pipe(filter(Boolean));

  readonly billingPlanObs = this.organizationObs.pipe(map(org => org.billingPlan));

  private readonly triggerPaymentDetailsRefresh$ = new Subject<void>();

  /** False for GCP or AWS billed orgs. */
  protected isBillingEditable = false;

  readonly paymentDetailsObs: Observable<PaymentDetailsEnvelope> = merge(
    this.organizationObs.pipe(
      tap(org => {
        this.isBillingEditable = org?.billingType === 'stripe';
      }),
      map(org => org?.paymentMethodUpdateDate),
      distinctUntilChanged(),
    ),
    this.triggerPaymentDetailsRefresh$,
  ).pipe(
    switchMap(() => this.billingService.getPaymentDetails()),
    map(paymentDetails => ({ paymentDetails })),
  );

  constructor(
    private readonly dialog: MatDialog,
    readonly billingService: BillingService,
    readonly organizationService: OrganizationService,
    readonly accountService: AccountService,
  ) {}

  showSavePaymentDetailsDialog(): void {
    SavePaymentDetailsDialogComponent.show(this.dialog)
      .afterClosed()
      .subscribe(() => this.triggerPaymentDetailsRefresh$.next());
  }

  getBrandIcon(paymentDetails: GetPaymentDetailsResponse): string {
    return THEME_ICONS.includes(paymentDetails.brand as IconType) ? paymentDetails.brand : 'credit_card_icon';
  }

  showBillingPlansModal(billingPlan: BillingPlan): void {
    const isOnStandardPlan = ['AI_STANDARD_NO_PAYMENT_DETAILS', 'AI_STANDARD'].includes(billingPlan.templateId);
    BillingPlansDialogComponent.show(this.dialog, { isOnStandardPlan });
  }

  protected readonly getBillingPlanDescription = getBillingPlanDescription;
}
