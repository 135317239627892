/** A type alias for an application id. */
export type AppId = string;
export const ENVIRONMENT_IDS = ['dev', 'prod'] as const;
export type EnvironmentId = (typeof ENVIRONMENT_IDS)[number];
export type SquidDeveloperId = string;

/** A type alias for an integration id. */
export type IntegrationId = string;

export type ClientId = string;

export type SquidDocId = string;

export type ClientRequestId = string;

/** ID of AI agent. Also known as AI profile id. */
export type AiAgentId = string;
export type AiProfileId = AiAgentId;

export type AiContextId = string;
