@if (textToRender) {
  <div class="documentation">
    <markdown
      [clipboard]="showCopyButton"
      [data]="textToRender"
      [clipboardButtonComponent]="CopyCodeComponent"
    >
    </markdown>
  </div>
}
