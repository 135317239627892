import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type FilterBubbleClickedOn = 'bubble' | 'close';

@Component({
  selector: 'db-browser-filter-bubble',
  templateUrl: './db-browser-filter-bubble.component.html',
  styleUrl: './db-browser-filter-bubble.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbBrowserFilterBubbleComponent {
  @Input({ required: true })
  label!: string;

  @Input()
  icon?: string;

  @Output()
  clicked = new EventEmitter<FilterBubbleClickedOn>();
}
