import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements AfterViewInit {
  @ViewChild('carousel', { static: false }) carousel!: ElementRef;
  private index = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  hasOverflow = false;

  ngAfterViewInit(): void {
    const carousel = this.carousel.nativeElement.parentElement;
    if (carousel.scrollWidth > carousel.clientWidth) {
      this.hasOverflow = true;
      this.cdr.detectChanges();
    }
  }

  next(): void {
    const items = this.getItems();
    this.index = (this.index + 1) % items.length;
    items[this.index].scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });
  }

  prev(): void {
    const items = this.getItems();
    this.index = (this.index - 1 + items.length) % items.length;
    items[this.index].scrollIntoView({
      behavior: 'smooth',
      inline: 'center',
    });
  }

  getItems(): HTMLCollection {
    return this.carousel.nativeElement.getElementsByClassName('carousel-item');
  }
}
