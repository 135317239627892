import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, UrlTree } from '@angular/router';
import { APP_ID_PARAMETER, getPageParameter, ORG_ID_PARAMETER } from '@squidcloud/console-web/app/utils/http-utils';
import { ApplicationService } from '../../application/application.service';
import { OrganizationService } from '../../organization/organization.service';
import { LocalStorageService } from '../services/local-storage.service';
import { parseAppId } from '@squidcloud/internal-common/types/communication.types';

@Injectable({
  providedIn: 'root',
})
export class BaseGuard implements CanActivateChild {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly organizationService: OrganizationService,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {}

  async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const organizationId = getPageParameter(ORG_ID_PARAMETER, route.params);
    const applicationId = getPageParameter(APP_ID_PARAMETER, route.params);

    const storedApplicationId: string | undefined = this.localStorageService.getItem('currentApplicationId');
    const storedOrganizationId: string | undefined = this.localStorageService.getItem('currentOrganizationId');

    if (applicationId) {
      // Set the application based on the applicationId parameter.
      // If no application exists, redirect to the index route.
      return (await this.initializeFromApplication(applicationId)) ? true : this.router.createUrlTree(['']);
    }

    if (organizationId) {
      // Set the organization based on the organizationId parameter.
      // If no organization exists, redirect to the index route.
      return (await this.initializeFromOrganization(organizationId)) ? true : this.router.createUrlTree(['']);
    }

    if (await this.initializeFromApplication(storedApplicationId)) {
      // Set the application based on the persisted currentApplicationId value.
      // If no application exists, fall through to the next step.
      return true;
    }

    if (await this.initializeFromOrganization(storedOrganizationId)) {
      // Set the application based on the persisted currentOrganizationId value.
      // If no organization exists, fall through to the next step.
      return true;
    }

    const organizations = await this.organizationService.getAllOrganizations();

    if (organizations.length) {
      // Default to the first organization.
      await this.initializeFromOrganization(organizations[0].id);
    } else {
      await this.organizationService.switchOrganization(undefined);
    }

    return true;
  }

  async initializeFromApplication(appId: string | undefined): Promise<boolean> {
    if (!appId) return false;
    const applications = await this.applicationService.getAllApplications();

    const application = applications.find(a => a.appId === appId);
    if (!application) return false;

    await this.organizationService.switchOrganization(application.organizationId);
    await this.applicationService.switchApplication(application.appId);

    return true;
  }

  async initializeFromOrganization(organizationId: string | undefined): Promise<boolean> {
    if (!organizationId) return false;

    const organizations = await this.organizationService.getAllOrganizations();
    const applications = await this.applicationService.getAllApplications();

    const organization = organizations.find(o => o.id === organizationId);
    if (!organization) return false;

    const matchingApplications = applications.filter(a => a.organizationId === organization?.id);
    const application =
      matchingApplications.find(a => parseAppId(a.appId).environmentId === 'dev') || matchingApplications[0];

    await this.organizationService.switchOrganization(organization.id);
    await this.applicationService.switchApplication(application?.appId);

    return true;
  }
}
