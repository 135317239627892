import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../global/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class InvitationGuard implements CanActivate {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const key = route.queryParamMap.get('key');
    if (key) {
      this.localStorageService.setItem('invitationKey', key);
    }
    return this.router.createUrlTree(['/']);
  }
}
