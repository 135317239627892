<div class="organization_page">
  @if (organizationService.currentOrganization$ | async; as organization) {
    @let orgRole = orgRoleObs | async ;
    <div class="container">
      <page-header [title]="organization.name"
                   subtitle="Overview"
                   [description]="'Created: '+(organization.creationDate | date: 'mediumDate')"
                   [withBottomBorder]="false"
      >
      </page-header>
      <div class="tab_group_container">
        <mat-tab-group animationDuration="0ms" color="primary" [disableRipple]="true" mat-align-tabs="start"
                       #tabGroup
                       (click)="updateLocation()" [(selectedIndex)]="selectedTabIndex"
                       mat-stretch-tabs="false">
          <mat-tab label="Details">
            <ng-template matTabContent>
              <organization-details />
            </ng-template>
          </mat-tab>
          <mat-tab label="Members">
            <ng-template matTabContent>
              <organization-members />
            </ng-template>
          </mat-tab>

          @if (isUsageTabVisible) {
            <mat-tab label="Usage">
              <ng-template matTabContent>
                <quotas mode="organization" />
              </ng-template>
            </mat-tab>
          }

          @if (isBillingTabVisible) {
            <mat-tab label="Billing">
              <ng-template matTabContent>
                <organization-billing />
              </ng-template>
            </mat-tab>
          }

          <!-- Contacts tab is always visible, but is editable only by Admins. -->
          <mat-tab label="Contacts">
            <ng-template matTabContent>
              <organization-contact-info />
            </ng-template>
          </mat-tab>

        </mat-tab-group>

        <div class="actions">
        <span [title]="orgRole === 'ADMIN' ? 'Invite Member' : 'Only admins can invite members'">
          @if (tabGroup.selectedIndex === 1) {
            <button (click)="showInviteMemberDialog()"
                    [disabled]="orgRole !== 'ADMIN'"
                    class="invite_member_button secondary_button large_button"
                    mat-flat-button>
            <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
            Invite Member
        </button>
          }
      </span>
        </div>
      </div>

    </div>
  }
</div>
