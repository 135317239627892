@let application = application$ | async ;
@let bundleDataTableData = bundleDataTableData$ | async ;
@if (bundleDataTableData && application) {
  <div class="table_container">
    <bundle-data-table
      [bundleDataTableData]="bundleDataTableData"
      stateKey="openapi"
      (selectedPeriodTypeChange)="periodType$.next($event)"
    />
    <div class="spec_link">
      The generated OpenAPI specification is available
      <a [href]="getOpenApiBaseUrl(application)+'/spec.json'" target="_blank">here</a>
    </div>
  </div>
} @else if (bundleDataTableData === null || application === null) {
  <loading-indicator></loading-indicator>
} @else {
  <div class="functions_doc_wrapper">
    <openapi-tutorial />
  </div>
}
