/**
 * This file contains general validators for the different objects being received from the client. The parameters are
 * usually of type 'any' to make sure there are no assumptions that the object has the correct type.
 * Also, this file should avoid importing from other files that are not for validation to avoid circular deps.
 */
import { assertNumber, assertTruthy } from 'assertic';
import { HttpStatus } from '../public-types/http-status.enum';
import { FieldSort } from '../public-types/query.public-types';

export type StatusCode =
  | HttpStatus.BAD_REQUEST
  | HttpStatus.NOT_FOUND
  | HttpStatus.FORBIDDEN
  | HttpStatus.UNAUTHORIZED
  | HttpStatus.CONFLICT
  | HttpStatus.INTERNAL_SERVER_ERROR;

export class ValidationError extends Error {
  public readonly statusCode: StatusCode;
  public readonly details?: any;

  constructor(error: string, statusCode: StatusCode, details?: Record<string, any>) {
    super(error);
    this.statusCode = statusCode;
    this.details = details;
  }
}

export function isValidId(id: unknown, maxLength?: number): boolean {
  return typeof id === 'string' && !!id && (!maxLength || id.length <= maxLength) && /^[a-zA-Z0-9_-]+$/.test(id);
}

export function validateFieldSort(fieldSort: unknown): void {
  if (!(fieldSort instanceof Object)) {
    throw new Error('Field sort has to be an object');
  }
  const safeFieldSort = fieldSort as FieldSort<unknown>;
  assertTruthy(hasOnlyKeys(safeFieldSort, ['fieldName', 'asc']), 'Field sort should only contain a fieldName and asc');
  assertTruthy(isRightType(safeFieldSort.asc, 'boolean'), 'Asc needs to be boolean');
}

export function validateQueryLimit(limit: unknown): void {
  assertNumber(limit, 'Limit needs to be a number');
  if (limit === -1) return;
  assertTruthy(limit > 0, 'query limit has to be greater than 0');
  assertTruthy(Math.floor(limit) === limit, 'query limit has to be an integer');
  assertTruthy(limit <= 20000, 'Limit can be maximum 20000');
}

/**
 * All type names returned by 'typeof' supported by JavaScript:
 * {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof}.
 * and a few other supported types.
 */
export type JavascriptTypeName =
  | 'undefined'
  | 'object'
  | 'boolean'
  | 'number'
  | 'bigint'
  | 'string'
  | 'symbol'
  | 'function';

/** Returns true if 'typeof' of the 'value' is 'type' or 'type[]'. */
export function isRightType(value: unknown, type: JavascriptTypeName): boolean {
  // TODO: the method is ambiguous when the value is an empty array and will return 'true' for any type.
  if (Array.isArray(value)) {
    return value.every(element => typeof element === type);
  }
  return typeof value === type;
}

/** Returns true if 'obj' has only keys listed in the 'keys'. Object can't be an array. */
export function hasOnlyKeys(obj: object, keys: string[]): boolean {
  return !Array.isArray(obj) && [...Object.keys(obj)].every(key => keys.includes(key));
}
