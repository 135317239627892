<div class="quotas">

  <div class="quotas_header_block">
    <div>
      <div class="quotas_title">
        Usage & Limits
      </div>
      <div class="quotas_details">
        Usage against the limits for your current tier for each resource are shown below.
        <a href="https://docs.squid.cloud/docs/orgs/quotas" target="_blank">Learn more</a> about usage and limits.
      </div>
      @if (periodDateRangeText && snapshotDateText) {
        <div class="quotas_period">
          Period {{ periodDateRangeText }} | Updated: {{ snapshotDateText }}
        </div>
      }
    </div>
    <div>
      <a mat-flat-button (click)="showContactUsDialog()" target="_blank" class="contact_button">Contact us to
        upgrade</a>
    </div>
  </div>
  <div class="quotas_cards_block">
    @for (item of quotaItems$ | async; track item.title) {
      <div class="quotas_card">
        <div class="title_and_details_block">
          <div class="title">{{ item.title }}</div>
          <div class="details">{{ item.details }}</div>
        </div>
        @if (item.alertType) {
          <div class="alert_block" [ngClass]="'alert_block_' + item.alertType">
            <mat-icon svgIcon="warning_icon" />
            <div class="alert_text">
              @if (item.alertType === 'close') {
                Ahoy, you are nearing your limit...
              } @else {
                ARRR, you have reached your limit...
              }
            </div>
            <!-- TODO: replace with a tooltip that supports links. -->
            <mat-icon svgIcon="info" class="info_icon"
                      (click)="showPopoverWithTemplate(item.alertType === 'close'? popover80Template : popover100Template, $event)" />
          </div>
        } @else {
          <div class="progress_block">
            <mat-progress-bar [value]="item.progress" mode="determinate"
                              [ngClass]="item.progressBarClass"></mat-progress-bar>
          </div>
        }
      </div>
    }
  </div>

  @if ((quotaItems$ | async) === null) {
    <div class="loading_indicator">
      <loading-indicator />
    </div>
  }
</div>


<ng-template #popover80Template let-popover="popover" [typeGuard]="{popover: PopoverRef}">
  <div class="popover">
    Your quota limit will soon be reached.<br>
    Please <a (click)="showContactUsDialog(popover)" class="contact_link">contact us</a> to update your plan.
  </div>
</ng-template>

<ng-template #popover100Template let-popover="popover" [typeGuard]="{popover: PopoverRef}">
  <div class="popover">
    You have hit your quota limit.<br>
    Please <a (click)="showContactUsDialog(popover)" class="contact_link">contact us</a> to update your plan.
  </div>
</ng-template>
