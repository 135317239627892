import { Auth0IdentityProviderType, CpUserId } from '@squidcloud/console-common/types/account.types';
import {
  isAuth0DatabaseUserId,
  isAuth0GithubUserId,
  isAuth0GoogleUserId,
} from '@squidcloud/console-common/utils/console-validators';

export function isPrimaryUserAccount(userId: CpUserId, provider: Auth0IdentityProviderType): boolean {
  if (isAuth0GoogleUserId(userId)) {
    return provider === 'google-oauth2';
  }
  if (isAuth0GithubUserId(userId)) {
    return provider === 'github';
  }
  if (isAuth0DatabaseUserId(userId)) {
    return provider === 'auth0';
  }
  console.error(`Unknown account type: ${userId}`);
  return false;
}

export function getAuth0IdentityProviderType(userId: CpUserId): Auth0IdentityProviderType {
  if (isAuth0GoogleUserId(userId)) {
    return 'google-oauth2';
  }
  if (isAuth0GithubUserId(userId)) {
    return 'github';
  }
  if (isAuth0DatabaseUserId(userId)) {
    return 'auth0';
  }
  throw new Error(`Unknown identity provider type. User id: ${userId}`);
}

export const REGISTRATION_IS_NOT_ALLOWED_ERROR = 'REGISTRATION_IS_NOT_GRANTED';
