<div mat-dialog-title>Payment method</div>

<mat-dialog-content class="save_payment_details_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_normal_text">
    Add your credit card details below. This card will be saved to your account and can be removed anytime.
  </div>
  <form (ngSubmit)="submitPaymentDetails()" class="form">
    <mat-spinner class="spinner" [diameter]="64" *ngIf="!ready"></mat-spinner>
    <div [hidden]="!ready">
      <div id="payment_details"></div>
      <div class="error" *ngIf="error">{{ error }}</div>
      <div class="dialog_buttons">
        <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button
                type="button">
          Cancel
        </button>
        <button class="confirm_button primary_button xl_button" mat-flat-button type="submit"
                [disabled]="requestInProgress">
          Save
        </button>
      </div>
    </div>
  </form>
</mat-dialog-content>
