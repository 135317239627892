<div (focusin)="onFocusIn()"
     (focusout)="onFocusOut($event)"
     [attr.aria-labelledby]="formField.getLabelId()"
     [formGroup]="parts"
     *ngIf="allSecretsObs | async as allSecrets"
     class="form">
  <mat-select #secretSelect (selectionChange)="handleSelectChanged()" [placeholder]="placeholder"
              class="select_box gray_select"
              formControlName="secret"
              data-testid="create-secret-select"
  >
    @if (!required) {
      <mat-option [value]="' '">No secret</mat-option>
    }
    @for (secret of allSecrets; track secret.key) {
      <mat-option [title]="placeholder" [value]="secret.key">{{ secret.key }}</mat-option>
    }
    @if (allSecrets.length) {
      <div class="menu_divider"></div>
    }
    <mat-option (click)="showCreateSecretDialog()" class="create_secret_option" data-testid="create-secret-option">
      <mat-icon class="icon" svgIcon="plus_icon"></mat-icon>
      Create secret
    </mat-option>
  </mat-select>
</div>
