<div class="illustration_background" [ngClass]="{ show: withBackground }" *ngIf="themeService.theme$ | async as theme">
  <div class="illustration">
    <mat-icon *ngIf="icon" class="icon" [svgIcon]="themed ? icon + '_' + theme : icon" />
    <img *ngIf="image" class="image" [src]="'assets/illustrations/' + image + (themed ? '_' + theme : '') + '.png'" />
    <div class="content">
      <span *ngIf="title" class="title">{{ title }}.</span>
      <p *ngIf="description" class="description">{{ description }}</p>
      <ng-content />
    </div>
  </div>
</div>
