<div class="info_card knowledge_card" [ngClass]="{hide: !show, border, warning}">
  <div class="card_wrapper" [ngClass]="{hide: !show}">
    <mat-icon *ngIf="rudder" class="rudder" [svgIcon]="'info_rudder_' + rudder"></mat-icon>
    <div class="text_wrapper">
      <div class="title">{{title}}</div>
      <div class="description">{{description}}</div>
    </div>
    <button (click)="onDismiss()">
      <mat-icon svgIcon="close_icon"></mat-icon>
    </button>
  </div>
  <div class="content" [ngClass]="{hide: !show}">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
