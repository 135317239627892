import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, TemplateRef } from '@angular/core';
import { Squid } from '@squidcloud/client';
import { callBackendExecutable } from '@squidcloud/console-common/utils/console-backend-executable';
import { combineLatest, map, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { OrganizationService } from '@squidcloud/console-web/app/organization/organization.service';
import {
  APPLICATION_QUOTA_NAMES,
  ApplicationQuotaName,
  ORGANIZATION_QUOTA_NAMES,
  OrganizationQuotaName,
  QuotaName,
} from '@squidcloud/internal-common/quota.types';
import { PopoverService } from '@squidcloud/console-web/app/global/popover/popover.service';
import { assertTruthy, truthy } from 'assertic';
import { getFinishedBillDate, getNextBillDate } from '@squidcloud/console-common/utils/billing-utils';
import { ContactUsDialogComponent } from '@squidcloud/console-web/app/global/components/contact-us-dialog/contact-us-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PopoverRef } from '@squidcloud/console-web/app/global/popover/popover-ref';
import { ApplicationService } from '@squidcloud/console-web/app/application/application.service';
import { BillingPlan } from '@squidcloud/console-common/types/billing.types';
import { typeGuard } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';

/** State of the quota. Passed by backend to frontend. */
export interface QuotaItem {
  title: string;
  details: string;
  progress: number;
  progressBarClass?: string;
  alertType?: 'close' | 'reached';
}

/** Indicates what type of quotas are shown by the component: organization quotas or application quotas. */
export type QuotasComponentMode = 'organization' | 'application';

@Component({
  selector: 'quotas',
  templateUrl: './quotas.component.html',
  styleUrl: './quotas.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotasComponent implements OnInit, OnChanges {
  @Input({ required: true }) mode!: QuotasComponentMode;

  protected periodDateRangeText = '';
  protected snapshotDateText = '';
  protected readonly quotaItems$: Observable<Array<QuotaItem>>;
  private readonly update$ = new ReplaySubject<void>(1);

  constructor(
    squid: Squid,
    organizationService: OrganizationService,
    applicationService: ApplicationService,
    private readonly dialog: MatDialog,
    private readonly popoverService: PopoverService,
  ) {
    this.quotaItems$ = this.update$.pipe(
      switchMap(() => organizationService.currentOrganization$),
      switchMap(org =>
        combineLatest([of(org), this.isOrganizationMode ? of(undefined) : applicationService.currentApplication$]),
      ),
      switchMap(([org, app]) =>
        combineLatest([
          org
            ? this.isOrganizationMode
              ? callBackendExecutable(squid, 'getOrganizationQuotas', org.id)
              : callBackendExecutable(squid, 'getApplicationQuotas', {
                  organizationId: org.id,
                  applicationId: truthy(app, 'No current application found').appId,
                })
            : of(undefined),
          of(org?.billingPlan),
        ]),
      ),
      map(([response, billingPlan]) => {
        if (!response) {
          this.periodDateRangeText = '';
          this.snapshotDateText = snapshotDateFormatter.format(Date.now());
          return [];
        }
        assertTruthy(billingPlan, 'Billing plan is not defined');
        const finishedBillDate = getFinishedBillDate(billingPlan);
        const nextBillDate = getNextBillDate(billingPlan);
        this.periodDateRangeText = formatDateRange(finishedBillDate, nextBillDate);
        this.snapshotDateText = snapshotDateFormatter.format(new Date());
        const hiddenQuotas: ReadonlyArray<QuotaName> = this.isOrganizationMode
          ? HIDDEN_ORGANIZATION_QUOTA_NAMES
          : HIDDEN_APPLICATION_QUOTA_NAMES;
        return response.quotas
          .filter(q => !hiddenQuotas.includes(q.id))
          .map(({ id, available, max: maxPlanUsage }) => {
            // TODO: group and sort metrics.
            const currentUsage = maxPlanUsage - available;
            const units = getQuotaUnits(id);
            return {
              alertType:
                currentUsage >= maxPlanUsage ? 'reached' : currentUsage >= 0.8 * maxPlanUsage ? 'close' : undefined,
              title: getUsageCardTitleByQuotaName(id),
              details: `${currentUsage} of ${maxPlanUsage} ${units}`,
              progress: (100 * currentUsage) / maxPlanUsage,
              progressBarClass: billingPlan?.lifetimeQuotas?.[id] !== undefined ? 'progress_bar_doc2' : undefined,
            };
          });
      }),
    );
  }

  showPopoverWithTemplate(template: TemplateRef<unknown>, event: Event): void {
    this.popoverService.open(template, event.target as HTMLElement, {
      preferredPosition: { overlayX: 'end', overlayY: 'bottom' },
    });
  }

  showContactUsDialog(popoverRef?: PopoverRef): void {
    popoverRef?.close();
    ContactUsDialogComponent.show(this.dialog, { reason: 'billing_or_quota' });
  }

  ngOnInit(): void {
    assertTruthy(this.mode, 'mode parameter is required');
  }

  ngOnChanges(): void {
    assertTruthy(this.mode, 'mode parameter is required');
    this.update$.next();
  }

  private get isOrganizationMode(): boolean {
    return this.mode === 'organization';
  }

  protected readonly PopoverRef = typeGuard<PopoverRef>();
}

function getQuotaUnits(quotaName: QuotaName): string {
  // Default-less switch to guarantee that all quotas are covered.
  switch (quotaName) {
    case 'maxAiContextBytes':
      return 'B';
    case 'maxMessageQueueBytes':
      return 'B';
    case 'maxNumberOfConcurrentConnections':
    case 'maxNumberOfApplications':
    case 'maxNumberOfIntegrations':
    case 'numberOfClaude2Chats':
    case 'numberOfClaude2Tokens':
    case 'numberOfGeminiProChats':
    case 'numberOfGeminiProTokens':
    case 'numberOfGpt35Chats':
    case 'numberOfGpt35Tokens':
    case 'numberOfGpt4Chats':
    case 'numberOfGpt4Tokens':
    case 'numberOfSecretMutations':
    case 'numberOfMetrics':
    case 'numberOfSecretQueries':
    case 'numberOfSquidActions':
    case 'numberOfAiImagesGenerated':
    case 'numberOfAiRemoveBackgroundRequests':
    case 'numberOfAiAudioTranscriptions':
    case 'numberOfAiAudioCreateSpeechCalls':
      return '';
  }
}

/** Returns user-friendly quota name to be used as a usage card title. */
function getUsageCardTitleByQuotaName(quotaName: QuotaName): string {
  // Default-less switch to guarantee that all quotas are covered.
  switch (quotaName) {
    case 'maxAiContextBytes':
      return 'Maximum AI context size';
    case 'maxMessageQueueBytes':
      return 'Maximum Kafka total traffic';
    case 'maxNumberOfConcurrentConnections':
      return 'Maximum # of concurrent connections';
    case 'maxNumberOfApplications':
      return 'Maximum # of applications';
    case 'maxNumberOfIntegrations':
      return 'Maximum # of integrations';
    case 'numberOfClaude2Chats':
      return 'Maximum # of Claude2 chats';
    case 'numberOfClaude2Tokens':
      return 'Maximum # of Claude2 tokens';
    case 'numberOfGeminiProChats':
      return 'Maximum # of Gemini Pro chats';
    case 'numberOfGeminiProTokens':
      return 'Maximum # of Gemini Pro tokens';
    case 'numberOfGpt35Chats':
      return 'Maximum # of GPT3.5 chats';
    case 'numberOfGpt35Tokens':
      return 'Maximum # of GPT3.5 tokens';
    case 'numberOfGpt4Chats':
      return 'Maximum # of GPT4 chats';
    case 'numberOfGpt4Tokens':
      return 'Maximum # of GPT4 tokens';
    case 'numberOfAiImagesGenerated':
      return 'Maximum # of AI images generated';
    case 'numberOfAiRemoveBackgroundRequests':
      return 'Maximum # of AI remove background requests';
    case 'numberOfAiAudioTranscriptions':
      return 'Maximum # of AI audio transcriptions';
    case 'numberOfAiAudioCreateSpeechCalls':
      return 'Maximum # of AI audio createSpeech calls';
    case 'numberOfMetrics':
      return 'Maximum # of reported metrics';
    case 'numberOfSecretMutations':
      return 'Secret changes';
    case 'numberOfSecretQueries':
      return 'Secret queries';
    case 'numberOfSquidActions':
      return 'Squid actions';
  }
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * Formats reporting date rage for the usage card. Example: Jan 1 - Jan 31, 2024.
 * Both dates are inclusive.
 */
function formatDateRange(startDate: Date, endDate: Date): string {
  const startMonth = monthNames[startDate.getMonth()];
  const startDay = startDate.getDate();

  const endMonth = monthNames[endDate.getMonth()];
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();

  let formattedRange = `${startMonth} ${startDay} - `;

  // Check if the start and end dates are in the same month
  formattedRange += startMonth === endMonth ? `${endDay}, ${endYear}` : `${endMonth} ${endDay}, ${endYear}`;

  return formattedRange;
}

/** Formatter for the report update time date. */
const snapshotDateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: '2-digit',
  timeZoneName: 'short',
  hour12: true,
});

/** Set of organization quotas intentionally not shown (as trivial) by the component. */
export const HIDDEN_ORGANIZATION_QUOTA_NAMES: ReadonlyArray<OrganizationQuotaName> = ['maxNumberOfApplications'];

/** Set of application quotas intentionally not shown (as trivial) by the component. */
export const HIDDEN_APPLICATION_QUOTA_NAMES: ReadonlyArray<ApplicationQuotaName> = ['maxNumberOfIntegrations'];

export function checkOrganizationHasQuotasInBillingPlan({ quotas, lifetimeQuotas }: BillingPlan): boolean {
  return ORGANIZATION_QUOTA_NAMES.some(
    quotaName =>
      !HIDDEN_ORGANIZATION_QUOTA_NAMES.includes(quotaName) && (quotas[quotaName] || lifetimeQuotas?.[quotaName]),
  );
}

export function checkApplicationHasQuotasInBillingPlan({ quotas, lifetimeQuotas }: BillingPlan): boolean {
  return APPLICATION_QUOTA_NAMES.some(
    quotaName =>
      !HIDDEN_APPLICATION_QUOTA_NAMES.includes(quotaName) && (quotas[quotaName] || lifetimeQuotas?.[quotaName]),
  );
}
