import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AnalyticsService } from '@squidcloud/console-web/app/analytics/analytics.service';
import { isPlaywrightTestMode } from '@squidcloud/internal-common/utils/e2e-test-utils';

/** Accumulates navigation related logic and utils used by Console app. */
@Injectable({ providedIn: 'root' })
export class NavigationService {
  static globalNavigationCounter = 0;

  constructor(
    private readonly router: Router,
    private readonly analytics: AnalyticsService,
  ) {
    const isE2eTestMode = isPlaywrightTestMode();
    if (isE2eTestMode) {
      console.log('Detected Playwright E2E test mode');
    }
    this.router.events.pipe(takeUntilDestroyed()).subscribe(event => {
      if (isE2eTestMode) {
        console.log('Router event:', event.toString());
      }
      if (event instanceof NavigationEnd) {
        NavigationService.globalNavigationCounter++;
        void this.analytics.page();
      }
    });
  }

  newNavigationGuard(): NavigationGuard {
    return new NavigationGuard(this.router, NavigationService.globalNavigationCounter);
  }
}

/**
 * A class that helps to handle the following scenario:
 * 1. Some async action is started by a Console code (like save to DB).
 * 2. User clicks some link in UI.
 * 3. Action started at step 1 is finished and the Console code wants to redirect the user to a new page.
 *
 * In this case step 3 must not redirect a user because the user already triggered an explicit navigation.
 */
export class NavigationGuard {
  constructor(
    private readonly router: Router,
    private readonly navigationCounter: number,
  ) {}

  /** Navigates to the URL only if there was no navigation since the guard construction. */
  async navigateByUrl(url: string): Promise<boolean> {
    if (this.hasNavigated) {
      return false;
    }
    return this.router.navigateByUrl(url);
  }

  /** Navigates using the list of commands only if there was no navigation since the guard construction. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    if (this.hasNavigated) {
      return false;
    }
    return this.router.navigate(commands, extras);
  }

  /** Returns true if there was a navigation even since the guard construction. */
  get hasNavigated(): boolean {
    return NavigationService.globalNavigationCounter !== this.navigationCounter;
  }

  /** Returns true if there was not a navigation even since the guard construction. */
  get hasNotNavigated(): boolean {
    return !this.hasNavigated;
  }
}
