<div class="schema_card_item" [ngClass]={elevated}>
  <ng-content />
  <div class="buttons">
    <button *ngIf="edit.observed"
            (click)="edit.emit()"
            class="secondary_icon_button"
            mat-flat-button
            title="Edit">
      <mat-icon svgIcon="edit_icon" />
    </button>
    <button *ngIf="delete.observed"
            (click)="delete.emit()"
            class="secondary_icon_button"
            mat-flat-button
            title="Delete">
      <mat-icon svgIcon="trash_icon" />
    </button>
  </div>
</div>
