<div mat-dialog-title>Create Application</div>

<mat-dialog-content class="create_application_dialog">
  <dialog-close-button></dialog-close-button>
  <div class="dialog_normal_text">A Squid application connects your frontend to all the data, APIs, and backend
    resources you'll need to build your application. Click next to continue or feel free to change the name below
  </div>
  <create-application-form (formSubmitted)="submitCreateApplication()"></create-application-form>
  <div class="dialog_buttons">
    <button [mat-dialog-close]="true" class="close_button outline_button xl_button" mat-stroked-button type="button">
      Cancel
    </button>
    <button [disabled]="createApplicationFormComponent?.form?.invalid || serverCallInProgress"
            (click)="submitCreateApplication()"
            class="confirm_button primary_button xl_button"
            mat-flat-button type="submit">
      <loading-indicator-overlay [isLoading]="serverCallInProgress">Create</loading-indicator-overlay>
    </button>
  </div>
</mat-dialog-content>
