import { cloneDeep, isDateObject } from './object';

function sortKeys(json: unknown): any {
  if (Array.isArray(json)) {
    return json.map(o => sortKeys(o));
  }
  if (typeof json !== 'object' || json === null || json instanceof Date) {
    return json;
  }

  const keys = Object.keys(json);
  const result = {} as Record<string, unknown>;
  keys.sort().forEach(key => {
    result[key] = sortKeys((json as Record<string, unknown>)[key]);
  });
  return result;
}

export function normalizeJsonAsString(json: unknown): string {
  return serializeObj(sortKeys(json));
}

export function serializeObj(obj: unknown): string {
  if (obj === undefined) return null as any; // TODO: change method signature.
  const objWithReplacedDates = cloneDeep(obj, value =>
    isDateObject(value) ? { $date: value.toISOString() } : undefined,
  );
  return JSON.stringify(objWithReplacedDates);
}

export function deserializeObj<T = any>(str: string): T {
  const deserializedObj = JSON.parse(str);
  return cloneDeep(deserializedObj, value => {
    if (value === null || typeof value !== 'object') {
      return undefined;
    }
    const record = value as Record<string, string>;
    const date = record['$date'];
    return date && Object.keys(record).length === 1 ? new Date(date) : undefined;
  });
}

export function encodeValueForMapping(value: unknown): string {
  if (value === undefined) throw new Error('INVALID_ENCODE_VALUE');

  const serializedValue = serializeObj(value);

  if (typeof Buffer !== 'undefined') {
    // Node.js
    return Buffer.from(serializedValue, 'utf8').toString('base64');
  } else {
    // Browser
    const bytes = new TextEncoder().encode(serializedValue);
    let binary = '';
    for (let i = 0; i < bytes.length; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}

export function decodeValueForMapping(encodedString: string): any {
  let decodedValue;

  if (typeof Buffer !== 'undefined') {
    // Node.js
    decodedValue = Buffer.from(encodedString, 'base64').toString('utf8');
  } else {
    // Browser
    const binary = atob(encodedString);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    decodedValue = new TextDecoder().decode(bytes);
  }

  return deserializeObj(decodedValue);
}
