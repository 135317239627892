import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../../global/services/snack-bar.service';
import { SecretService } from '../secret.service';
import { truthy } from 'assertic';
import { CpSecret } from '@squidcloud/console-common/types/application.types';
import { getMessageFromError } from '@squidcloud/internal-common/utils/error-utils';

interface FormDetails {
  key: FormControl<string>;
  value: FormControl<string>;
}

@Component({
  selector: 'app-update-secret-dialog',
  templateUrl: './update-secret-dialog.component.html',
  styleUrls: ['./update-secret-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateSecretDialogComponent {
  errorMessage?: string;

  readonly form;

  constructor(
    private readonly matDialogRef: MatDialogRef<unknown>,
    private readonly secretService: SecretService,
    @Inject(MAT_DIALOG_DATA) public secret: CpSecret,
    private readonly snackBar: SnackBarService,
  ) {
    this.form = new FormGroup<FormDetails>({
      key: new FormControl(secret.key, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      value: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    });
  }

  async submitUpdateSecret(): Promise<void> {
    const value = truthy(this.form.value.value);
    try {
      await this.secretService.upsertSecret(this.secret.key, value);
      this.snackBar.success('Secret updated');
      this.matDialogRef.close();
    } catch (error) {
      this.errorMessage = `Unable to save secret: ${getMessageFromError(error)}`;
    }
  }
}
