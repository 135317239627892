import { BehaviorSubject } from 'rxjs';

type Modification = {
  [key: string]: Modification | boolean;
};
type Path = string | string[];

export class Modifications {
  private modifications: Modification;
  readonly subject = new BehaviorSubject<Modifications>(this);

  constructor() {
    this.modifications = {};
  }

  modifyPath(path: Path): void {
    const pathArray = Array.isArray(path) ? path : path.split('.');
    let current = this.modifications;

    for (const key of pathArray) {
      if (!current[key] || typeof current[key] === 'boolean') {
        current[key] = {};
      }
      current = current[key] as Modification;
    }

    current['_modified'] = true;
    this.subject.next(this);
  }

  isPathModified(path: Path): boolean {
    const pathArray = Array.isArray(path) ? path : path.split('.');
    let current = this.modifications;

    for (const key of pathArray) {
      if (!current[key]) {
        return false;
      }

      current = current[key] as Modification;
    }

    return this.hasModifications(current);
  }

  clearPath(path: Path): void {
    const pathArray = Array.isArray(path) ? path : path.split('.');
    let current = this.modifications;

    for (let i = 0; i < pathArray.length; i++) {
      const key = pathArray[i];
      if (!current[key]) {
        return;
      }

      if (i === pathArray.length - 1) {
        delete current[key];
      } else {
        current = current[key] as Modification;
      }
    }
    this.subject.next(this);
  }

  clear(): void {
    this.modifications = {};
    this.subject.next(this);
  }

  isModified(): boolean {
    return this.hasModifications(this.modifications);
  }

  private hasModifications(modifications: Modification): boolean {
    for (const key in modifications) {
      if (key === '_modified' && modifications[key] === true) {
        return true;
      } else if (typeof modifications[key] === 'object' && this.hasModifications(modifications[key] as Modification)) {
        return true;
      }
    }
    return false;
  }
}
