import { assertTruthy } from 'assertic';

/** Generates a UUID-style ID. */
export function generateId(): string {
  let dt = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

/** Default length if ID produced by `generateShortId`. */
export const DEFAULT_SHORT_ID_LENGTH = 18;

const SHORT_ID_CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Generates an ID of the given `length` using lowercase latin letters and digits.
 * If the 'prefix' provided replaces the first generated characters with the prefix.
 */
export function generateShortId(length = DEFAULT_SHORT_ID_LENGTH, prefix = ''): string {
  assertTruthy(prefix.length < length, 'ID prefix is too long');
  let id = '';
  for (let i = 0; i < length; i++) {
    id += SHORT_ID_CHARACTERS.charAt(Math.floor(Math.random() * SHORT_ID_CHARACTERS.length));
  }
  if (prefix.length > 0) {
    id = prefix + id.substring(prefix.length);
  }
  return id;
}
