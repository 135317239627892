import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountService } from '@squidcloud/console-web/app/account/account.service';
import { SquidTheme, ThemeService } from '@squidcloud/console-web/app/global/services/theme.service';

/** User drop down menu content (top-right in the header). */
@Component({
  selector: 'header-user-dropdown',
  templateUrl: './header-user-dropdown.component.html',
  styleUrls: ['./header-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderUserDropdownComponent {
  constructor(
    readonly accountService: AccountService,
    readonly themeService: ThemeService,
  ) {}

  switchTheme(theme: SquidTheme): void {
    // This timeout is so the menu won't look weird while switching icons
    setTimeout(() => this.themeService.setTheme(theme, true), 100);
  }

  signOut(): void {
    this.accountService.logout();
  }
}
