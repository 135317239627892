import { Routes } from '@angular/router';
import { ProfileSettingsComponent } from './account/profile-settings/profile-settings.component';
import { ApplicationOverviewComponent } from './application/application-overview/application-overview.component';
import { ApplicationSettingsComponent } from './application/application-settings/application-settings.component';
import { SecretsComponent } from './application/secrets/secrets.component';
import { BackendComponent } from './backend/backend.component';
import { ProtectedLayoutComponent } from './global/components/protected-layout/protected-layout.component';
import { SidebarLayoutComponent } from './global/components/sidebar-layout/sidebar-layout.component';
import { AcceptInvitationGuard } from './global/guards/accept-invitation.guard';
import { BaseGuard } from './global/guards/base.guard';
import { IndexGuard } from './global/guards/index.guard';
import { IntegrationGuard } from './global/guards/integration.guard';
import { OnboardingGuard } from './global/guards/onboarding.guard';
import { IntegrationFormComponent } from './integrations/integration-form/integration-form.component';
import { IntegrationComponent } from './integrations/integration/integration.component';
import { IntegrationsComponent } from './integrations/integrations/integrations.component';
import { SchemaComponent } from './integrations/schema/schema.component';
import { OnboardingApplicationComponent } from './onboarding/onboarding-application/onboarding-application.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { InvitationGuard } from './organization/invitation.guard';
import { OrganizationComponent } from './organization/organization.component';
import { LogoutComponent } from './global/components/logout/logout.component';
import { OnboardingWelcomeComponent } from './onboarding/onboarding-welcome/onboarding-welcome.component';
import { OnboardingGettingStartedComponent } from './onboarding/onboarding-getting-started/onboarding-getting-started.component';
import { OnboardingDoneComponent } from './onboarding/onboarding-done/onboarding-done.component';
import { LogsComponent } from './application/logs/logs.component';
import { EmailVerificationComponent } from './global/components/email-verification/email-verification.component';
import {
  AUTH0_EMAIL_CONFIRMATION_PAGE_PATH,
  StatusPageComponent,
} from './global/components/status-page/status-page.component';
import {
  APP_ID_PARAMETER,
  INTEGRATION_ID_PARAMETER,
  ORG_ID_PARAMETER,
  TAB_ID_PARAMETER,
} from '@squidcloud/console-web/app/utils/http-utils';
import { adminAuthGuardFn } from '@squidcloud/console-web/app/admin/admin-auth.guard';
import {
  ONBOARDING_ENTRY_STEP_PARAMETER,
  ONBOARDING_FLOW_ID_PARAMETER,
  ONBOARDING_PAGE_PATH,
} from '@squidcloud/console-web/app/onboarding/onboarding.types';
import { ConsoleAuthGuard } from '@squidcloud/console-web/app/global/guards/auth.guard';
import { ApplicationUsageComponent } from '@squidcloud/console-web/app/application/application-usage/application-usage.component';
import { OnboardingEntryComponent } from '@squidcloud/console-web/app/onboarding/onboarding-entry/onboarding-entry.component';
import { OnboardingCardsComponent } from '@squidcloud/console-web/app/onboarding/onboarding-cards/onboarding-cards.component';
import { OnboardingWidgetGettingStartedComponent } from '@squidcloud/console-web/app/onboarding/onboarding-widget-getting-started/onboarding-widget-getting-started.component';
import { ERROR_PAGE_PATH, STATUS_PAGE_PATH } from '@squidcloud/console-common/types/shared-url-constants';
import { IntegrationsGuard } from '@squidcloud/console-web/app/global/guards/integrations.guard';
import {
  USER_LOCK_PAGE_PATH,
  UserLockPageComponent,
} from '@squidcloud/console-web/app/global/components/user-lock-page/user-lock-page.component';
import { AuditLogsComponent } from '@squidcloud/console-web/app/integrations/audit-logs/audit-logs.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        canActivate: [ConsoleAuthGuard],
        canActivateChild: [BaseGuard],
        component: ProtectedLayoutComponent,
        children: [
          {
            path: ONBOARDING_PAGE_PATH,
            component: OnboardingComponent,
            children: [
              { path: '', redirectTo: 'welcome', pathMatch: 'full' },
              { path: 'welcome', component: OnboardingWelcomeComponent, data: { animation: 'openClosePage' } },
              { path: `entry`, component: OnboardingEntryComponent },
              { path: `entry/:${ONBOARDING_ENTRY_STEP_PARAMETER}`, component: OnboardingEntryComponent },
              { path: `cards`, component: OnboardingCardsComponent },
              { path: `application/:${ONBOARDING_FLOW_ID_PARAMETER}`, component: OnboardingApplicationComponent },
              { path: `getting-started/widget`, component: OnboardingWidgetGettingStartedComponent },
              {
                path: `getting-started/:${ONBOARDING_FLOW_ID_PARAMETER}`,
                component: OnboardingGettingStartedComponent,
              },
              { path: 'done', component: OnboardingDoneComponent },
            ],
          },
          {
            path: '',
            component: SidebarLayoutComponent,
            canActivate: [AcceptInvitationGuard],
            canActivateChild: [OnboardingGuard],
            children: [
              { path: '', canActivate: [IndexGuard], children: [] },
              {
                path: `organization/:${ORG_ID_PARAMETER}`,
                children: [
                  { path: '', redirectTo: 'details', pathMatch: 'full' },
                  { path: `:${TAB_ID_PARAMETER}`, component: OrganizationComponent },
                ],
              },
              {
                path: 'integrations',
                canActivate: [IntegrationsGuard],
                // The component below won't be used, the IntegrationGuard will redirect to the
                // '/application/id/integrations' page.
                children: [{ path: '**', component: IntegrationsComponent }],
              },
              {
                path: 'application',
                children: [
                  { path: '', component: ApplicationOverviewComponent },
                  { path: 'dialog/:dialog', component: ApplicationOverviewComponent },
                  { path: 'quick-start', component: ApplicationOverviewComponent },
                  { path: 'integrations', component: IntegrationsComponent },
                  { path: 'secrets', component: SecretsComponent },
                  { path: 'backend', component: BackendComponent },
                  { path: `backend/:${TAB_ID_PARAMETER}`, component: BackendComponent },
                  { path: 'logs', component: LogsComponent },
                ],
              },
              {
                path: `application/:${APP_ID_PARAMETER}`,
                children: [
                  { path: '', component: ApplicationOverviewComponent },
                  { path: 'dialog/:dialog', component: ApplicationOverviewComponent },
                  {
                    path: 'integrations',
                    children: [
                      { path: '', redirectTo: 'current', pathMatch: 'full' },
                      { path: 'add/:type', component: IntegrationFormComponent },
                      { path: `:${TAB_ID_PARAMETER}`, component: IntegrationsComponent },
                      { path: `:${TAB_ID_PARAMETER}/:selectedCategoryKey`, component: IntegrationsComponent },
                    ],
                  },
                  {
                    path: `integration/:${INTEGRATION_ID_PARAMETER}`,
                    canActivate: [IntegrationGuard],
                    children: [
                      { path: '', redirectTo: 'edit', pathMatch: 'full' },
                      { path: `:${TAB_ID_PARAMETER}`, component: IntegrationComponent },
                      { path: 'schema/:new', component: SchemaComponent },
                      { path: 'audit', component: AuditLogsComponent },
                    ],
                  },
                  {
                    path: 'backend',
                    children: [
                      // redirectTo should point to the first tab on the page.
                      { path: '', redirectTo: 'executables', pathMatch: 'full' },
                      { path: ':tabId', component: BackendComponent },
                    ],
                  },
                  { path: 'usage', component: ApplicationUsageComponent },
                  { path: 'logs', component: LogsComponent },
                  { path: 'secrets', component: SecretsComponent },
                  { path: 'settings', component: ApplicationSettingsComponent },
                ],
              },
              { path: 'profile', component: ProfileSettingsComponent },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [ConsoleAuthGuard, adminAuthGuardFn],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  { path: 'logout', canActivate: [ConsoleAuthGuard], component: LogoutComponent },
  { path: 'email-verification', component: EmailVerificationComponent },
  { path: ERROR_PAGE_PATH, component: StatusPageComponent },
  { path: STATUS_PAGE_PATH, component: StatusPageComponent },
  { path: AUTH0_EMAIL_CONFIRMATION_PAGE_PATH, component: StatusPageComponent },
  { path: USER_LOCK_PAGE_PATH, component: UserLockPageComponent },
  { path: 'invitations/accept', canActivate: [InvitationGuard], children: [] },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
