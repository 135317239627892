<div class="task_container" [ngClass]="taskDisplayType" *ngIf="taskDisplayType !== 'completed_task'">
  <div class="title_container">
    <div class="title">{{task.title}}</div>
    <div class="mark_complete_button" (click)="markComplete.emit()">Mark Complete</div>
  </div>
  <div class="description">{{task.description}}</div>
  <div class="task_buttons">
    <button (click)="startTask.emit()"
            class="start_task_button secondary_button medium_button"
            mat-flat-button>{{task.buttonText}}
    </button>
    <a [href]="task.documentationLink" *ngIf="task.documentationLink" title="Documentation" target="_blank">
      <button class="task_documentation_button secondary_icon_button medium_button"
              mat-flat-button>
        <mat-icon class="documentation_icon" svgIcon="documentation_icon"></mat-icon>
      </button>
    </a>
  </div>
</div>

<div class="completed_task_container" *ngIf="taskDisplayType === 'completed_task'">
  <div class="title">{{task.title}}</div>
  <div class="right">
    <div class="completion_date">{{task.completionDate | date: 'MM.dd.yy'}}</div>
    <a [href]="task.documentationLink" *ngIf="task.documentationLink" title="Documentation" target="_blank">
      <button class="task_documentation_button secondary_icon_button medium_button"
              mat-flat-button>
        <mat-icon class="documentation_icon" svgIcon="documentation_icon"></mat-icon>
      </button>
    </a>
  </div>
</div>
