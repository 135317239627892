export function replaceInUrl(
  url: string,
  replace: Record<string, string>,
): { url: string; queryParams: Record<string, unknown> } {
  const [pathString, queryParamsString] = url.split('?');
  const parts = pathString.split('/');

  const queryParams = Object.fromEntries(new URLSearchParams(queryParamsString));

  const replacedParts: Array<string> = [];
  let replacer = '';

  for (const part of parts) {
    if (replacer) {
      replacedParts.push(replacer);
      replacer = '';
    } else {
      replacedParts.push(part);
      replacer = replace[part];
    }
  }

  return {
    url: replacedParts.join('/'),
    queryParams,
  };
}
