import { CollectionName, DocId, IntegrationId, SquidDocId } from './public-types';
import { DataManager } from './data.manager';
import DocumentIdentityService from './document-identity.service';
import { DocumentReference } from './document-reference';
import { QueryBuilderFactory } from './query/query-builder.factory';
import { truthy } from 'assertic';
import { replaceKeyInMap } from '../../internal-common/src/utils/object';
import { parseSquidDocId } from '../../internal-common/src/types/document.types';
import { IdResolutionMap } from '../../internal-common/src/types/mutation.types';

type CollectionKey = `${IntegrationId}_${CollectionName}`;

/** @internal */
export class DocumentReferenceFactory {
  private dataManager!: DataManager;

  private readonly documents = new Map<DocId, DocumentReference>();
  private readonly documentsForCollection = new Map<CollectionKey, Array<DocumentReference>>();

  constructor(private readonly documentIdentityService: DocumentIdentityService) {
    this.documentIdentityService.observeChanges().subscribe(this.migrateDocIds.bind(this));
  }

  create(squidDocId: SquidDocId, queryBuilderFactory: QueryBuilderFactory): DocumentReference {
    let reference = this.documents.get(squidDocId);
    if (reference) return reference;
    reference = new DocumentReference(
      squidDocId,
      truthy(this.dataManager, 'dataManager not found'),
      queryBuilderFactory,
    );
    const { integrationId, collectionName } = parseSquidDocId(squidDocId);
    this.documents.set(squidDocId, reference);

    const collectionKey = this.getCollectionKey(integrationId, collectionName);
    const docsForCollection = this.documentsForCollection.get(collectionKey) || [];
    this.documentsForCollection.set(collectionKey, docsForCollection.concat(reference));

    return reference;
  }

  setDataManager(dataManager: DataManager): void {
    this.dataManager = dataManager;
  }

  getDocumentsForCollection(integrationId: IntegrationId, collectionName: CollectionName): Array<DocumentReference> {
    const collectionKey = this.getCollectionKey(integrationId, collectionName);
    return (this.documentsForCollection.get(collectionKey) || []).filter(d => d.hasData);
  }

  migrateDocIds(idResolutionMap: IdResolutionMap): void {
    for (const [, reference] of this.documents) {
      reference.migrateDocIds(idResolutionMap);
    }

    Object.entries(idResolutionMap).forEach(([squidDocId, newSquidDocId]) => {
      const squidDocIdObj = parseSquidDocId(squidDocId);
      const newSquidDocIdObj = parseSquidDocId(newSquidDocId);

      replaceKeyInMap(this.documents, squidDocIdObj.docId, newSquidDocIdObj.docId);
    });
  }

  private getCollectionKey(integrationId: IntegrationId, collectionName: CollectionName): CollectionKey {
    return `${integrationId}_${collectionName}`;
  }
}
