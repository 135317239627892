import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import { BackendMetricPeriodType } from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  compareByFunctionId,
  newErrorsColumn,
  newMedianColumn,
  newP95Column,
  newServiceNameColumn,
  newSuccessColumn,
  newTextColumn,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

@Component({
  templateUrl: './ai-functions.component.html',
  styleUrls: ['./ai-functions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiFunctionsComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;

  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.backendService.reportBackendTabHasMetrics(false);

    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['aiFunction'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions.sort(compareByFunctionId).map(metric => {
            const { counts, functionData } = metric;
            const { extras } = functionData;
            const shortFunctionName = extras['id'] as string;
            const columns: Array<BundleDataTableColumn> = [
              newTextColumn(shortFunctionName),
              newSuccessColumn(counts),
              newErrorsColumn(counts),
              newMedianColumn(counts),
              newP95Column(counts),
              newServiceNameColumn(functionData.serviceFunctionName),
            ];
            return { columns };
          });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;
          return {
            headerRow: [
              'Name',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Service',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );
  }
}
