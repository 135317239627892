import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, filter, take } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { AccountService } from '../../account/account.service';
import { AppId, SquidRegion } from '@squidcloud/client';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CpApplication } from '@squidcloud/console-common/types/application.types';
import { appIdWithEnvironmentId } from '@squidcloud/internal-common/types/communication.types';
import { convertToSquidRegion } from '@squidcloud/console-common/clouds-and-regions';
import { environment } from '@squidcloud/console-web/environments/environment';

@Component({
  selector: 'app-create-env-file-doc',
  templateUrl: './create-env-file-doc.component.html',
  styleUrls: ['./create-env-file-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEnvFileDocComponent {
  readonly apiKeySubject = new BehaviorSubject<string | undefined>(undefined);
  readonly appObs = this.applicationService.observeCurrentApplication().pipe(filter(Boolean));
  readonly userObs = this.accountService.observeUser().pipe(filter(Boolean));

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly accountService: AccountService,
  ) {
    this.appObs.pipe(take(1), takeUntilDestroyed()).subscribe(async () => {
      const apiKey = await this.applicationService.getApiKey();
      this.apiKeySubject.next(apiKey || '<Your API key here>');
    });
  }

  getProdAppId(appId: AppId): string {
    return appIdWithEnvironmentId(appId, 'prod');
  }

  getRegion(app: CpApplication): SquidRegion {
    return convertToSquidRegion(app.cloudId, app.region, app.shard, environment.stage);
  }
}
