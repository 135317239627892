import { assertTruthy, formatError, ValueAssertion } from 'assertic';
import { DEFAULT_SHORT_ID_LENGTH } from '@squidcloud/internal-common/public-utils/id-utils';
import { CONSOLE_ORGANIZATION_ID } from '@squidcloud/console-common/types/organization.types';

export const INVALID_USER_ID_ERROR_TOKEN = 'INVALID_USER_ID';

const HEX_STRING_REGEX = /^[0-9a-fA-F]*$/;
const DIGIT_STRING_REGEX = /^[0-9]*$/;
const AUTH0_DATABASE_USER_ID_PREFIX = 'auth0|';
const AUTH0_SOCIAL_GOOGLE_USER_ID_PREFIX = 'google-oauth2|';
const AUTH0_SOCIAL_GITHUB_USER_ID_PREFIX = 'github|';

/** Returns true if 'value' is a string that contains only hexadecimal characters. The string may be empty. */
export function isHexString(value: unknown): value is string {
  return typeof value === 'string' && HEX_STRING_REGEX.test(value);
}

/**
 * Returns true if the value is a valid Auth0 local database user id.
 * Console app re-uses these Auth0 ids as Console user ids.
 */
export function isAuth0DatabaseUserId(value: unknown): value is string {
  if (typeof value !== 'string') return false;
  if (value.length < 28 || value.length > 34) return false;
  if (!value.startsWith(AUTH0_DATABASE_USER_ID_PREFIX)) return false;
  if (value.toLocaleLowerCase() !== value) return false;
  const hexPart = value.substring(AUTH0_DATABASE_USER_ID_PREFIX.length);
  return isHexString(hexPart);
}

/**
 * Returns true if the value is a valid Auth0 social user id.
 * Console app re-uses these Auth0 ids as Console user ids.
 */
export function isAuth0SocialUserId(value: unknown): value is string {
  return isAuth0GoogleUserId(value) || isAuth0GithubUserId(value);
}

/**
 * Returns true if the value is a valid Auth0 social Google user id.
 * Console app re-uses these Auth0 ids as Console user ids.
 */
export function isAuth0GoogleUserId(value: unknown): value is string {
  if (typeof value !== 'string') return false;
  if (value.length !== AUTH0_SOCIAL_GOOGLE_USER_ID_PREFIX.length + 21) return false;
  if (!value.startsWith(AUTH0_SOCIAL_GOOGLE_USER_ID_PREFIX)) return false;
  const numericPart = value.substring(AUTH0_SOCIAL_GOOGLE_USER_ID_PREFIX.length);
  return DIGIT_STRING_REGEX.test(numericPart);
}

/**
 * Returns true if the value is a valid Auth0 social Github user id.
 * Console app re-uses these Auth0 ids as Console user ids.
 */
export function isAuth0GithubUserId(value: unknown): value is string {
  if (typeof value !== 'string') return false;
  const prefixLength = AUTH0_SOCIAL_GITHUB_USER_ID_PREFIX.length;
  if (value.length < prefixLength + 5 || value.length > prefixLength + 21) return false;
  if (!value.startsWith(AUTH0_SOCIAL_GITHUB_USER_ID_PREFIX)) return false;
  const numericPart = value.substring(prefixLength);
  return DIGIT_STRING_REGEX.test(numericPart);
}

/** Returns true if the value is a valid Auth0 user id (sub) of any supported by Auth0 provider type. */
export function isAuth0UserId(value: unknown): value is string {
  return isAuth0DatabaseUserId(value) || isAuth0SocialUserId(value);
}

/**
 * Returns true if the value is a valid Console user id.
 * The Console app user ids are the same as Auth0 user ids (subs).
 * */
export function isConsoleUserId(value: unknown): value is string {
  return isAuth0UserId(value);
}

export const assertConsoleUserId: ValueAssertion<string> = (
  value: unknown,
  context = undefined,
): asserts value is string => {
  assertTruthy(isConsoleUserId(value), () => formatError(context, INVALID_USER_ID_ERROR_TOKEN, value));
};

export const MIN_ORGANIZATION_NAME_LENGTH = 1;
export const MAX_ORGANIZATION_NAME_LENGTH = 100;

/** Returns true if the value can be considered as a valid organization name. */
export function isOrganizationName(value: unknown): value is string {
  return (
    typeof value === 'string' &&
    value.length >= MIN_ORGANIZATION_NAME_LENGTH &&
    value.length <= MAX_ORGANIZATION_NAME_LENGTH
  );
}

/** Returns true if the value can be considered as a valid organization ID. */
export function isOrganizationId(value: unknown): value is string {
  return typeof value === 'string' && (value.length === DEFAULT_SHORT_ID_LENGTH || value === CONSOLE_ORGANIZATION_ID);
}

export const MIN_INTEGRATION_ID_LENGTH = 1;
export const MAX_INTEGRATION_ID_LENGTH = 100;

/** Returns true if the value can be used as a valid integration ID. */
export function isIntegrationId(value: unknown): value is string {
  return (
    typeof value === 'string' && value.length >= MIN_INTEGRATION_ID_LENGTH && value.length <= MAX_INTEGRATION_ID_LENGTH
  );
}

/** Returns true if the value can be considered as a valid organization invitation key. */
export function isInvitationKey(value: unknown): value is string {
  return typeof value === 'string' && value.length === DEFAULT_SHORT_ID_LENGTH;
}
