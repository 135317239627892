<div class="spinner_container" *ngIf="!(applicationInitializedObs | async)">
  <mat-spinner class="spinner"></mat-spinner>
</div>
<quick-start-fly-out></quick-start-fly-out>
<ai-test-chat-fly-out></ai-test-chat-fly-out>
@let isExpanded = !!(isExpandedObs | async) ;
@let theme = themeObs | async ;
<div class="sidebar_layout">
  <div
    [ngClass]="{ expanded: isExpanded, light: theme === 'light' }"
    class="sidebar"
  >
    <div class="top">
      <div class="logo_container">
        <div *ngIf="isExpanded" class="logo">
          <mat-icon svgIcon="logo_long" class="logo_icon long"></mat-icon>
        </div>

        <div *ngIf="!isExpanded" class="logo">
          <mat-icon svgIcon="logo" class="logo_icon short"></mat-icon>
        </div>
      </div>

      @for (item of sidebarMenuItems; track item.id) {
        @if (isMenuItemVisible(item)) {
          <sidebar-menu-item
            [isActive]="isMenuItemActive(item)"
            [isMenuExpanded]="isExpanded"
            [item]="item"
          ></sidebar-menu-item>
        }
      }

      <div class="little_wave_wrapper">
        <mat-icon
          class="little_wave_icon"
          svgIcon="little_wave_icon"
        ></mat-icon>
      </div>

      <sidebar-menu-item
        *ngIf="organizationMenuItem"
        [isActive]="isMenuItemActive(organizationMenuItem)"
        [isMenuExpanded]="isExpanded"
        [item]="organizationMenuItem"
      >
      </sidebar-menu-item>
    </div>

    <div class="bottom">
      @let isQuickStartExpanded = quickStartExpandedObs | async ;
      <div
        class="quick_start_progress_container"
        [ngClass]="{
              active: isQuickStartExpanded,
              menu_collapsed: !isExpanded,
            }"
        (click)="toggleQuickStartExpanded()"
        *ngIf="quickStartProgressObs | async as progress"
      >
        <div class="active_indicator"></div>
        <div class="progress_bar_wrapper">
          <svg
            class="progress noselect"
            data-progress="65"
            x="0px"
            y="0px"
            viewBox="0 0 80 80"
          >
            <path
              class="track"
              d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
            />
            <path
              class="fill"
              #fillPath
              [style.stroke-dashoffset]="
                    ((100 - progress.percentCompleted) / 100) *
                    getMaxFillWidth(fillPath)
                  "
              d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0"
            />
            <text class="value" x="49%" y="66%">
              {{ progress.total - progress.completed }}
            </text>
          </svg>
        </div>
        <div class="content_wrapper">
          <div class="title">Quick Start Tasks</div>
          <div class="count">
            {{ progress.completed }} of {{ progress.total }} completed
          </div>
        </div>
      </div>
      <sidebar-menu-item
        [isMenuExpanded]="isExpanded"
        [item]="feedbackMenuItem"
        [isActive]="false"
      ></sidebar-menu-item>
      <sidebar-menu-item
        [isMenuExpanded]="isExpanded"
        [item]="documentationMenuItem"
        [isActive]="false"
      ></sidebar-menu-item>

      <ng-container *ngIf="accountService.user$ | async as user">
        <sidebar-menu-item
          [isMenuExpanded]="isExpanded"
          [item]="adminMenuItem"
          [isActive]="false"
          *ngIf="isSysadminUserEmail(user.email)"
        ></sidebar-menu-item>
      </ng-container>

      <sidebar-menu-item
        [isMenuExpanded]="isExpanded"
        [item]="onboardingMenuItem"
        [isActive]="false"
      ></sidebar-menu-item>

      <div
        (click)="setIsCollapsed(isExpanded)"
        [ngClass]="{ menu_collapsed: !isExpanded }"
        class="collapse_or_expand_button"
      >
        <mat-icon class="icon" svgIcon="collapse_icon"></mat-icon>
        <div class="text">Collapse</div>
      </div>
    </div>
  </div>

  <div class="main_and_header">
    <app-header class="header"></app-header>
    <div class="main">
      <router-outlet></router-outlet>
    </div>
  </div>

  <rudder-chat />
</div>

