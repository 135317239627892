import { filter, firstValueFrom, Observable, timeout } from 'rxjs';
import { MILLIS_PER_SECOND } from '@squidcloud/internal-common/types/time-units';
import { environment } from '@squidcloud/console-web/environments/environment';
import { logE2eTestMessage } from '@squidcloud/console-web/app/utils/e2e-test-web-utils';

/**
 * Awaits up to `timeoutMillis` until `observable` emits any value other than `undefined`.
 * This utility method is used when some entity (org/app/integration) is created via executable API call and
 * Console app wants to use the entity right after the executable call (ex: navigate to the entity page).
 * In this case there may be some delay until the update is delivered to the web client via web-socket channel.
 */
export async function waitForAsyncUpdate(
  observable: Observable<unknown>,
  callerName: string,
  timeoutMillis = 10 * MILLIS_PER_SECOND,
): Promise<void> {
  logE2eTestMessage(() => `waitForAsyncUpdate: ${callerName} started`);
  try {
    await firstValueFrom(
      observable.pipe(
        filter(v => v !== undefined),
        timeout(timeoutMillis),
      ),
    );
    logE2eTestMessage(() => `waitForAsyncUpdate: ${callerName} succeed`);
  } catch (error) {
    console.error(`waitForAsyncUpdate failed to get the result. Caller: ${callerName}`, error);
  }
}

/**
 * Returns a full --stage parameter (key and value) for `squid ...` command.
 * Default for this parameter is '--stage prod', so only parameters values for other stages are used.
 */
export function getBackendInitStageParameter(): string {
  switch (environment.stage) {
    case 'local':
      return '--stage local';
    case 'sandbox':
      return '--stage sandbox';
    case 'dev':
      return '--stage dev';
    case 'prod':
      return '';
  }
}
