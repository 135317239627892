<div class="available_integrations_page">
  @let selectedCategory = (selectedCategorySubject | async) || availableCategories[0].key ;
  <div class="available_integrations_controller">
    <div class="left">
      <mat-select
        class="squid_select"
        panelClass="squid_select"
        (selectionChange)="handleSelectChanged($event.value)"
        [value]="selectedCategory">
        @for (category of availableCategories; track category.key) {
          <mat-option [value]="category.key">{{ category.name }}</mat-option>
        }
      </mat-select>
    </div>


    <div class="right">
      <mat-form-field class="search_form_field form_field" appearance="outline">
        <mat-label class="filter_label">
          <mat-icon class="icon" svgIcon="search_icon"></mat-icon>
          Search integrations
        </mat-label>
        <input matInput type="text" (input)="onFiltersChanged($event)" />
      </mat-form-field>
    </div>
  </div>

  @if (getCategoryAndIntegrationsArray(selectedCategory, filterText); as categoryAndIntegrationsArray) {
    @for (categoryAndIntegrations of categoryAndIntegrationsArray; track categoryAndIntegrations.category.key) {
      <div class="integration_category_container">
        <div class="category_title"
             *ngIf="categoryAndIntegrationsArray.length > 1">{{ categoryAndIntegrations.category.name }}
        </div>
        <div class="available_integrations">
          @for (integration of categoryAndIntegrations.integrations; track integration.type) {
            <div class="available_integration" [attr.data-testid]="'integration-block-' + integration.type">
              <div class="logo_and_metadata">
                <img [src]="'/assets/integrations/' + integration.type + '.svg'" class="integration_icon logo"
                     alt="integrations" />
                <div class="metadata">
                  <div class="name">{{ integration.name }}</div>
                  <div class="description">{{ integration.description }}</div>
                </div>
              </div>
              @let isAdmin = ('ADMIN' | minRole) ;
              <div class="button_and_tag"
                   [matTooltip]="(integration['available'] && !isAdmin) ? 'You need to be an admin to add integrations' : ''">
                @if (integration['available'] || !integration['docs']) {
                  <button
                    [routerLink]="integration['available'] ? ['/application', currentApplication.appId, 'integrations', 'add', integration.type] : undefined"
                    [disabled]="!isAdmin"
                    (click)="integration['available'] ? handleAddIntegration(integration.type) : showRequestIntegrationDialog(integration.name)"
                    class="secondary_button medium_button"
                    mat-flat-button
                    [attr.data-testid]="'add-' + integration.type + '-integration-button'"
                  >
                    {{ integration['available'] ? 'Add Integration' : 'Request' }}
                  </button>
                } @else {
                  <button
                    (click)="openDocs(integration['docs']!)"
                    class="secondary_button medium_button"
                    mat-flat-button
                    [attr.data-testid]="'view-' + integration.type + '-docs-button'"
                  >
                    View Docs
                  </button>
                }
                <span class="tag badge" [ngClass]="integration.category">{{ integration.category }}</span>
              </div>
            </div>
          }
        </div>
      </div>
    }
  }
</div>
