/** Returns start timestamp of the given date. */
export function getStartOfTheDay(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

/** Returns the last day of the month in 1..31 domain. */
export function getLastDayOfMonth(year: number, month: number): number {
  // Month is 0-indexed (0 for January, 1 for February, etc.)
  return new Date(year, month + 1, 0).getDate();
}

/**
 * Adds `monthCount` months to the date.
 * Preserves the `originalDayOfTheMonth` if possible, like Jan 31 -> Feb 28 -> March 31 -> Apr 30 -> May 31.
 */
export function addMonths(date: Date, monthCount: number, originalDayOfTheMonth: number): Date {
  let month = date.getMonth();
  let year = date.getFullYear();
  month += monthCount;
  if (month > 11) {
    year += Math.floor(month / 12);
    month = month % 12;
  }
  const resultDayOfTheMonth = Math.min(originalDayOfTheMonth, getLastDayOfMonth(year, month));
  return new Date(year, month, resultDayOfTheMonth);
}
