<div class="filter_bubble" (click)="clicked.emit('bubble')">
  <div class="left">
    <div class="label_and_icon">
      <div class="label ellipsis">{{ label }}</div>
      <mat-icon class="icon" [svgIcon]="icon" *ngIf="icon"></mat-icon>
    </div>
  </div>
  <div class="right" (click)="$event.stopPropagation(); clicked.emit('close')">
    <mat-icon class="close_icon" svgIcon="close_icon"></mat-icon>
  </div>
</div>
