import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { timer } from 'rxjs';

/**
 * A loading indicator or an infinite progress bar.
 *
 * Should be used in case if Console app waits for some operation completion
 * and has nothing to show to user while the data is loading.
 *
 * The indicator does not appear immediately, but after some delay to avoid
 * flickering in case if the data is loaded fast.
 */
@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrl: './loading-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent implements OnInit {
  /**
   * Time in milliseconds from the component creation to the moment when indicator becomes visible.
   *
   * Only the value provided during component initialization is used:
   *  the component does not support dynamic updates of this value today.
   */
  @Input() delayMillis = 500;

  isVisible = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    timer(this.delayMillis)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isVisible = true;
        this.cdr.markForCheck();
      });
  }
}
