import {
  AiAudioCreateSpeechOptions,
  AiAudioCreateSpeechRequest,
  AiAudioCreateSpeechResponse,
  AiAudioTranscribeOptions,
} from './public-types';
import { RpcManager } from './rpc.manager';
import { BlobAndFilename } from './types';
import { base64ToFile } from './file-utils';

export class AiAudioClient {
  /** @internal */
  constructor(private readonly rpcManager: RpcManager) {}

  async transcribe(
    file: File | BlobAndFilename,
    options: AiAudioTranscribeOptions = { modelName: 'whisper-1' },
  ): Promise<string> {
    return this.rpcManager.post<string>('ai/audio/transcribe', options, [file]);
  }

  async createSpeech(input: string, options: AiAudioCreateSpeechOptions): Promise<File> {
    const request: AiAudioCreateSpeechRequest = { input, options };
    const resp = await this.rpcManager.post<AiAudioCreateSpeechResponse>('ai/audio/createSpeech', request);
    const base64File = resp.base64File;
    return base64ToFile(base64File, `audio.${resp.extension}`, resp.mimeType);
  }
}
