import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SecretService } from './secret.service';
import { map } from 'rxjs';
import { SecretUiService } from './secret-ui.service';
import { ApplicationService } from '../application.service';
import { CpSecret } from '@squidcloud/console-common/types/application.types';
import { typeGuard } from '@squidcloud/console-web/app/global/directives/template-type-guard.directive';

@Component({
  selector: 'app-secrets',
  templateUrl: './secrets.component.html',
  styleUrls: ['./secrets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecretsComponent {
  readonly secretsObs = this.secretService.observeSecrets().pipe(map(secrets => Object.values(secrets)));
  readonly applicationObs = this.applicationService.observeCurrentApplication();

  protected readonly CpSecret = typeGuard<CpSecret>();

  constructor(
    private readonly secretService: SecretService,
    protected readonly secretUiService: SecretUiService,
    private readonly applicationService: ApplicationService,
  ) {}
}
