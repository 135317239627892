@let bundleDataTableData = bundleDataTableData$ | async ;
@if (bundleDataTableData) {
  <div class="table_container" data-testid="webhooks-table">
    <bundle-data-table
      [bundleDataTableData]="bundleDataTableData"
      stateKey="webhooks"
      (selectedPeriodTypeChange)="periodType$.next($event)"
    />
  </div>
} @else if (bundleDataTableData === null) {
  <loading-indicator></loading-indicator>
} @else {
  <div class="functions_doc_wrapper">
    <webhooks-tutorial></webhooks-tutorial>
  </div>
}
