export const MARKETPLACE_TYPES = ['aws', 'gcp'] as const;
export type MarketplaceType = (typeof MARKETPLACE_TYPES)[number];

export const MP_ACCOUNT_SETUP_EVENT_IDS_LS_KEY = 'squid-marketplace-setup-event-ids';

/** AWS callback entry info stored in user's browser. */
export interface MarketplaceAccountSetupEventLsEntry {
  eventId: string;
  timestamp: number;
  type: MarketplaceType;
}
