import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ApplicationService } from '../../application/application.service';
import { isNonNullable } from 'assertic';

@Injectable({
  providedIn: 'root',
})
export class IndexGuard implements CanActivate {
  constructor(
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
  ) {}

  async canActivate(): Promise<UrlTree> {
    const application = await firstValueFrom(this.applicationService.observeCurrentApplication());
    // Currently the index route should redirect to the application route.
    return this.router.createUrlTree(['/application', application?.appId].filter(isNonNullable));
  }
}
