import { CollectionName, DocumentData, FieldName } from './document.public-types';
import { Paths } from './typescript.public-types';

/**
 * An alias for a join result. This is used to disambiguate fields in the result.
 */
export type Alias = string;

/**
 * A join condition.
 * The join conditions defines the alias for the left side of the join and the field names to join on.
 */
export interface JoinCondition {
  leftAlias: Alias;
  left: FieldName;
  right: FieldName;
  isInner: boolean;
}

/** A list of query conditions. */
export type Condition<Doc extends DocumentData = any> = SimpleCondition<Doc> | CompositeCondition<Doc>;
export type Conditions<Doc extends DocumentData = any> = Array<Condition<Doc>>;

/** A single field query condition. */
export interface SimpleCondition<
  Doc extends DocumentData = any,
  F extends Paths<Doc> = Paths<Doc>,
  MyOperator = Operator,
> {
  fieldName: F;
  operator: MyOperator;
  value: any;
}

/**
 * A composite fields query condition.
 * Just a list of simple conditions with specific set of operators. It should be
 * evaluated this way:
 * If A, B, C are the conditions and A` = A with '==' operator:
 * A || (A' && B) || (A' && B' && C)
 */
export interface CompositeCondition<Doc extends DocumentData = any> {
  fields: Array<SimpleCondition<Doc, Paths<Doc>, CompositeConditionOperator>>;
}

export function isSimpleCondition(condition: Condition): condition is SimpleCondition {
  return 'fieldName' in condition;
}

type CompositeConditionOperator = '>=' | '<=' | '>' | '<';

/** An operator in a query condition. */
export type Operator =
  | '=='
  | '!='
  | CompositeConditionOperator
  | 'like'
  | 'not like'
  | 'like_cs'
  | 'not like_cs'
  | 'array_includes_some'
  | 'array_includes_all'
  | 'array_not_includes';

export const ALL_OPERATORS: Array<Operator> = [
  '==',
  '!=',
  '>=',
  '<=',
  '>',
  '<',
  'like',
  'not like',
  'like_cs',
  'not like_cs',
  'array_includes_some',
  'array_includes_all',
  'array_not_includes',
];

/** A definition of a sort by a filed. */
export interface FieldSort<Doc> {
  fieldName: FieldName<Doc>;
  asc: boolean;
}

/** A query object. */
export interface Query<Doc extends DocumentData = any> {
  collectionName: CollectionName; // The full collection path such as "c1" or "c1/d1/c2"
  integrationId: string;
  conditions: Conditions<Doc>;
  sortOrder: Array<FieldSort<Doc>>;
  limit: number;
  limitBy?: {
    limit: number;
    fields: Array<FieldName<Doc>>;
    reverseSort: boolean;
  };
}
