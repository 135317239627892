@if (integrationObs | async; as integration) {
  @let collectionList = collectionListObs | async ;
  @let documents = documentsObs | async ;
  @let selectedDocument = selectedDocumentSubject | async ;
  <div class="db_browser_page">
    <div class="container">
      @if (Integrations[integration.type]; as integrationContent) {
        <portal class="flex_shrink_0" [emitter]="headerTemplateChange" [teleport]="true">
          <integration-header [integrationType]="integration.type"
                              [title]="integration.id"
                              [description]="integrationContent.description"
                              [withTabs]="true">
          </integration-header>
        </portal>
      }
    </div>
    <div class="container browser_container">
      <div class="browser">
        <div class="pane collections_pane" [ngClass]="{expanded: collectionsExpanded}">
          <div class="pane_header">
            <div class="pane_left">
              <mat-icon svgIcon="browser_collections_icon"></mat-icon>
              <div class="pane_title" [hidden]="!collectionsExpanded">Collections</div>
            </div>
          </div>

          <div class="content">
            @let selectedCollectionName = selectedCollectionNameSubject | async ;
            @for (collectionName of collectionList; track collectionName) {
              <db-browser-row [label]="collectionName"
                              [selected]="selectedCollectionName === collectionName"
                              [expanded]="collectionsExpanded"
                              (click)="selectCollection(collectionName)" />
            }
          </div>

          <div class="collapse_or_expand_container">
            <div class="background_overlay"></div>
            <div class="collapse_or_expand_button"
                 (click)="collectionsExpanded = !collectionsExpanded">
              <mat-icon class="collapse_or_expand_icon" svgIcon="browser_expand_icon"
                        [ngClass]="{expanded: collectionsExpanded}"></mat-icon>
            </div>
          </div>
        </div>
        <div class="pane documents_pane" [ngClass]="{expanded: documentsExpanded}">
          @let sortOrders = (sortOrdersSubject | async) || [] ;
          @let filters = (filtersSubject | async) || [] ;
          <div class="pane_header">
            <div class="pane_left">
              <mat-icon svgIcon="browser_documents_icon"></mat-icon>
              <div class="pane_title" [hidden]="!documentsExpanded">Documents</div>
            </div>
            <div class="pane_right" [ngClass]="{expanded: documentsExpanded}">
              <div class="doc_button filter_action" title="Filter" (click)="showFilterDialog()"
                   [ngClass]="{active: !!filters.length}">
                <mat-icon svgIcon="browser_filter_icon"></mat-icon>
              </div>
              <div class="doc_button sort_action" title="Sort" (click)="showSortDialog()"
                   [ngClass]="{active: !!sortOrders.length}">
                <mat-icon svgIcon="browser_sort_icon"></mat-icon>
              </div>
            </div>
          </div>
          @if (filters.length || sortOrders.length) {
            <div class="filters_and_sort">
              @for (filter of filters; track filter.name + ' ' + filter.operator + ' ' + filter.value; let i = $index) {
                <db-browser-filter-bubble [label]="filter.name + ' ' + filter.operator + ' ' + filter.value"
                                          (clicked)="handleFilterClicked(filter, i, $event)"
                ></db-browser-filter-bubble>
              }
              @for (sortOrder of sortOrders; track sortOrder.field + ' ' + sortOrder.sortOrder; let i = $index) {
                <db-browser-filter-bubble [label]="sortOrder.field"
                                          (clicked)="handleSortOrderClicked(sortOrder, i, $event)"
                                          [icon]="sortOrder.sortOrder ? 'browser_ascending_icon' : 'browser_descending_icon'"
                ></db-browser-filter-bubble>
              }
            </div>
          }
          @if (selectedDocument) {
            <div class="content">
              @for (document of documents; track getSquidDocumentId(document)) {
                <db-browser-row
                  [label]="integration.type === IntegrationType.built_in_db ? document['__id'] : getSquidDocumentId(document)"
                  [selected]="getSquidDocumentId(document) === getSquidDocumentId(selectedDocument)"
                  [expanded]="documentsExpanded"
                  (click)="selectDocument(document)" />
              }
            </div>
          }
          <div class="collapse_or_expand_container">
            <div class="background_overlay"></div>
            <div class="collapse_or_expand_button"
                 (click)="documentsExpanded = !documentsExpanded">
              <mat-icon class="collapse_or_expand_icon" svgIcon="browser_expand_icon"
                        [ngClass]="{expanded: documentsExpanded}"></mat-icon>
            </div>
          </div>
        </div>
        <div class="pane data_pane">
          <div class="pane_header">
            <div class="pane_left">
              <mat-icon svgIcon="browser_data_icon"></mat-icon>
              <div class="pane_title">Data</div>
            </div>
            @if (selectedDocument) {
              <div class="pane_right" [ngClass]="{expanded: true}">
                <div class="view_as_json_toggle_wrapper">
                  <div class="text">View as JSON</div>
                  <mat-checkbox class="checkbox" [(ngModel)]="jsonViewEnabled"></mat-checkbox>
                </div>

                <button mat-flat-button class="copy_button button secondary_button medium_button"
                        (click)="copyJsonDate(selectedDocument)">
                  <mat-icon class="copy_icon" svgIcon="copy_icon"></mat-icon>
                  <div class="text">Copy</div>
                </button>
              </div>
            }
          </div>

          @if (selectedDocument) {
            <div class="content">
              @if (jsonViewEnabled) {
                <markdown [clipboard]="false" [data]="getJsonMarkdown(selectedDocument)" />
              } @else {
                <app-json-editor [options]="editorOptions" [data]="selectedDocument"></app-json-editor>
              }
            </div>
          }
        </div>
      </div>

    </div>
  </div>
}
