import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Alias, ClientRequestId, JoinCondition, Query, SquidDocument } from '../public-types';

export interface OngoingQuery {
  clientRequestId: ClientRequestId;
  query: Query;
  supportedQueries: Array<OngoingQuery>; // All the queries that depend on this query A.join(B) ==> B depends on A
  supportingOngoingQuery?: OngoingQuery; // The query this query supports. A.join(B) ==> B supporting A
  gotInitialResponse: boolean; // Whether the first response arrived
  activated: boolean; // Whether the query is ready - relevant for joins
  joinCondition?: JoinCondition; // The join condition with the supporting ongoing query
  alias: Alias;
  dataSubject: BehaviorSubject<Array<SquidDocument> | null>;
  queryRegistered: BehaviorSubject<boolean>;
  /**
   * In case that this query is a parent of another query (that is, the other query is a subset of this query), this
   * query should not be unsubscribed from the server until we registered the child query in the server.
   */
  unsubscribeBlockerCount: BehaviorSubject<number>;
  subscribe: boolean;
  /**
   * In case of joins, and if this ongoing query is the root, this field emits all the supported observables
   * for example A.join(B, {...some join condition...}).join(C, {...some join condition}.
   * This field will emit [A.subject.pipe(), B.subject.pipe(), C.subject.pipe()]. Any new supported queries will be
   * added here.
   */
  allObservables?: ReplaySubject<Array<Observable<DocsAndAlias>>>;
  isEmptyForJoin: boolean;
  /**
   * If the query is a supporting query (right side of the join) and the query has '==' condition on the join column,
   * there is no need to emit a new query when additional values are added to the left side of the join since the right
   * side will not change.
   */
  canExpandForJoin: boolean;
  done: boolean;
  isInFlight: boolean;
  forceFetchFromServer: boolean;

  /**
   * If there's a limit, we request `limit + FETCH_BEYOND_LIMIT` documents from the server.
   * If we got that many documents, that means there may be even more. In that case, if our result set goes below
   * `limit + LIMIT_UNDERFLOW_TRIGGER` documents (due to local or remote deletions), we need to resend the query to the
   * server to potentially get more documents.
   * If the number of documents is less than `limit + FETCH_BEYOND_LIMIT`, that means there are not that many documents
   * on the server, so we don't need to resend the query regardless of how small our result size is or becomes.
   */
  limitUnderflowState: LimitUnderflowState;
}

export enum LimitUnderflowState {
  UNKNOWN,
  DISABLED,
  ENABLED,
}

export interface DocsAndAlias {
  docs: Array<SquidDocument>;
  alias: Alias;
}
