<ng-container *ngIf="themeService.theme$ | async as theme">
  <div class="onboarding_layout dark">
    <div class="header">
      <mat-icon svgIcon="logo_long" class="logo_icon"></mat-icon>
      <div class="contact_buttons">
        <a
          mat-flat-button
          href="https://squid.cloud/contact"
          target="__blank"
          class="secondary_icon_button large_button"
          title="Contact us"
        >
          <mat-icon svgIcon="envelope_simple" title="Contact us"></mat-icon>
        </a>
        <a
          mat-flat-button
          target="__blank"
          href="https://bit.ly/squid-HELP"
          class="secondary_icon_button large_button"
          title="Join the Discord"
        >
          <mat-icon svgIcon="discord" title="Discord"></mat-icon>
        </a>
      </div>
    </div>
    <div class="background"></div>
    <seabed [index]="index" />
    <div
      class="content"
      [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''"
    >
      <router-outlet #outlet="outlet"></router-outlet>
    </div>


    <div class="progress_bar_wrapper">
      <div class="progress_bar" [style.width]="getProgress(progress)"></div>
    </div>
    @switch (onboardingService.button) {
      @case ('skip') {
        <button
          mat-flat-button
          class="skip-button secondary_button large_button"
          data-testid="skip-button"
          (click)="handleSkip()"
        >
          Skip
        </button>
      }
      @case ('back') {
        <button
          mat-flat-button
          class="skip-button secondary_button large_button"
          data-testid="back-button"
          (click)="handleBack()"
        >
          Back to Console
        </button>
      }
    }
  </div>
</ng-container>
