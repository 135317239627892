import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { truthy } from 'assertic';

/** A reusable constant for organization id path or query parameter. */
export const ORG_ID_PARAMETER = 'organizationId';

/** A reusable constant for application id path or query parameter. */
export const APP_ID_PARAMETER = 'applicationId';

/** A reusable constant for user id path or query parameter. */
export const USER_ID_PARAMETER = 'userId';

/** A reusable constant for integration id path or query parameter. */
export const INTEGRATION_ID_PARAMETER = 'integrationId';

/** A reusable constant for an active top level tab path or query parameter. */
export const TAB_ID_PARAMETER = 'tabId';

/** Look-ups parameter in the snapshot params. Throws an error if the parameter is not found. */
export function getRequiredPageParameter<T extends string>(
  parameterName: string,
  params: ActivatedRouteSnapshot | Params,
): T {
  return truthy(getPageParameter(parameterName, params), `${parameterName} parameter is required`);
}

/** Looks up for an optional route parameter. */
export function getPageParameter<T extends string>(
  parameterName: string,
  params: ActivatedRouteSnapshot | Params,
): T | undefined {
  const effectiveParams: Params = params instanceof ActivatedRouteSnapshot ? params.params || {} : params;
  return effectiveParams[parameterName];
}

/** Returns current URL (window.location.href) parameter value. */
export function getPathParameter(parameterName: string): string | undefined {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const value = params.get(parameterName);
  return value ? decodeURIComponent(value) : undefined;
}
