import { CanActivateFn, UrlTree } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { isSysadminUserEmail } from '@squidcloud/console-common/utils/sysadmin-utils';

export const adminAuthGuardFn: CanActivateFn = async () => {
  const auth0: AuthService = inject(AuthService);
  const user = await firstValueFrom(auth0.user$);
  // All Squid.Cloud users are considered as admins today.
  if (isSysadminUserEmail(user?.email)) {
    return true;
  }
  console.debug('User is not an admin. Redirecting to the home page.');
  return new UrlTree();
};
