import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import {
  BundleDataTableColumn,
  BundleDataTableData,
  BundleDataTableRow,
  medianColumnHeader,
  p95ColumnHeader,
} from '../../global/components/bundle-data-table/bundle-data.types';
import { BackendService } from '@squidcloud/console-web/app/backend/backend.service';
import {
  BackendMetricPeriodType,
  getServiceNameFromFunctionName,
} from '@squidcloud/console-common/types/metrics.types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  newErrorsColumn,
  newMedianColumn,
  newP95Column,
  newSuccessColumn,
  newTextColumn,
  newTextColumnWithTooltip,
} from '@squidcloud/console-web/app/backend/backend-component-utils';

@Component({
  templateUrl: './schedulers.component.html',
  styleUrls: ['./schedulers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulersComponent {
  protected readonly bundleDataTableData$: Observable<BundleDataTableData | undefined>;
  protected readonly periodType$ = new BehaviorSubject<BackendMetricPeriodType>('last-hour');

  constructor(private readonly backendService: BackendService) {
    this.bundleDataTableData$ = this.backendService
      .observeMetricByPeriodType(this.periodType$, takeUntilDestroyed())
      .pipe(
        map(metricsResponse => {
          const metrics = metricsResponse.functionsExecutionCounts?.['scheduler'];
          if (!metrics) return undefined;
          const rows: Array<BundleDataTableRow> = metrics.functions.map(metric => {
            const { counts, functionData } = metric;
            const { extras } = functionData;
            const schedulerId = extras['id'] as string;
            const columns: Array<BundleDataTableColumn> = [
              newTextColumnWithTooltip(schedulerId),
              newSuccessColumn(counts),
              newErrorsColumn(counts),
              newMedianColumn(counts),
              newP95Column(counts),
              newTextColumn(extras['cronExpression'] as string),
              newTextColumn(getServiceNameFromFunctionName(functionData.serviceFunctionName)),
            ];
            return { columns };
          });

          this.backendService.reportBackendTabHasMetrics(!!rows.length);
          if (!rows.length) return undefined;

          return {
            headerRow: [
              'ID',
              { name: 'Executions', align: 'right' },
              { name: 'Errors', align: 'right' },
              medianColumnHeader,
              p95ColumnHeader,
              'Cron expression',
              'Service',
            ],
            rows,
            metrics: metrics.charts,
            showPeriodSelector: true,
          };
        }),
      );

    this.backendService.reportBackendTabHasMetrics(false);
  }
}
