@if (applicationService.currentApplication$ | async; as application) {
  <div class="app_logs" #topPage>
    <portal class="flex_shrink_0" [emitter]="headerTemplateChange">
      <integration-header
        integrationType="ai_agents"
        [title]="application.name"
        forceSubtitle="AI Agents"
      />
    </portal>
    @if (logEntriesObs | async; as logEntries) {
      <div class="container">
        <div class="filters_container">
          <div class="left">
            <mat-form-field class="form_field" appearance="outline">
              <mat-label class="filter_label">
                <mat-icon class="icon" svgIcon="calendar_icon"></mat-icon>
                Timestamp
              </mat-label>
              <mat-select
                class="squid_select"
                (selectionChange)="handleLogDisplayPeriodInMinutesChanged($event.value)"
                [value]="logDisplayPeriodInMinutes">
                @for (timestamp of allTimestampOptions; track timestamp.value) {
                  <mat-option [value]="timestamp.value">{{ timestamp.label }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
            <!--        @if (agentIdOptions) {-->
            <!--          <mat-form-field class="form_field" appearance="outline">-->
            <!--            <mat-label class="filter_label">Source</mat-label>-->
            <!--            <mat-select-->
            <!--              class="squid_select"-->
            <!--              [value]="selectedAgentId"-->
            <!--              (selectionChange)="onAgentSelectionChange($event)">-->
            <!--              @for (agentIdOption of agentIdOptions; track agentIdOption) {-->
            <!--              <mat-option [value]="agentIdOption.value">{{ agentIdOption.label }}</mat-option>-->
            <!--              }-->
            <!--            </mat-select>-->
            <!--          </mat-form-field>-->
            <!--        }-->
            @if (serverRequestInProgress || (refreshInProgressSubject | async)) {
              <mat-icon svgIcon="spinner_icon" />
            }
          </div>
        </div>

        @if (receivedDataFromServer) {
          <div class="logs_table" [ngClass]="{server_request_in_progress: serverRequestInProgress}">
            <div class="row header_row">
              <div class="cell">Timestamp</div>
              <div class="cell">Agent ID</div>
              <div class="cell">UserId</div>
              <div class="cell">Prompt</div>

              <div class="cell"></div>
            </div>

            @if (logEntries.length) {
              @for (logEntry of logEntries; track logEntry.id) {
                <div class="row">
                  <div class="cell timestamp_cell">
                    {{ logEntry.timestamp | date: 'medium' }}
                  </div>
                  <div class="cell truncate">{{ logEntry.tags['agentId'] }}</div>
                  <div class="cell truncate">{{ logEntry.context.userId }}</div>
                  <div class="cell">{{ logEntry.tags['prompt'] }}</div>
                  <div class="cell actions">
                    <button class="eye_button secondary_icon_button" mat-flat-button
                            (click)="showLogEntryDialog(logEntry)">
                      <mat-icon class="eye_icon" svgIcon="eye_icon"></mat-icon>
                    </button>
                  </div>
                </div>
              }
            } @else {
              <div class="no_logs_outer_container">
                <div class="no_logs_inner_container">
                  <mat-icon class="file_dotted_icon" svgIcon="file_dotted_icon"></mat-icon>
                  <div class="title">No logs to display</div>
                  <div class="description">Try a different filter combination</div>
                </div>
              </div>
            }
          </div>

          @if (logEntries.length) {
            <div class="logs_footer">
              <div class="center_buttons">
                <button class="button secondary_button medium_button" mat-flat-button
                        [disabled]="!hasPreviousPage || serverRequestInProgress"
                        (click)="previousPage()">
                  <mat-icon class="icon prev_icon" svgIcon="chevron_right_icon"></mat-icon>
                  Prev
                </button>
                <button class="button secondary_button medium_button" mat-flat-button
                        [disabled]="!hasNextPage || serverRequestInProgress"
                        (click)="nextPage()">
                  Next
                  <mat-icon class="icon" svgIcon="chevron_right_icon" iconPositionEnd></mat-icon>
                </button>
              </div>
              <div class="right">
                <span class="back_to_top_button"
                      (click)="topPage.scrollIntoView({behavior: 'smooth'})">Back to top</span>
              </div>
            </div>
          }
        }
      </div>
    }
  </div>
}
