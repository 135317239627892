<div class="seabed">
  <div
    class="images"
    [@slideAndSnapBack]="{
      value: animationState,
      params: { translation, lastTranslation }
    }"
    (@slideAndSnapBack.done)="done()"
  >
    <img src="assets/images/onboarding_seabed.png" alt="" />
    <img src="assets/images/onboarding_seabed.png" alt="" />
    <img src="assets/images/onboarding_seabed.png" alt="" />
  </div>
</div>
