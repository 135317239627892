@let application = applicationObs | async ;
@let isAdmin = 'ADMIN' | minRole ;
@let secrets = secretsObs | async ;
<div class="secrets_page">
  <div class="container">
    <page-header
      [title]="application?.name || 'No application'"
      subtitle="Secrets vault"
      secondaryTitle="Loose lips sink ships..."
      secondaryDescription="Create, store and manage your secrets here">
      <div class="header_buttons_container">
        @if (application) {
          <button mat-flat-button (click)="secretUiService.showCreationScriptDialog()"
                  class="secondary_button large_button"
                  [disabled]="!secrets?.length"
                  data-testid="show-creation-script-button"
          >
            Show Creation Script
          </button>

          <button mat-flat-button (click)="secretUiService.showCreateSecretDialog()"
                  class="primary_button large_button"
                  [disabled]="!isAdmin"
                  [matTooltip]="isAdmin ? '' : 'Only admins can create new secrets'"
                  data-testid="store-new-secret-button"
          >
            Store New Secret
          </button>
        }
      </div>
    </page-header>

    @if (application) {
      @if (secrets?.length) {
        <div class="app_metadata_table">
          <div class="row header_row">
            <div class="column">Key</div>
            <div class="column">Value</div>
            <div class="column">Updated</div>
            <div class="column"></div>
          </div>
          @for (secret of secrets; track secret.key) {
            <div class="row">
              <div class="column key_column">
                <mat-icon class="lock_icon" svgIcon="lock_icon"></mat-icon>
                {{ secret.key }}
              </div>
              <div class="column value_column">***************</div>
              <div class="column updated_column">{{ secret.updateDate | date: 'medium' }}</div>
              <div class="column action_column">
                <button mat-icon-button [matMenuTriggerFor]="secretDropdown" [matMenuTriggerData]="{element: secret}"
                        data-testid="secret-action-menu-button">
                  <mat-icon class="action_icon" svgIcon="menu_dots_icon"></mat-icon>
                </button>
              </div>
            </div>
          }
        </div>
      } @else {
        <illustration description="Create secrets here and use them throughout your application."
                      title="No stored secrets"
                      icon="no_stored_secrets">
            <span [matTooltip]="isAdmin ? '' : 'Only admins can create new secrets'">
              <button mat-flat-button (click)="secretUiService.showCreateSecretDialog()"
                      class="store_secret_button primary_button large_button"
                      [disabled]="!isAdmin">
                Store First Secret
              </button>
            </span>
        </illustration>
      }
    } @else {
      <no-application-zero-state
        title="Arrrr no secrets yet..."
        description="Secrets require an application, it looks like you do not have one yet.">
      </no-application-zero-state>
    }

  </div>
  <mat-menu #secretDropdown="matMenu"
            class="secret_dropdown dropdown_menu"
            xPosition="before">
    <ng-template let-element="element" matMenuContent [typeGuard]="{ element: CpSecret }">
        <span [matTooltip]="isAdmin ? '' : 'Only admins can update secrets'">
          <button
            (click)="secretUiService.showUpdateSecretDialog(element)"
            class="menu_item update_secret_button" mat-menu-item
            [disabled]="!isAdmin">
            <mat-icon class="edit_icon menu_icon" svgIcon="edit_icon"></mat-icon>
            Update secret
          </button>
        </span>
      <div class="menu_divider"></div>

      <span [matTooltip]="isAdmin ? '' : 'Only admins can delete secrets'">
          <button (click)="secretUiService.showDeleteSecretDialog(element)"
                  class="menu_item delete_secret_button red_menu_item"
                  data-testid="delete-secret-button"
                  [disabled]="!isAdmin"
                  mat-menu-item>
            <mat-icon class="trash_icon menu_icon" svgIcon="trash_icon"></mat-icon>
            Delete secret
          </button>
        </span>
    </ng-template>
  </mat-menu>
</div>

