import { RpcManager } from './rpc.manager';
import { BlobAndFilename } from './types';
import { IntegrationId } from '../../internal-common/src/public-types/communication.public-types';

export interface StorageFileUploadRequest {
  integrationId: IntegrationId;
  dirPathInBucket: string;
  expirationInSeconds?: number;
}

export interface GetFileMetadataRequest {
  integrationId: IntegrationId;
  filePathInBucket: string;
}

export interface GetFileMetadataResponse {
  filename: string;
  size: number;
  lastModified: Date;
  metadata: Record<string, string>;
}

export interface GetDownloadUrlRequest {
  integrationId: IntegrationId;
  filePathInBucket: string;
  urlExpirationInSeconds?: number;
}

export interface GetDownloadUrlResponse {
  url: string;
}

export interface DeleteFilesRequest {
  integrationId: IntegrationId;
  filePathsInBucket: Array<string>;
}

export interface ListDirectoryContentsRequest {
  integrationId: IntegrationId;
  dirPathInBucket: string;
}

export interface FileInDirectory {
  filename: string;
  absoluteFilePathInBucket: string;
  size: number;
  lastModified: Date;
}

export interface ListDirectoryContentsResponse {
  directories: Array<string>;
  files: Array<FileInDirectory>;
}

export class StorageClient {
  /** @internal */
  constructor(
    private readonly integrationId: IntegrationId = 'built_in_storage',
    private readonly rpcManager: RpcManager,
  ) {}

  /**
   * Uploads a file to a specified directory within the bucket.
   *
   * @param dirPathInBucket - The directory path within the bucket where the file should be uploaded.
   * @param file - The file or blob (with filename) to upload.
   * @param expirationInSeconds - Optional expiration time in seconds for the upload.
   * @returns A promise that resolves to void when the upload is complete.
   */
  async uploadFile(dirPathInBucket: string, file: File | BlobAndFilename, expirationInSeconds?: number): Promise<void> {
    const request: StorageFileUploadRequest = {
      integrationId: this.integrationId,
      dirPathInBucket,
      expirationInSeconds,
    };
    await this.rpcManager.post('storage/uploadFile', request, [file]);
  }

  /**
   * Retrieves metadata for a file located in a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket.
   * @returns A promise that resolves to a `GetFileMetadataResponse` containing the file's metadata.
   */
  async getFileMetadata(filePathInBucket: string): Promise<GetFileMetadataResponse> {
    const request: GetFileMetadataRequest = {
      integrationId: this.integrationId,
      filePathInBucket,
    };
    return await this.rpcManager.post<GetFileMetadataResponse>('storage/getFileMetadata', request);
  }

  /**
   * Generates a URL for downloading a file from a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket for which to generate the download URL.
   * @param urlExpirationInSeconds - Optional expiration time in seconds for the download URL.
   * @returns A promise that resolves to a `GetDownloadUrlResponse` containing the download URL.
   */
  async getDownloadUrl(filePathInBucket: string, urlExpirationInSeconds?: number): Promise<GetDownloadUrlResponse> {
    const request: GetDownloadUrlRequest = {
      integrationId: this.integrationId,
      filePathInBucket,
      urlExpirationInSeconds,
    };
    return await this.rpcManager.post<GetDownloadUrlResponse>('storage/getDownloadUrl', request);
  }

  /**
   * Lists the contents of a directory within the bucket.
   *
   * @param dirPathInBucket - The path of the directory within the bucket.
   * @returns A promise that resolves to a `ListDirectoryContentsResponse` containing the directory contents.
   */
  async listDirectoryContents(dirPathInBucket: string): Promise<ListDirectoryContentsResponse> {
    const request: ListDirectoryContentsRequest = {
      integrationId: this.integrationId,
      dirPathInBucket,
    };
    return await this.rpcManager.post<ListDirectoryContentsResponse>('storage/listDirectoryContents', request);
  }

  /**
   * Deletes a single file from a specified path within the bucket.
   *
   * @param filePathInBucket - The path of the file within the bucket to be deleted.
   * @returns A promise that resolves to void when the file has been deleted.
   */
  async deleteFile(filePathInBucket: string): Promise<void> {
    await this.deleteFiles([filePathInBucket]);
  }

  /**
   * Deletes multiple files from specified paths within the bucket.
   *
   * @param filePathsInBucket - An array of paths for the files within the bucket to be deleted.
   * @returns A promise that resolves to void when all specified files have been deleted.
   */
  async deleteFiles(filePathsInBucket: Array<string>): Promise<void> {
    const request: DeleteFilesRequest = {
      integrationId: this.integrationId,
      filePathsInBucket,
    };
    await this.rpcManager.post('storage/deleteFiles', request);
  }
}
