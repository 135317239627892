<div class="onboarding_content">
  <div class="getting_started_container">
    <h1>Create an <br />Expert AI Agent</h1>
    <div class="subtitle">
      Try a pre-built AI agent trained on car insurance. Then, create a custom
      AI agent yourself with no code in just 3 easy steps!
    </div>

    <button
      class="primary_button extra_large_button"
      mat-flat-button
      (click)="createIntegration()"
      [disabled]="requestInProgress"
      data-testid="onboarding-widget-create-button"
    >
      <loading-indicator-overlay [isLoading]="requestInProgress"
        >Create AI Agent
        <mat-icon
          svgIcon="chevron_right_icon"
          iconPositionEnd
          class="chevron_right"
        ></mat-icon>
      </loading-indicator-overlay>
    </button>
  </div>
</div>
