import { addMonths, getStartOfTheDay } from './date-utils';
import { BillingPlan, BillingPlanTemplateId } from '@squidcloud/console-common/types/billing.types';
import { MILLIS_PER_DAY } from '@squidcloud/internal-common/types/time-units';
import { getBillingPlanTemplateById } from '@squidcloud/console-common/types/billing-plans';

/** Returns the next bill date after the current or the provided date. */
export function getNextBillDate(plan: Pick<BillingPlan, 'creationDate' | 'billingPeriod'>, date = new Date()): Date {
  const finishedBillDate = getFinishedBillDate(plan, date);
  switch (plan.billingPeriod.interval) {
    case 'day': {
      const result = new Date(finishedBillDate);
      result.setDate(finishedBillDate.getDate() + plan.billingPeriod.intervalCount);
      return result;
    }
    case 'month':
      return addMonths(finishedBillDate, plan.billingPeriod.intervalCount, plan.creationDate.getDate());
  }
}

/** Returns the finished bill date before or equal to the current or the provided date. */
export function getFinishedBillDate(
  { creationDate, billingPeriod }: Pick<BillingPlan, 'creationDate' | 'billingPeriod'>,
  date = new Date(),
): Date {
  switch (billingPeriod.interval) {
    case 'day': {
      const daysBetweenDates = Math.round(
        (getStartOfTheDay(date).getTime() - getStartOfTheDay(creationDate).getTime()) / MILLIS_PER_DAY,
      );
      const fullPeriodsBetweenDates = Math.floor(daysBetweenDates / billingPeriod.intervalCount);
      return getStartOfTheDay(
        new Date(creationDate.getTime() + fullPeriodsBetweenDates * billingPeriod.intervalCount * MILLIS_PER_DAY),
      );
    }
    case 'month': {
      let fullMonthsBetweenDates =
        date.getFullYear() * 12 + date.getMonth() - creationDate.getFullYear() * 12 - creationDate.getMonth();
      if (creationDate.getDate() > date.getDate() && date.getDate() !== getMaxDaysInMonth(date)) {
        fullMonthsBetweenDates--;
      }
      const fullPeriodsBetweenDates = Math.floor(fullMonthsBetweenDates / billingPeriod.intervalCount);
      // Aligns result date by 'day' in 'creationDate'.
      return addMonths(creationDate, fullPeriodsBetweenDates * billingPeriod.intervalCount, creationDate.getDate());
    }
  }
}

function getMaxDaysInMonth(date: Date): number {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1, 0).getDate();
}

/** Creates a new billing plan instance by template id. */
export function createBillingPlan(planTemplateId: BillingPlanTemplateId): BillingPlan {
  const billingPlanTemplate = getBillingPlanTemplateById(planTemplateId);
  return {
    ...billingPlanTemplate,
    updateDate: new Date(),
    creationDate: new Date(),
  };
}

/** Gets plan description based on plan templateId */
export function getBillingPlanDescription(planTemplateId: BillingPlanTemplateId): string {
  return getBillingPlanTemplateById(planTemplateId).description;
}
